import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useStateValue } from './StateProvider';

import axios from 'axios';
import configData from './Config';

import UpdatePage from "./UpdatePage";
import AuthScreen from "./AuthScreen";
import Projects from "./Projects";
import HomePage from "./HomePage";
import Home from "./Home";
import Backups from "./Backups";
import CollectionsOverview from "./CollectionsOverview";
import PaymentsOverview from "./PaymentsOverview";
import AuditTrail from "./AuditTrail";
import AddProject from "./AddProject";

import { areObjectsEqual } from './Utils/textparse';

import './App.css';

const REFRESHUPDATEINTERVAL = 5000; // every 2 minutes
const REFRESHVERSIONINTERVAL = 43200000;  // every 12 hours

function App() {
    const initialState = {
        user: {
            userid: '',
            usertoken: '',
            usertype: 0,
            useremail: '',
            username: '',
            userprofilepic: '',
            userbiography: '',
        },
        notifications: [],
        notificationscount: 0,
        modalopen: false,
        modalcontent: null,
        version: 0
    }

    const [state, setState] = useState(initialState);

    const [{ project }, dispatch] = useStateValue();

    const [loading, setLoading] = useState(false);
    const [versionlock, setVersionlock] = useState(false);

    const fetchVersionData = async () => {
        const response = await fetch('/manifest.json');
        const manifestData = await response.json();

        setState(state => ({
            ...state,
            version: manifestData.version
        }))

        axios.post(configData.CONTROLLERURL + configData.GETVERSION, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8"
            },
        }).then((res) => {
            if (res.data == manifestData.version) {
                setVersionlock(false);
            }
            else {
                setVersionlock(true);
            }
        }).catch(() => {
            console.log('Not connected, cant check for version')
        })
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchVersionData();
        }, REFRESHVERSIONINTERVAL);

        return () => clearInterval(intervalId);
    }, []);

    const getLastModified = (project, state, dispatch) => {
        if (project.projectid) {
            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid
            };

            axios.post(configData.CONTROLLERURL + configData.GETLASTMODIFIED, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                if (res.data != project.lastmodified) {
                    const data = {
                        projectuserid: project.projectuserid,
                        projectid: project.projectid
                    };

                    axios.post(configData.CONTROLLERURL + configData.GETPROJECT, data, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                            "userid": state.user.userid,
                            "usertoken": state.user.usertoken
                        }
                    }).then((res) => {
                        const changedKeys = areObjectsEqual(project, res.data);
                        if (changedKeys.length > 0) {
                            changedKeys.forEach(key => {
                                dispatch({
                                    type: 'UPDATE_KEYVALUE',
                                    key: key,
                                    value: res.data[key]
                                });
                            });
                        }
                    })
                }
            }).catch(() => {
                console.log('Not connected, cant check for updates')
            })
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            getLastModified(project, state, dispatch);
        }, REFRESHUPDATEINTERVAL);

        return () => clearInterval(intervalId);
    }, [project, state, dispatch]);

    useEffect(() => { //20240523
        getLocalUser().then(value => {
            if (value.user != null) {
                validateUser(value.user.userid, value.user.usertoken)
            }
        })
    }, []);

    async function getLocalUser() { //20240523
        const user = await localStorage.getItem('user');
        return {
            user: user != null ? JSON.parse(user) : null
        }
    }

    function validateUser(userid, usertoken) { //20240523

        setLoading(true)

        const data = {
            userid: userid,
            usertoken: usertoken
        }

        axios.post(configData.CONTROLLERURL + configData.VALIDATEUSER, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8"
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setState(state => ({
                        ...state, user: {
                            userid: userid,
                            usertoken: usertoken,
                            useremail: res.data.data.useremail,
                            username: res.data.data.username,
                            usertype: res.data.data.usertype,
                        }
                    }))
                }
                else {
                }
            }
            else {
            }
        }).catch(() => {

        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                setState(state => ({ ...state, modalopen: false, modalcontent: null }));
            }
        };

        document.addEventListener('keydown', handleEscape);

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    if (loading) {
        return (
            <div className="app_loadingpage">
                <div className="app_loadingcontent">
                    <h1>Loading...</h1>
                    <p>Please wait while we load the page</p>
                </div>
            </div>
        );
    }

    return (
        versionlock ?
            <BrowserRouter>
                <Routes>
                    <Route index element={<UpdatePage />} />
                </Routes>
            </BrowserRouter>
            :
            state.user.userid ?
                <BrowserRouter>
                    <Routes>
                        <Route path="/">
                            <Route index element={<Projects state={state} setState={setState} />} />
                            <Route path='home' element={<Home state={state} setState={setState} />} />
                            <Route path='backups' element={<Backups state={state} setState={setState} />} />
                            <Route path='collectionsoverview' element={<CollectionsOverview state={state} setState={setState} />} />
                            <Route path='paymentsoverview' element={<PaymentsOverview state={state} setState={setState} />} />
                            <Route path='audittrail' element={<AuditTrail state={state} setState={setState} />} />
                            <Route path='addproject' element={<AddProject state={state} setState={setState} />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
                :
                <BrowserRouter>
                    <Routes>
                        <Route path="/">
                            <Route index element={<HomePage state={state} setState={setState} />} />
                            <Route path='authscreen' element={<AuthScreen state={state} setState={setState} />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
    );
}

export default App;