import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from './StateProvider';

import Header from './Header';
import CashAccounts from './CashAccounts';
import Summary from './Summary';
import Announcements from './Announcements';
import EditProject from './EditProject';
import Assets from './Assets';
import Tasks from './Tasks';
import ChartAccounts from './ChartAccounts';
import Inspections from './Inspections';
import Clients from './Clients';
import Collections from './Collections';
import InteraccountTransfers from './InteraccountTransfers';
import JournalEntries from './JournalEntries';
import PurchaseInvoices from './PurchaseInvoices';
import EMSReadings from './EMSReadings.js';
import EMSTanks from './EMSTanks.js';
import EMSMeters from './EMSMeters.js';
import Reports from './Reports';
import Transactions from './Transactions';
import Reconciliations from './Reconciliations';
import Suppliers from './Suppliers';
import Contracts from './Contracts';
import Users from './Users.js';
import Settings from './Settings';
import Admin from './Admin/Admin';

import { getHomePermission } from './Utils/permissions.js';

import './Home.css';

const smallscreen = 540;

function Home({ state, setState }) {
  const [{ project, version }, dispatch] = useStateValue();

  const [togglemenu, setTogglemenu] = useState(false);
  const [screenwidth, setScreenWidth] = useState(window.innerWidth);

  const [announcements, setAnnouncements] = useState([]);
  const [assets, setAssets] = useState([]);
  const [cashaccounts, setCashaccounts] = useState([]);
  const [clients, setClients] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [interaccounttransfers, setInteraccounttransfers] = useState([]);
  const [emsreadings, setEmsreadings] = useState([]);
  const [emstanks, setEmstanks] = useState([]);
  const [emsmeters, setEmsmeters] = useState([]);
  const [journalentries, setJournalentries] = useState([]);
  const [reconciliations, setReconciliations] = useState([]);
  const [purchaseinvoices, setPurchaseinvoices] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [users, setUsers] = useState([]);

  const [option, setOption] = useState(<Summary />);

  const homemenuRef = useRef(null);
  const navigation = useNavigate();

  useEffect(() => {
    if (project.projectid == null) navigation('/')
  }, []);

  useEffect(() => {
    setAnnouncements(project.announcements);
    setAssets(project.assets);
    setCashaccounts(project.cashaccounts);
    setClients(project.clients);
    setInspections(project.inspections);
    setReconciliations(project.reconciliations)
    setInteraccounttransfers(project.interaccounttransfers);
    setJournalentries(project.journalentries);
    setEmsreadings(project.readings);
    setEmstanks(project.tanks);
    setEmsmeters(project.meters)
    setPurchaseinvoices(project.purchaseinvoices);
    setSuppliers(project.suppliers);
    setContracts(project.contracts);
    setTransactions(project.transactions);
    setUsers(project.users);
  }, [project]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const importProject = () => {
    const fileInput = document.getElementById('jsonFileInput');
    fileInput.click();
  }

  const handleFileUpload = (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        try {
          const jsonContent = JSON.parse(e.target.result);
          jsonContent.imported = true;
          dispatch({
            type: 'SET_PROJECT',
            project: jsonContent
          });
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      };

      reader.readAsText(file);
    }
  };

  const downloadBackup = () => {
    if (project) {
      project.version = version;
      const projectJSON = JSON.stringify(project);
      const blob = new Blob([projectJSON], { type: 'application/json' });

      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      const today = new Date().toLocaleDateString('en-GB');
      const formattedDate = today.replace(/\//g, '_');
      const formattedName = `${project.name.toLowerCase().replace(/\s+/g, '_')}_${formattedDate}`;
      a.download = formattedName + '.pd';
      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }

  const homepermission = getHomePermission(project.projectuserid, project.users, state.user.userid);

  const handleClickMenuItem = (selectedOption) => {
    setOption(selectedOption);
    setTogglemenu(false);
  };

  return (
    <div className='home'>
      <Header state={state} setState={setState} />
      <div className='home_projectdetails'>
        <div className='home_projectsection'>
          <div className='home_projecttext'>
            {project.name}
          </div>
        </div>
        {
          homepermission['Edit Project'] &&
          <div className='home_projectsection'>
            <button className='button' onClick={() => setOption(<EditProject state={state} setState={setState} setOption={setOption} />)}>Edit</button>
          </div>
        }
        {
          (state.user.usertype == 1 || state.user.usertype === 2) &&
          <div className='home_projectsection'>
            <button className='button' onClick={importProject}>Import Project</button>
            <input
              type='file'
              accept='.pd'
              style={{ display: 'none' }}
              id='jsonFileInput'
              onChange={handleFileUpload}
            />
          </div>
        }
        <div className='home_projectsection'>
          <div className='button' onClick={downloadBackup}>Backup Project</div>
        </div>
      </div>

      <div className='home_content'>
        <div className={screenwidth > smallscreen ? 'home_menu' : (togglemenu ? 'home_menu home_menusmallvisible' : 'home_menu home_menusmallinvisible')} ref={homemenuRef}>
          {homepermission['Summary'] && <div className='home_button' onClick={() => handleClickMenuItem(<Summary state={state} setState={setState} />)}>Summary</div>}
          {homepermission['Announcements'] && <div className='home_button' onClick={() => handleClickMenuItem(<Announcements state={state} setState={setState} />)}>Announcements ({announcements.length})</div>}
          {homepermission['Assets'] && <div className='home_button' onClick={() => handleClickMenuItem(<Assets state={state} setState={setState} />)}>Assets ({assets.length})</div>}
          {homepermission['Chart of Accounts'] && <div className='home_button' onClick={() => handleClickMenuItem(<ChartAccounts state={state} setState={setState} />)}>Chart of Accounts</div>}
          {homepermission['Cash Accounts'] && <div className='home_button' onClick={() => handleClickMenuItem(<CashAccounts state={state} setState={setState} />)}>Cash Accounts ({cashaccounts.length})</div>}
          {homepermission['Clients'] && <div className='home_button' onClick={() => handleClickMenuItem(<Clients state={state} setState={setState} />)}>Clients ({clients.length})</div>}
          {homepermission['Contracts'] && <div className='home_button' onClick={() => handleClickMenuItem(<Contracts state={state} setState={setState} />)}>Contracts ({contracts.length})</div>}
          {homepermission['Collections'] && <div className='home_button' onClick={() => handleClickMenuItem(<Collections state={state} setState={setState} />)}>Collections</div>}
          {homepermission['EMS Readings'] && <div className='home_button' onClick={() => handleClickMenuItem(<EMSReadings state={state} setState={setState} />)}>EMS Readings ({emsreadings.length})</div>}
          {homepermission['EMS Tanks'] && <div className='home_button' onClick={() => handleClickMenuItem(<EMSTanks state={state} setState={setState} />)}>EMS Tanks ({emstanks.length})</div>}
          {homepermission['EMS Meters'] && <div className='home_button' onClick={() => handleClickMenuItem(<EMSMeters state={state} setState={setState} />)}>EMS Meters ({emsmeters.length})</div>}
          {homepermission['Inspections'] && <div className='home_button' onClick={() => handleClickMenuItem(<Inspections state={state} setState={setState} />)}>Inspections ({inspections.length})</div>}
          {homepermission['Interaccount Transfers'] && <div className='home_button' onClick={() => handleClickMenuItem(<InteraccountTransfers state={state} setState={setState} />)}>Interaccount Transfers ({interaccounttransfers.length})</div>}
          {homepermission['Journal Entries'] && <div className='home_button' onClick={() => handleClickMenuItem(<JournalEntries state={state} setState={setState} />)}>Journal Entries ({journalentries.length})</div>}
          {homepermission['Purchase Invoices'] && <div className='home_button' onClick={() => handleClickMenuItem(<PurchaseInvoices state={state} setState={setState} />)}>Purchase Invoices ({purchaseinvoices.length})</div>}
          {homepermission['Reconciliations'] && <div className='home_button' onClick={() => handleClickMenuItem(<Reconciliations state={state} setState={setState} />)}>Reconciliations ({reconciliations.length})</div>}
          {homepermission['Reports'] && <div className='home_button' onClick={() => handleClickMenuItem(<Reports state={state} setState={setState} />)}>Reports</div>}
          {homepermission['Suppliers'] && <div className='home_button' onClick={() => handleClickMenuItem(<Suppliers state={state} setState={setState} />)}>Suppliers ({suppliers.length})</div>}
          {homepermission['Tasks'] && <div className='home_button' onClick={() => handleClickMenuItem(<Tasks state={state} setState={setState} />)}>Tasks</div>}
          {homepermission['Transactions'] && <div className='home_button' onClick={() => handleClickMenuItem(<Transactions state={state} setState={setState} />)}>Transactions ({transactions.length})</div>}
          {homepermission['Settings'] && <div className='home_button' onClick={() => handleClickMenuItem(<Settings state={state} setState={setState} />)}>Settings</div>}
          {(state.user.usertype == 1 || state.user.usertype == 2) && <div className='home_button' onClick={() => handleClickMenuItem(<Users state={state} setState={setState} />)}>Users ({users.length})</div>}
          {(state.user.usertype == 2) && <div className='home_button' onClick={() => handleClickMenuItem(<Admin state={state} setState={setState} />)}>Admin</div>}
        </div>

        <div className='home_panel'>
          {option}
        </div>

        {
          screenwidth <= smallscreen &&
          <div className="home_floatingbutton" onClick={() => setTogglemenu(!togglemenu)}>
            <span className="home_floatingbuttonicon">+</span>
          </div>
        }
      </div>
    </div>
  );
}

export default Home;