import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { getPermission } from './Utils/permissions.js';
import Title from './Title';

import './Announcements.css';

//20240430

function Announcements({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddAnnouncement />);
    }

    const editButton = (announcement) => {
        setOption(<EditAnnouncement announcement={announcement} />);
    }

    const removeButton = (announcement) => {
        setOption(<RemoveAnnouncement announcement={announcement} />);
    }

    const viewButton = (announcement) => {
        setOption(<ViewAnnouncement announcement={announcement} />);
    }

    function AddAnnouncement() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);
        const [text, setText] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const addAnnouncement = (ts, text) => {
            console.log('Trying to add announcement');



            if (!text) {
                setResult('No text');
                return
            }

            const trimmedText = text.trim();

            const isAnnouncementDuplicate = project.announcements.some(announcement => announcement.text === trimmedText);
            if (isAnnouncementDuplicate) {
                setResult('Announcement with the same text exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                text: trimmedText
            }

            axios.post(configData.CONTROLLERURL + configData.ADDANNOUNCEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add announcement data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_ANNOUNCEMENT',
                            announcement: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult("Error")
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Announcement</div>
                        <div className='modal_row'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Description"
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={() => addAnnouncement(timestamp, text)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function EditAnnouncement({ announcement }) {
        const [id, setId] = useState('');
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);
        const [text, setText] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(announcement.id)
            setText(announcement.text);
        }, [announcement]);


        const updateAnnouncement = (id, ts, text) => {
            console.log('Trying to update announcement');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Announcements', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!text) {
                setResult('No text');
                return
            }

            const trimmedText = text.trim();

            const isAnnouncementDuplicate = project.announcements.some(announcement => announcement.text === trimmedText && announcement.id !== id);
            if (isAnnouncementDuplicate) {
                setResult('Announcement with the same text exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                text: trimmedText
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEANNOUNCEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update announcement data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_ANNOUNCEMENT',
                            announcement: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult("Error")
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Announcement</div>
                        <div className='modal_row'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Text"
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal_result'>{result && result}</div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={() => updateAnnouncement(id, timestamp, text)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        <button className="modal_button" onClick={() => removeButton(announcement)}>Remove</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAnnouncement({ announcement }) {
        const [id, setId] = useState(announcement.id);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const removeAnnouncement = (id) => {
            console.log('Trying to remove announcement');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Announcements', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEANNOUNCEMENT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove announcement data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_ANNOUNCEMENT',
                            announcementid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult("Error")
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Remove Announcement</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeAnnouncement(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ViewAnnouncement = ({ announcement }) => {
        const printRef = useRef();

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Announcement</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>ID</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_description'>{announcement.id}</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Description</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_description'>{announcement.text}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='announcements'>
            <Title text='Announcements' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Announcement</button>
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>Description</th></tr></thead>
                <tbody>
                    {
                        project.announcements.map((announcement, index) => (
                            <tr key={'announcement' + index}>
                                <td><div className='table_button' onClick={() => editButton(announcement)}>Edit</div></td>
                                <td><div className='table_button' onClick={() => viewButton(announcement)}>View</div></td>
                                <td><div>{moment.unix(announcement.ts).format('DD/MM/YYYY')}</div></td>
                                <td><div>{announcement.text}</div></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Announcements;