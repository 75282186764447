import React, { useState, useEffect } from 'react';
import { useStateValue } from '../StateProvider';
import axios from 'axios';
import configData from '../Config.json';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Title from '../Title';

import { getPermission } from '../Utils/permissions.js';
import '../Settings.css';

function StartDate({state,setState}) {
    const [{ project }, dispatch] = useStateValue();

    const [startdate, setStartdate] = useState('');
    const [timestamp, setTimestamp] = useState(0);

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (project.startdate === '') {
            const currentDate = new Date();
            setStartdate(currentDate);
            setTimestamp(Math.floor(currentDate.getTime() / 1000));
        }
        else {
            setStartdate(new Date(project.startdate * 1000));
            setTimestamp(project.startdate);
        }
    }, [project.startdate]);

    useEffect(() => {
        setTimestamp(Math.floor(new Date(startdate).getTime() / 1000));
    }, [startdate]);

    const handleSubmit = () => {
        console.log('Trying to save project details');
        setResult(null);

        const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Settings', 'add');
        if (!hasPermission) {
            setResult('No permission');
            return;
        }

        setLoading(true);

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            startdate: timestamp
        }

        axios.post(configData.CONTROLLERURL + configData.ADDSTARTDATE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Save project details data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult('Success')
                    dispatch({
                        type: 'ADD_STARTDATE',
                        startdate: timestamp,
                    });
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error');
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <div className='startdate'>
            <Title text='Start Date' />
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <div className='settings_label'>Select starting date</div>
                </div>
            </div>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <DatePicker
                        className='modal_datepicker'
                        selected={startdate}
                        onChange={(date) => setStartdate(date)}
                    />
                </div>
            </div>
            <div className='result'>{result && result}</div>
            <div className='buttonscontainer'>
                <button className='button' onClick={handleSubmit} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
            </div>
        </div>
    );
}

export default StartDate;