import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { getPermission } from './Utils/permissions.js';

import Title from './Title';

import './Users.css';

const permissionResources = ['Summary', 'Announcements', 'Assets', 'Chart of Accounts', 'Cash Accounts', 'Collections', 'Reconciliations', 'Clients', 'EMS Readings', 'EMS Tanks', 'EMS Meters', 'Inspections', 'Interaccount Transfers', 'Journal Entries', 'Purchase Invoices', 'Suppliers', 'Contracts', 'Tasks', 'Transactions', 'Reports', 'Settings'];

function Users({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [users, setUsers] = useState([]);

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setUsers(project.users)
    }, [project]);

    const cancelButton = () => {
        setOption(null)
    }

    const addButton = () => {
        setOption(<AddUser />)
    }

    const editButton = (user) => {
        setOption(<EditUser user={user} />)
    }

    const viewButton = (user) => {
        setOption(<ViewUser user={user} />)
    }

    const viewUsersStatus = (users) => {
        setOption(<ViewUsersStatus users={users} />);
    }

    const removeButton = (user) => {
        setOption(<RemoveUser user={user} />)
    }

    function PermissionRow({ resource, permissions, handleChange }) {
        return (
            <tr>
                <td><div>{resource}</div></td>
                <td>
                    <div>
                        <input
                            type="checkbox"
                            name="open"
                            checked={permissions?.open}
                            onChange={(e) => handleChange(resource, e)}
                        />
                    </div>
                </td>
                <td>
                    <div>
                        <input
                            type="checkbox"
                            name="add"
                            checked={permissions?.add}
                            onChange={(e) => handleChange(resource, e)}
                        />
                    </div>
                </td>
                <td>
                    <div>
                        <input
                            type="checkbox"
                            name="update"
                            checked={permissions?.update}
                            onChange={(e) => handleChange(resource, e)}
                        />
                    </div>
                </td>
                <td>
                    <div>
                        <input
                            type="checkbox"
                            name="remove"
                            checked={permissions?.remove}
                            onChange={(e) => handleChange(resource, e)}
                        />
                    </div>
                </td>
            </tr>
        );
    }

    function NewDevice({ row, index, handleEmailChangeDevice, handleRemoveDevice }) {
        return (
            <tr>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder='Email'
                        value={row.email}
                        onChange={(e) => handleEmailChangeDevice(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_cancelbutton' onClick={() => handleRemoveDevice(index)}>X</div>
                </td>
            </tr>
        );
    }

    function AddUser() {
        const [email, setEmail] = useState('');

        const [selectAllColumns, setSelectAllColumns] = useState({ open: false, add: false, update: false, remove: false });
        const [permissions, setPermissions] = useState([]);
        const [devices, setDevices] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState(null);

        const handleAddDevice = () => {
            setResult(null)
            setDevices([...devices, { email: '' }]);
        };

        const handleEmailChangeDevice = (e, index) => {
            const newDevices = [...devices];
            newDevices[index].email = e.target.value;
            setDevices(newDevices);
        };

        const handleRemoveDevice = (index) => {
            const newDevices = [...devices];
            newDevices.splice(index, 1);
            setDevices(newDevices);
        };

        const handleChange = (resource, event) => {
            const { name, checked } = event.target;
            setPermissions(prevPermissions => ({
                ...prevPermissions,
                [resource]: {
                    ...prevPermissions[resource],
                    [name]: checked
                }
            }));
        };

        const handleSelectColumnAll = (permissionResources, columnName) => {
            setPermissions(prevPermissions => {
                const updatedPermissions = { ...prevPermissions };
                let allChecked = true;

                for (const resource of permissionResources) {
                    if (!prevPermissions[resource]?.[columnName]) {
                        allChecked = false;
                        break;
                    }
                }

                for (const resource of permissionResources) {
                    updatedPermissions[resource] = {
                        ...updatedPermissions[resource],
                        [columnName]: !allChecked
                    };
                }

                return updatedPermissions;
            });
        };

        const addUser = (email, permissions, devices) => {
            console.log('Trying to add user');

            setResult(null);

            if (project.projectuserid == state.user.userid) {
                setResult('User is project owner');
                return
            }

            const userExists = project.users.some(user => user.email === email);
            const deviceExists = project.users.some(user => user.devices.some(device => device.email === email));
            if (userExists || deviceExists) {
                setResult('Email already exists ' + email);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                email: email,
                permissions: permissions,
                devices: devices
            }

            axios.post(configData.CONTROLLERURL + configData.ADDUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add user data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_USER',
                            user: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add User</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>User Email</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Permissions</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Section</th>
                                            <th>
                                                <label className="modal_label">
                                                    Open
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAllColumns['open']}
                                                        onChange={() => handleSelectColumnAll(permissionResources, 'open')}
                                                    />
                                                </label>
                                            </th>
                                            <th>
                                                <label className="modal_label">
                                                    Add
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAllColumns['add']}
                                                        onChange={() => handleSelectColumnAll(permissionResources, 'add')}
                                                    />
                                                </label>
                                            </th>
                                            <th>
                                                <label className="modal_label">
                                                    Update
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAllColumns['update']}
                                                        onChange={() => handleSelectColumnAll(permissionResources, 'update')}
                                                    />
                                                </label>
                                            </th>
                                            <th>
                                                <label className="modal_label">
                                                    Remove
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAllColumns['remove']}
                                                        onChange={() => handleSelectColumnAll(permissionResources, 'remove')}
                                                    />
                                                </label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            permissionResources.map((resource, index) => (
                                                <PermissionRow
                                                    key={index}
                                                    resource={resource}
                                                    permissions={permissions[resource]}
                                                    handleChange={handleChange}
                                                />
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Devices</div>
                            </div>
                        </div>
                        <table className='modal_table'>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    devices.length ?
                                        devices.map((row, index) => (
                                            <NewDevice
                                                key={index}
                                                row={row}
                                                index={index}
                                                handleEmailChangeDevice={handleEmailChangeDevice}
                                                handleRemoveDevice={handleRemoveDevice}
                                            />
                                        ))
                                        :
                                        <tr><td colSpan={2}>No devices</td></tr>
                                }
                            </tbody>
                        </table>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={handleAddDevice}>Add Device</button>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addUser(email, permissions, devices)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditUser({ user }) {
        const [id, setId] = useState('');
        const [email, setEmail] = useState('');

        const [selectAllColumns, setSelectAllColumns] = useState({ open: false, add: false, update: false, remove: false });
        const [permissions, setPermissions] = useState({ name: '', email: '' });
        const [devices, setDevices] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState(null);

        useEffect(() => {
            setId(user.id)
            setEmail(user.email)
            setPermissions(user.permissions)
            setDevices(user.devices)
        }, [user]);

        const handleAddDevice = () => {
            setResult(null)
            setDevices([...devices, { email: '' }]);
        };

        const handleEmailChangeDevice = (e, index) => {
            const deviceToRemove = devices[index];
            if (deviceToRemove.id) {
                setResult('Cannot change connected devices');
                return;
            }

            const newDevices = [...devices];
            newDevices[index].email = e.target.value;
            setDevices(newDevices);
        };

        const handleRemoveDevice = (index) => {
            const deviceToRemove = devices[index];
            if (deviceToRemove.id) {
                setResult('Cannot remove connected devices');
                return;
            }

            const newDevices = [...devices];
            newDevices.splice(index, 1);
            setDevices(newDevices);
        };

        const handleChange = (resource, event) => {
            const { name, checked } = event.target;
            setPermissions(prevPermissions => ({
                ...prevPermissions,
                [resource]: {
                    ...prevPermissions[resource],
                    [name]: checked
                }
            }));
        };

        const handleSelectColumnAll = (permissionResources, columnName) => {
            setPermissions(prevPermissions => {
                const updatedPermissions = { ...prevPermissions };
                let allChecked = true;

                for (const resource of permissionResources) {
                    if (!prevPermissions[resource]?.[columnName]) {
                        allChecked = false;
                        break;
                    }
                }

                for (const resource of permissionResources) {
                    updatedPermissions[resource] = {
                        ...updatedPermissions[resource],
                        [columnName]: !allChecked
                    };
                }

                return updatedPermissions;
            });
        };


        const updateUser = (id, email, permissions, devices) => {
            console.log('Trying to update user permissions');

            setResult(null);

            const emailExistsElsewhere = project.users.some(user => (user.email === email || user.devices.some(device => devices.some(newDevice => newDevice.email === device.email))) && user.id !== id);
            if (emailExistsElsewhere) {
                setResult('Email already exists in another user or device');
                return;
            }

            const connectedDeviceRemoved = user.devices.find(device => !devices.some(newDevice => newDevice.email === device.email) && device.id);
            if (connectedDeviceRemoved) {
                setResult('Cannot remove connected devices');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                email: email,
                permissions: permissions,
                devices: devices
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update user permission data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_USER',
                            user: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Update User</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>User ID</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                {id}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>User Email</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                {email}
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Permissions</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Section</th>
                                            <th>
                                                <label className="modal_label">
                                                    Open
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAllColumns['open']}
                                                        onChange={() => handleSelectColumnAll(permissionResources, 'open')}
                                                    />
                                                </label>
                                            </th>
                                            <th>
                                                <label className="modal_label">
                                                    Add
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAllColumns['add']}
                                                        onChange={() => handleSelectColumnAll(permissionResources, 'add')}
                                                    />
                                                </label>
                                            </th>
                                            <th>
                                                <label className="modal_label">
                                                    Update
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAllColumns['update']}
                                                        onChange={() => handleSelectColumnAll(permissionResources, 'update')}
                                                    />
                                                </label>
                                            </th>
                                            <th>
                                                <label className="modal_label">
                                                    Remove
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAllColumns['remove']}
                                                        onChange={() => handleSelectColumnAll(permissionResources, 'remove')}
                                                    />
                                                </label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            permissionResources.map((resource, index) => (
                                                <PermissionRow
                                                    key={index}
                                                    resource={resource}
                                                    permissions={permissions[resource]}
                                                    handleChange={handleChange}
                                                />
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Devices</div>
                            </div>
                        </div>
                        <table className='modal_table'>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    devices.length ?
                                        devices.map((row, index) => (
                                            <NewDevice
                                                key={index}
                                                row={row}
                                                index={index}
                                                handleEmailChangeDevice={handleEmailChangeDevice}
                                                handleRemoveDevice={handleRemoveDevice}
                                            />
                                        ))
                                        :
                                        <tr><td colSpan={2}>No devices</td></tr>
                                }
                            </tbody>
                        </table>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={handleAddDevice}>Add Device</button>
                        </div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateUser(id, email, permissions, devices)}>Update</button>
                            <button className="modal_button" onClick={() => removeButton(user)}>Remove User</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewUser({ user }) {
        const [id, setId] = useState('');
        const [email, setEmail] = useState('');
        const [devices, setDevices] = useState([]);

        const [permissions, setPermissions] = useState({});

        const printRef = useRef();

        useEffect(() => {
            setId(user.id)
            setEmail(user.email)
            setPermissions(user.permissions)
            setDevices(user.devices)
        }, [user]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>View User</div>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>User ID</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {id}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>User Email</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {email}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Permissions</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Section</th>
                                                <th>
                                                    <label className="modal_label">
                                                        Open
                                                    </label>
                                                </th>
                                                <th>
                                                    <label className="modal_label">
                                                        Add
                                                    </label>
                                                </th>
                                                <th>
                                                    <label className="modal_label">
                                                        Update
                                                    </label>
                                                </th>
                                                <th>
                                                    <label className="modal_label">
                                                        Remove
                                                    </label>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                permissionResources.map((resource, index) => (
                                                    <tr key={'resource' + index}>
                                                        <td>{resource}</td>
                                                        <td>{resource.open ? 'yes' : 'no'}</td>
                                                        <td>{resource?.read ? 'yes' : 'no'}</td>
                                                        <td>{resource?.update ? 'yes' : 'no'}</td>
                                                        <td>{resource?.remove ? 'yes' : 'no'}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Devices</div>
                                </div>
                            </div>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        devices.length ?
                                            devices.map((device, index) => {
                                                return (
                                                    <tr key={'device' + index}>
                                                        <td>{device.email}</td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr><td colSpan={4}>No devices</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal_buttonscontainer">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                            />
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewUsersStatus({ users }) {
        const [statusData, setStatusData] = useState([]);
        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');
        const [refresh, setRefresh] = useState(false);

        const allemails = users.flatMap(user => [{ id: user.id, type: 'user', email: user.email }, ...user.devices.map(device => ({ id: device.id, type: 'device', email: device.email }))]).filter(({ email }) => email);

        useEffect(() => {
            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                emails: allemails.map(({ email }) => email)
            }

            axios.post(configData.CONTROLLERURL + configData.GETUSERSSTATUS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Users status data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    setStatusData(res.data);
                }
            })
        }, [refresh]);

        const connectUser = (email) => {
            console.log('Trying to connect user');

            if (!email) {
                setResult('Missing info');
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                email: email,
            }

            axios.post(configData.CONTROLLERURL + configData.CONNECTUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Connect user data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setResult('Success')
                        setRefresh(!refresh)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const disconnectUser = (email) => {
            console.log('Trying to disconnect user');

            if (!email) {
                setResult('Missing info');
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                email: email,
            }

            axios.post(configData.CONTROLLERURL + configData.DISCONNECTUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Disconnect user data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setResult('Success')
                        setRefresh(!refresh)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const getStatus = (email) => {
            const status = statusData.find(item => item.email === email) || {};
            const { registered, connected, lastlogin } = status;

            if (!registered) return 'Not registered';
            if (!connected) return 'Not connected'
            if (!lastlogin) return 'No last login';
            if (lastlogin) return moment.unix(lastlogin).format('dddd, Do MMMM YYYY, h:mm:ss A');
        };

        const renderActionButton = (email) => {
            const status = statusData.find(item => item.email === email) || {};

            if (status.registered & !status.connected) {
                return (
                    <button className="add-client-button" onClick={() => connectUser(email)}>
                        Connect user
                    </button>
                );
            }

            if (status.connected) {
                return (
                    <button className="add-client-button" onClick={() => disconnectUser(email)}>
                        Disconnect user
                    </button>
                );
            }
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>View Users Status</div>
                        <table className='modal_table'>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Type</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allemails.map(({ id, type, email }, index) => (
                                        <tr key={'user' + index}>
                                            <td>{id}</td>
                                            <td>{type}</td>
                                            <td>{email}</td>
                                            <td>{getStatus(email)}</td>
                                            <td>{renderActionButton(email)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function RemoveUser({ user }) {
        const [id, setId] = useState('');
        const [email, setEmail] = useState('');
        const [devices, setDevices] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState(null);

        useEffect(() => {
            setId(user.id)
            setEmail(user.email)
            setDevices(user.devices)
        }, [user]);

        const removeUser = (user) => {
            console.log('Trying to remove user permissions');

            setResult(null)

            if (project.projectuserid == state.user.userid) {
                setResult('User is project owner');
                return
            }

            if (user.devices && user.devices.length > 0) {
                setResult('Please remove devices first');
                return
            }

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            setLoading(true)

            axios.post(configData.CONTROLLERURL + configData.REMOVEUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove user data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_USER',
                            userid: id
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            })
        }
        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeUser(email, setLoading)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const exportPage = () => {
        setOption(<ExportPage />)
    }

    function ExportPage() {
        useEffect(() => {
            const usersContent = JSON.stringify(project.users, null, 2);

            navigator.clipboard.writeText(usersContent)
                .then(() => console.log('Users data copied to clipboard'))
                .catch(err => console.error('Error copying users data to clipboard:', err));
        }, []);

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Users Exported</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={cancelButton}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const importPage = () => {
        setOption(<ImportPage />)
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [result, setResult] = useState('');

        const handleImport = () => {
            const importedUsers = JSON.parse(importText);
            if (!(importedUsers instanceof Array)) {
                setResult('Imported data is not in the expected format.');
                return;
            }

            const invalidUsers = importedUsers.filter(user => !project.users.find(existingUser => existingUser.id === user.id));
            if (invalidUsers.length > 0) {
                setResult(`The following users do not exist: ${invalidUsers.map(user => user.id).join(', ')}`);
                return;
            }

            console.log('Trying to update users');
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                users: importedUsers
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTUSERS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update users data received')
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_USERS',
                            users: importedUsers
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            })

            setResult('Users imported successfully.');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Import Users</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <textarea
                                    className='modal_textarea'
                                    value={importText}
                                    onChange={e => setImportText(e.target.value)}
                                    placeholder="Paste your import data here..."
                                    rows={10}
                                    cols={50}
                                    style={{ resize: 'vertical' }}
                                />
                            </div>
                        </div>
                        {result && <div className="modal_result">{result}</div>}
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={handleImport}>Import</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='users'>
            <Title text='Users' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add User</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={() => viewUsersStatus(project.users)}>View Users Status</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Id</th>
                        <th>Email</th>
                        <th>Devices</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td><div>{state.user.userid}</div></td>
                        <td><div>{state.user.useremail}</div></td>
                        <td></td>
                    </tr>
                    {
                        users.map((user, index) => {
                            return (
                                <tr key={'user' + index}>
                                    <td><div className='table_button' onClick={() => editButton(user)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(user)}>View</div></td>
                                    <td><div>{user.id}</div></td>
                                    <td><div>{user.email}</div></td>
                                    <td>{user.devices && user.devices.map((device, index) => <div key={'device' + index}>{device.email}</div>)}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div >
    );
}

export default Users;