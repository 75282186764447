import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { isValidNumber, hasMoreThanTwoDecimals } from './Utils/textparse.js';

import { getPermission } from './Utils/permissions.js';
import SupplierSelect from './Components/supplierselect.js';
import SubaccountSelect from './Components/subaccountselect.js';
import CurrencySelect from './Components/currencyselect.js';

import Title from './Title';

import './PurchaseInvoices.css';

function PurchaseInvoices({ state, setState }) {
    const [{ user, project }, dispatch] = useStateValue();

    const [filteredpurchaseinvoices, setFilteredPurchaseInvoices] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.purchaseinvoices.filter((invoice) =>
            invoice.name.toLowerCase().includes(search.toLowerCase()) ||
            invoice.id.includes(search) ||
            invoice.amount.includes(search)
        );

        setFilteredPurchaseInvoices(filtered);
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddPurchaseInvoice />);
    }

    const editButton = (purchaseinvoice) => {
        setOption(<EditPurchaseInvoice purchaseinvoice={purchaseinvoice} mode='edit' />);
    }

    const viewButton = (purchaseinvoice) => {
        setOption(<ViewPurchaseInvoice purchaseinvoice={purchaseinvoice} />);
    }

    const cloneButton = (purchaseinvoice) => {
        setOption(<EditPurchaseInvoice purchaseinvoice={purchaseinvoice} mode='clone' />);
    }

    const removeButton = (purchaseinvoice) => {
        setOption(<RemovePurchaseInvoice purchaseinvoice={purchaseinvoice} />);
    }

    function NewItem({ item, index, handleNameChange, handleQuantityChange, handleCostChange, handleRemoveItem }) {
        let itemNet = item.itemquantity * item.itemcost
        if (hasMoreThanTwoDecimals(itemNet)) {
            itemNet = parseFloat(itemNet.toFixed(2));
        }

        return (
            <tr>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        value={item.itemname}
                        onChange={(e) => handleNameChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        value={item.itemquantity}
                        onChange={(e) => handleQuantityChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        value={item.itemcost}
                        onChange={(e) => handleCostChange(e, index)}
                    />
                </td>
                <td>
                    {itemNet.toLocaleString('en-US')}
                </td>
                <td>
                    <div className='modal_cancelbutton' onClick={() => handleRemoveItem(index)}>X</div>
                </td>
            </tr>
        );
    }

    function AddPurchaseInvoice() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([{ itemname: '', itemquantity: 0, itemcost: 0 }]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setSuppliers(project.suppliers);
            setCurrencies(project.currencies);
        }, [project]);

        const handleAddItem = () => {
            setItems([...items, { itemname: '', itemquantity: 0, itemcost: 0 }])
        };

        const handleNameChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemname = selectedItem;
            setItems(updatedItems);
        };

        const handleQuantityChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemquantity = selectedItem;
            setItems(updatedItems);
        };

        const handleCostChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemcost = selectedItem;
            setItems(updatedItems);
        };

        const handleRemoveItem = (indexToRemove) => {
            if (items.length > 1) {
                const updateItems = items.filter((_, index) => index !== indexToRemove);
                setItems(updateItems);
            }
        };

        const totalAmount = items.reduce((total, item) => total + (item.itemquantity || 0) * (item.itemcost || 0), 0);

        const addPurchaseinvoice = (timestamp, supplierid, subaccount, currencyid, name, items, amount) => {
            console.log('Trying to add purchase invoice');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Purchase Invoices', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !supplierid || !currencyid || !subaccount) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                supplierid: supplierid,
                subaccount: subaccount,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.ADDPURCHASEINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add purchase invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_PURCHASEINVOICE',
                            purchaseinvoice: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        let totalNet = items.reduce((sum, item) => sum + (item.itemquantity * item.itemcost || 0), 0);
        if (hasMoreThanTwoDecimals(totalNet)) {
            totalNet = parseFloat(totalNet.toFixed(2));
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Purchase Invoice</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <SupplierSelect suppliers={suppliers} supplierid={supplierid} setSupplierid={setSupplierid} width={'auto'} position='fixed' />
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Subaccount</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <SubaccountSelect subaccounts={project.subaccounts} subaccountid={selectedsubaccount} setSubaccountid={setSelectedSubaccount} width={'auto'} position='fixed' />
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={'auto'} position='fixed' />
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='modal_scrollable'>
                            <table className='modal_table'>
                                <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th>Net</th><th></th></tr></thead>
                                <tbody>
                                    {items.map((item, index) => (
                                        <NewItem
                                            key={'item' + index}
                                            item={item}
                                            index={index}
                                            handleNameChange={handleNameChange}
                                            handleQuantityChange={handleQuantityChange}
                                            handleCostChange={handleCostChange}
                                            handleRemoveItem={handleRemoveItem}
                                        />
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><div>Total</div></td>
                                        <td></td>
                                        <td></td>
                                        <td><div>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {totalNet.toLocaleString('en-US')}</div></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="modal_buttonscontainer">
                            <div className='modal_button' onClick={handleAddItem}>Add item</div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addPurchaseinvoice(timestamp, supplierid, selectedsubaccount, currencyid, name, items, totalAmount)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditPurchaseInvoice({ purchaseinvoice, mode }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([{ itemname: '', itemquantity: 0, itemcost: 0 }]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(purchaseinvoice.id)
            setSelecteddate(new Date(purchaseinvoice.ts * 1000));
            setSuppliers(project.suppliers);
            setSupplierid(purchaseinvoice.supplierid);
            setSelectedSubaccount(purchaseinvoice.subaccount);
            setCurrencies(project.currencies);
            setCurrencyid(purchaseinvoice.currencyid);
            setName(purchaseinvoice.name);
            setItems(purchaseinvoice.items);
        }, []);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const handleAddItem = () => {
            setItems([...items, { itemname: '', itemquantity: 0, itemcost: 0 }])
        };

        const handleNameChange = (event, index) => {
            const selectedItem = event.target.value;
            const updatedItems = [...items];
            updatedItems[index].itemname = selectedItem;
            setItems(updatedItems);
        };

        const handleQuantityChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemquantity = selectedItem;
            setItems(updatedItems);
        };

        const handleCostChange = (event, index) => {
            const selectedItem = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedItems = [...items];
            updatedItems[index].itemcost = selectedItem;
            setItems(updatedItems);
        };

        const handleRemoveItem = (indexToRemove) => {
            if (items.length > 1) {
                const updateItems = items.filter((_, index) => index !== indexToRemove);
                setItems(updateItems);
            }
        };

        const updatePurchaseInvoice = (id, timestamp, supplierid, subaccount, currencyid, name, items, amount) => {
            console.log('Trying to update purcahse invoice');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Purchase Invoices', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !supplierid || !currencyid || !subaccount) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: timestamp,
                supplierid: supplierid,
                subaccount: subaccount,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEPURCHASEINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update purchase invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_PURCHASEINVOICE',
                            purchaseinvoice: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const addPurchaseinvoice = (timestamp, supplierid, subaccount, currencyid, name, items, amount) => {
            console.log('Trying to add purchase invoice');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Purchase Invoices', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !supplierid || !currencyid || !subaccount) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                supplierid: supplierid,
                subaccount: subaccount,
                currencyid: currencyid,
                name: trimmedname,
                items: items,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.ADDPURCHASEINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add purchase invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_PURCHASEINVOICE',
                            purchaseinvoice: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        let totalNet = items.reduce((sum, item) => sum + (item.itemquantity * item.itemcost || 0), 0);
        if (hasMoreThanTwoDecimals(totalNet)) {
            totalNet = parseFloat(totalNet.toFixed(2));
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Purchase Invoice</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <SupplierSelect suppliers={suppliers} supplierid={supplierid} setSupplierid={setSupplierid} width={'auto'} position='fixed' />
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Subaccount</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <SubaccountSelect subaccounts={project.subaccounts} subaccountid={selectedsubaccount} setSubaccountid={setSelectedSubaccount} width={'auto'} position='fixed' />
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={'auto'} position='fixed' />
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='modal_scrollable'>
                            <table className='modal_table'>
                                <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th>Net</th><th></th></tr></thead>
                                <tbody>
                                    {
                                        items.map((item, index) => (
                                            <NewItem
                                                key={'item' + index}
                                                item={item}
                                                index={index}
                                                handleNameChange={handleNameChange}
                                                handleQuantityChange={handleQuantityChange}
                                                handleCostChange={handleCostChange}
                                                handleRemoveItem={handleRemoveItem}
                                            />
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><div>Total</div></td>
                                        <td></td>
                                        <td></td>
                                        <td><div>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {totalNet.toLocaleString('en-US')}</div></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="modal_buttonscontainer">
                            <div className='modal_button' onClick={handleAddItem}>Add item</div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            {mode === 'edit' && <button className="modal_button" onClick={() => updatePurchaseInvoice(id, timestamp, supplierid, selectedsubaccount, currencyid, name, items, totalNet)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>}
                            {mode === 'clone' && <button className="modal_button" onClick={() => addPurchaseinvoice(timestamp, supplierid, selectedsubaccount, currencyid, name, items, totalNet)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>}
                            <button className="modal_button" onClick={() => removeButton(purchaseinvoice)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemovePurchaseInvoice({ purchaseinvoice }) {
        const [id, setId] = useState(purchaseinvoice.id);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const removePurchaseInvoice = (id) => {
            console.log('Trying to remove purchase invoice');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Purchase Invoices', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEPURCHASEINVOICE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove purchase invoice data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_PURCHASEINVOICE',
                            purchaseinvoiceid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Remove Purchase Invoice</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removePurchaseInvoice(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewPurchaseInvoice({ purchaseinvoice }) {
        const [timestamp, setTimestamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');
        const [subaccounts, setSubaccounts] = useState([]);
        const [subaccount, setSubaccount] = useState('');

        const [currencies, setCurrencies] = useState([]);
        const [currencyid, setCurrencyid] = useState('');

        const [name, setName] = useState('');

        const [items, setItems] = useState([]);

        const printRef = useRef();

        useEffect(() => {
            setTimestamp(purchaseinvoice.ts);
            setSuppliers(project.suppliers);
            setSupplierid(purchaseinvoice.supplierid);
            setSubaccounts(project.subaccounts)
            setSubaccount(purchaseinvoice.subaccount)
            setCurrencies(project.currencies);
            setCurrencyid(purchaseinvoice.currencyid);
            setName(purchaseinvoice.name);
            setItems(purchaseinvoice.items);
        }, [purchaseinvoice]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        let totalNet = items.reduce((sum, item) => sum + (item.itemquantity * item.itemcost || 0), 0);
        if (hasMoreThanTwoDecimals(totalNet)) {
            totalNet = parseFloat(totalNet.toFixed(2));
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_row'>
                                <div className='modal_rowsection'></div>
                                <div className='modal_rowsection'>
                                    <div className='modal_title'>Purchase Invoice</div>
                                    <div className='modal_date'>{moment.unix(timestamp).format('dddd Do MMMM YYYY')}</div>
                                </div>
                                <div className='modal_rowsection'></div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>Supplier</b> {suppliers.find(supplier => supplier.id === supplierid)?.name || 'Supplier not found'}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>Subaccount</b> {subaccounts.find(sub => sub.id === subaccount)?.name || 'Subaccount not found'}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>Name</b> {name}
                                </div>
                            </div>
                            <div className='modal_scrollable'>
                                <table className='modal_table'>
                                    <thead><tr><th>Item</th><th>Quantity</th><th>Cost</th><th>Net</th></tr></thead>
                                    <tbody>
                                        {
                                            items.map((item, index) => {
                                                let itemNet = item.itemquantity * item.itemcost
                                                if (hasMoreThanTwoDecimals(itemNet)) {
                                                    itemNet = parseFloat(itemNet.toFixed(2));
                                                }
                                                return (
                                                    <tr key={'item' + index}>
                                                        <td>{item.itemname}</td>
                                                        <td>{item.itemquantity}</td>
                                                        <td>{currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {item.itemcost}</td>
                                                        <td>{currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {itemNet.toLocaleString('en-US')}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Total</td>
                                            <td></td>
                                            <td></td>
                                            <td>{project.currencies.find(item => item.id === currencyid, 10)?.symbol || ''} {totalNet.toLocaleString('en-US')}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={() => cloneButton(purchaseinvoice)}>Clone</button>
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='purchaseinvoices'>
            <Title text='Purchase Invoices' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Purchase Invoice</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>ID</th><th>Supplier</th><th>Name</th><th>Amount</th></tr></thead>
                <tbody>
                    {
                        filteredpurchaseinvoices.sort((a, b) => b.ts - a.ts).map((purchaseinvoice, index) => {
                            let supplierName = project.suppliers.find(supplier => supplier.id === purchaseinvoice.supplierid)?.name || '';
                            const currency = project.currencies.find(item => item.id === purchaseinvoice.currencyid, 10)?.symbol || '';
                            const amount = parseFloat(purchaseinvoice.amount).toLocaleString('en-US');

                            return (
                                <tr key={'purchaseinvoice' + index}>
                                    <td><div className='table_button' onClick={() => editButton(purchaseinvoice, index)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(purchaseinvoice, index)}>View</div></td>
                                    <td><div>{moment.unix(purchaseinvoice.ts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{purchaseinvoice.id}</div></td>
                                    <td><div>{supplierName}</div></td>
                                    <td><div>{purchaseinvoice.name}</div></td>
                                    <td><div>{currency} {amount}</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default PurchaseInvoices;