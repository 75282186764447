import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';

import { getPermission } from './Utils/permissions.js';
import Title from './Title.js';
import AssetSelect from './Components/assetselect.js';

import './EMSTanks.css';

function EMSTanks({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [emstanks, setEmstanks] = useState([]);

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setEmstanks(project.tanks)
    }, [project.tanks]);

    const cancelButton = () => {
        setOption(null);
    }

    const addTankButton = () => {
        setOption(<AddTank />);
    }

    const editTankButton = (tank) => {
        setOption(<EditTank tank={tank} />);
    }

    const viewTankButton = (tank) => {
        setOption(<ViewTank tank={tank} />);
    }

    const removeTankButton = (tank) => {
        setOption(<RemoveTank tank={tank} />);
    }

    function AddTank() {
        const [name, setName] = useState('');
        const [assetid, setAssetid] = useState('');
        const [capacity, setCapacity] = useState(0);
        const [color, setColor] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addTank = (name, assetid, capacity, color) => {
            console.log('Trying to add tank');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'EMS Tanks', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !assetid || !capacity || !color) {
                setResult('Missing data');
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: name,
                assetid: assetid,
                capacity: capacity,
                color: color
            }

            axios.post(configData.CONTROLLERURL + configData.ADDTANK, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add tank data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_TANK',
                            tank: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Tank</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>EMS Tank Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Asset Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <AssetSelect assets={project.assets} assetid={assetid} setAssetid={setAssetid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Capacity</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    value={capacity}
                                    onChange={(e) => setCapacity(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Color (Hex Code)</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    placeholder='#00f'
                                    type="text"
                                    value={color}
                                    onChange={(e) => setColor(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addTank(name, assetid, capacity, color)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditTank({ tank }) {
        const [id, setId] = useState('');

        const [name, setName] = useState('');
        const [assetid, setAssetid] = useState('');
        const [capacity, setCapacity] = useState('');
        const [color, setColor] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(tank.id)
            setName(tank.name)
            setAssetid(tank.assetid)
            setCapacity(tank.capacity)
            setColor(tank.color)
        }, []);

        const updateReading = (id, name, assetid, capacity, color) => {
            console.log('Trying to update tank');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'EMS Tanks', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!assetid) {
                setResult('Please select an asset');
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: name,
                assetid: assetid,
                capacity: capacity,
                color: color
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATETANK, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update tank data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_TANK',
                            tank: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Tank</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>EMS Tank Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Asset Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <AssetSelect assets={project.assets} assetid={assetid} setAssetid={setAssetid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Capacity</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    value={capacity}
                                    onChange={(e) => setCapacity(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Color (Hex Code)</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    placeholder='#00f'
                                    type="text"
                                    value={color}
                                    onChange={(e) => setColor(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateReading(id, name, assetid, capacity, color)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeTankButton(tank)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewTank({ tank }) {
        const [assetid, setAssetid] = useState('');

        useEffect(() => {
            setAssetid(tank.assetid)
        }, [tank]);

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable'>
                            <div className='modal_row'>
                                <div className='modal_rowsection'></div>
                                <div className='modal_rowsection'>
                                    <div className='modal_title'>EMS Tank</div>
                                </div>
                                <div className='modal_rowsection'></div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>EMS Tank Name</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {tank.name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Asset Name</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {project.assets.find((asset) => asset.id === assetid)?.name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Capacity</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {tank.capacity}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Color (Hex Code)</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {tank.color}
                                </div>
                            </div>
                        </div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => editTankButton(tank)}>Edit</button>
                            <button className="modal_button" onClick={() => removeTankButton(tank)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveTank({ tank }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(tank.id);
        }, [tank]);

        const removeTank = (id) => {
            console.log('Trying to remove tank');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'EMS Tanks', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVETANK, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove tank data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_TANK',
                            tankid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Remove Reading ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeTank(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function Tank({ tank }) {
        const latestreading = project.readings.filter(reading => reading.assetid == tank.assetid).reduce((latest, current) => current.ts > latest.ts ? current : latest, { ts: 0 });
        let currentcapacity;
        if (latestreading) {
            currentcapacity = latestreading.amount
        }
        else {
            currentcapacity = 0;
        }
        const fullcapacity = tank.capacity;
        const percentageFilled = (currentcapacity / tank.capacity) * 100;
        const name = tank.name;
        const color = tank.color;
        return (
            <div className='emstanks_tank'>
                <div className="emstanks_tanktitle">
                    {name}
                </div>
                <div className="emstanks_tanksubtitle">
                    (Last refreshed: {moment.unix(latestreading.ts).fromNow()})
                </div>
                <div className="emstanks_tankcapacity" onClick={() => viewTankButton(tank)}>
                    <div
                        className="emstanks_tankcurrent"
                        style={{ height: `${percentageFilled}%`, backgroundColor: `${color}` }}
                    ></div>
                    <div className="emstanks_tanktext">
                        {currentcapacity}/{fullcapacity}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='emstanks'>
            <Title text='Energy Management System Tanks' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addTankButton}>Add Tank</button>
                </div>
            </div>

            <div className='emstanks_tanks'>
                {
                    emstanks.map((tank, index) => {
                        return (
                            <Tank key={'tank' + index} tank={tank} />
                        )
                    })
                }
            </div>

            {option}

        </div>
    );
}

export default EMSTanks;