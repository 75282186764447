export const isRTL = (text) => { //20240716
    return /[\u0600-\u06FF]/.test(text);
};

function getChecksum(value) { //20240917
    if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value);
    }
    return value;
}

export function areObjectsEqual(localObject, serverObject) { //20240917
    const changedKeys = [];

    const allKeys = new Set([...Object.keys(localObject), ...Object.keys(serverObject)]);

    for (const key of allKeys) {
        const localValue = localObject[key];
        const serverValue = serverObject[key];

        if (getChecksum(localValue) !== getChecksum(serverValue)) {
            changedKeys.push(key);
        }
    }

    return changedKeys;
}

export const isValidNumber = (value) => !isNaN(Number(value)) //20240716

export function isValidEmail(email) { //20240716
    return /\S+@\S+\.\S+/.test(email);
}

export function numberToWords(amount) { //20240716
    const converter = require('number-to-words');
    const words = converter.toWords(amount).split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
}

export const hasMoreThanTwoDecimals = (number) => { //20240716
    return (number * 1000) % 10 !== 0;
};

export function naturalCompare(a, b) {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
}