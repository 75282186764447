import { findNearestExchangeRate } from './currency.js';

export function getSupplierAccounts(suppliers, transactions, purchaseinvoices, journalentries, exchangerates, currencies, basecurrency) { //20240108
    const data = [];
    const currencySymbol = currencies.find(item => item.id === basecurrency)?.symbol || '';

    suppliers.forEach((supplier) => {
        let totalAmount = 0;

        purchaseinvoices.forEach((invoice) => {
            if (invoice.supplierid === supplier.id) {
                if (invoice.currencyid === basecurrency) {
                    totalAmount += parseFloat(invoice.amount)
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, invoice.ts, invoice.currencyid, basecurrency);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    totalAmount += parseFloat(invoice.amount) * exchangerate;
                }
            }
        });

        transactions.forEach((transaction) => {
            if (transaction.beneficiaryid === supplier.id) {
                if (transaction.currencyid === basecurrency) {
                    totalAmount -= parseFloat(transaction.amount)
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    totalAmount -= parseFloat(transaction.amount) * exchangerate;
                }
            }
        });

        journalentries.forEach((entry) => {
            if (entry.rows) {
                entry.rows.forEach((row) => {
                    if (row.accountid === supplier.id) {
                        if (entry.currencyid === basecurrency) {
                            totalAmount += parseFloat(row.debit - row.credit)
                        }
                        else {
                            const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, basecurrency);
                            const exchangerate = NearestExchangeRate.exchangerate;
                            totalAmount += parseFloat(row.debit - row.credit) * exchangerate;;
                        }
                    }
                });
            }
        });

        data.push({
            id: supplier.id,
            name: supplier.name,
            currency: currencySymbol,
            balance: totalAmount.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(totalAmount) ? 0 : 2, maximumFractionDigits: 2 })
        });
    });

    data.sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name, 'en', { numeric: true });
        if (nameComparison === 0) {
            return parseFloat(a.balance) - parseFloat(b.balance);
        }
        return nameComparison;
    });

    return data;
}

export function getSupplierAgingReport(suppliers, transactions, purchaseinvoices, journalentries, exchangerates, currencies, basecurrency) {
    const data = [];
    const currencySymbol = currencies.find(item => item.id === basecurrency)?.symbol || '';
    const now = Math.floor(new Date().getTime() / 1000);

    suppliers.forEach((supplier) => {
        let totalAmount = 0;
        const aging = {
            thirty: 0,
            sixty: 0,
            ninety: 0,
            overninety: 0,
        };

        purchaseinvoices.forEach((invoice) => {
            if (invoice.supplierid === supplier.id) {
                let invoiceAmount = parseFloat(invoice.amount);
                if (invoice.currencyid !== basecurrency) {
                    const nearestExchangeRate = findNearestExchangeRate(exchangerates, invoice.ts, invoice.currencyid, basecurrency);
                    const exchangeRate = nearestExchangeRate.exchangerate;
                    invoiceAmount *= exchangeRate;
                }

                const ageInDays = Math.floor((now - invoice.ts) / (60 * 60 * 24));
                totalAmount += invoiceAmount;

                if (ageInDays <= 30) {
                    aging.thirty += invoiceAmount;
                } else if (ageInDays <= 60) {
                    aging.sixty += invoiceAmount;
                } else if (ageInDays <= 90) {
                    aging.ninety += invoiceAmount;
                } else {
                    aging.overninety += invoiceAmount;
                }
            }
        });

        journalentries.forEach((entry) => {
            if (entry.rows) {
                entry.rows.forEach((row) => {
                    if (row.accountid === supplier.id) {
                        let rowAmount = parseFloat(row.debit - row.credit);
                        if (entry.currencyid !== basecurrency) {
                            const nearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, basecurrency);
                            const exchangeRate = nearestExchangeRate.exchangerate;
                            rowAmount *= exchangeRate;
                        }

                        const ageInDays = Math.floor((now - entry.ts) / (60 * 60 * 24));
                        totalAmount += rowAmount;

                        if (ageInDays <= 30) {
                            aging.thirty += rowAmount;
                        } else if (ageInDays <= 60) {
                            aging.sixty += rowAmount;
                        } else if (ageInDays <= 90) {
                            aging.ninety += rowAmount;
                        } else {
                            aging.overninety += rowAmount;
                        }
                    }
                });
            }
        });

        transactions.forEach((transaction) => {
            if (transaction.beneficiaryid === supplier.id) {
                let transactionAmount = parseFloat(transaction.amount);
                if (transaction.currencyid !== basecurrency) {
                    const nearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                    transactionAmount *= nearestExchangeRate.exchangerate;
                }

                if (transactionAmount > 0) {
                    if (aging.overninety > 0) {
                        const amountToDeduct = Math.min(aging.overninety, transactionAmount);
                        aging.overninety -= amountToDeduct;
                        transactionAmount -= amountToDeduct;
                    }

                    if (transactionAmount > 0 && aging.ninety > 0) {
                        const amountToDeduct = Math.min(aging.ninety, transactionAmount);
                        aging.ninety -= amountToDeduct;
                        transactionAmount -= amountToDeduct;
                    }

                    if (transactionAmount > 0 && aging.sixty > 0) {
                        const amountToDeduct = Math.min(aging.sixty, transactionAmount);
                        aging.sixty -= amountToDeduct;
                        transactionAmount -= amountToDeduct;
                    }

                    if (transactionAmount > 0 && aging.thirty > 0) {
                        const amountToDeduct = Math.min(aging.thirty, transactionAmount);
                        aging.thirty -= amountToDeduct;
                        transactionAmount -= amountToDeduct;
                    }
                }
            }
        });

        data.push({
            id: supplier.id,
            name: supplier.name,
            currency: currencySymbol,
            balance: totalAmount.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(totalAmount) ? 0 : 2, maximumFractionDigits: 2 }),
            aging: {
                thirty: aging.thirty.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                sixty: aging.sixty.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                ninety: aging.ninety.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                overninety: aging.overninety.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            }
        });
    });

    data.sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name, 'en', { numeric: true });
        if (nameComparison === 0) {
            return parseFloat(a.balance) - parseFloat(b.balance);
        }
        return nameComparison;
    });

    return data;
}
