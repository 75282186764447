import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import configData from './Config';

import { getPermission } from './Utils/permissions.js';
import Title from './Title';
import StatementAccount from './StatementAccount';
import { getSupplierAccounts } from './Utils/suppliers.js';
import { journalentryremovalprotection, transactionsremovalprotection, purchaseinvoicesremovalprotection, contractsremovalprotection } from './Utils/removeprotection.js';

import './Suppliers.css';

function Suppliers({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredsuppliers, setFilteredsuppliers] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    const suppliersdata = getSupplierAccounts(filteredsuppliers, project.transactions, project.purchaseinvoices, project.journalentries, project.exchangerates, project.currencies, project.basecurrency);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.suppliers.filter((supplier) =>
            supplier.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredsuppliers(filtered)
    }, [project, search]);

    const cancelButton = () => {
        setOption(null)
    }

    const addButton = () => {
        setOption(<AddSupplier />)
    }

    const editButton = (supplier) => {
        setOption(<EditSupplier supplier={supplier} />)
    }

    const viewButton = (supplier) => {
        setOption(<ViewSupplier supplier={supplier} />)
    }

    const removeButton = (supplier) => {
        setOption(<RemoveSupplier supplier={supplier} />)
    }

    const handleStatement = (type, account, setOption) => {
        setOption(<StatementAccount type={type} account={account} setOption={setOption} />)
    }

    function AddSupplier() {
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addSupplier = (name) => {
            console.log('Trying to add supplier');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Suppliers', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name) {
                setResult('Missing info');
                return;
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.suppliers.some(supplier => supplier.name === trimmedName);
            if (isNameDuplicate) {
                setResult('Supplier with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.ADDSUPPLIER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add supplier data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_SUPPLIER',
                            supplier: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Supplier</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addSupplier(name)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditSupplier({ supplier }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(supplier.id);
            setName(supplier.name);
        }, [supplier]);

        const updateSupplier = (id, name) => {
            console.log('Trying to update supplier');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Suppliers', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name) {
                setResult('Missing info');
                return;
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.suppliers.some(supplier => supplier.name === trimmedName && supplier.id !== id);
            if (isNameDuplicate) {
                setResult('Supplier with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATESUPPLIER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update supplier data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_SUPPLIER',
                            supplier: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Supplier</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateSupplier(id, name)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(supplier)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewSupplier({ supplier }) {
        const printRef = useRef();

        const [id, setId] = useState('');
        const [name, setName] = useState('');

        useEffect(() => {
            setId(supplier.id);
            setName(supplier.name);
        }, [supplier]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Supplier</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Name</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>ID</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {id}
                                </div>
                            </div>
                        </div>
                        <div className="modal_buttonscontainer">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                            />
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveSupplier({ supplier }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(supplier.id);
            setName(supplier.name);
        }, [supplier]);

        const removeSupplier = (id) => {
            console.log('Trying to remove supplier');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Suppliers', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const journalEntryData = journalentryremovalprotection(project.journalentries, id, 'suppliers');
            if (journalEntryData.code) {
                setResult(journalEntryData.message);
                return
            }

            const transactionData = transactionsremovalprotection(project.transactions, id, 'suppliers');
            if (transactionData.code) {
                setResult(transactionData.message);
                return
            }

            const purchaseinvoiceData = purchaseinvoicesremovalprotection(project.purchaseinvoices, id, 'suppliers');
            if (purchaseinvoiceData.code) {
                setResult(purchaseinvoiceData.message);
                return
            }

            const contractData = contractsremovalprotection(project.contracts, id, 'suppliers');
            if (contractData.code) {
                setResult(contractData.message);
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVESUPPLIER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove supplier data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_SUPPLIER',
                            supplierid: id
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeSupplier(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='suppliers'>
            <Title text='Suppliers' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>New Supplier</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Name</th><th>Balance</th></tr></thead>
                <tbody>
                    {
                        suppliersdata.map((supplier, index) => {

                            return (
                                <tr key={'supplier' + index}>
                                    <td><div className='table_button' onClick={() => editButton(supplier)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(supplier)}>View</div></td>
                                    <td><div>{supplier.name}</div></td>
                                    <td><div className='cash_balance' onClick={() => handleStatement('suppliers', { id: supplier.id, name: supplier.name, currencyid: project.basecurrency }, setOption)}>{supplier.currency} {supplier.balance.toLocaleString('en-US')}</div></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Suppliers;