import { findNearestExchangeRate } from './currency.js';

export function getClientAccounts(clients, transactions, journalentries, exchangerates, currencies, basecurrency) { //20240108
    const data = [];
    const currencySymbol = currencies.find((currency) => currency.id === basecurrency)?.symbol;

    clients.forEach((client) => {
        let balance = 0;

        transactions.forEach((transaction) => {
            if (transaction.from === client.id) {
                if (transaction.currencyid === basecurrency) {
                    balance -= parseFloat(transaction.amount);
                }
                else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, basecurrency);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    const convertedAmount = transaction.amount * exchangerate;
                    balance -= parseFloat(convertedAmount);
                }
            }
        });

        journalentries.forEach((entry) => {
            entry.rows.forEach((row) => {
                if (row.accountid === client.id) {
                    if (entry.currencyid === basecurrency) {
                        balance += parseFloat(row.debit);
                        balance -= parseFloat(row.credit);
                    }
                    else {
                        const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, basecurrency);
                        const exchangerate = NearestExchangeRate.exchangerate;
                        const convertedDebit = parseFloat(row.debit) * exchangerate;
                        const convertedCredit = parseFloat(row.credit) * exchangerate;
                        balance += parseFloat(convertedDebit);
                        balance -= parseFloat(convertedCredit);
                    }
                }
            });
        });

        data.push({
            id: client.id,
            name: client.name,
            email: client.email,
            currency: currencySymbol,
            balance: balance,
            numbers: client.numbers,
            devices: client.devices
        });
    });

    data.sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name, 'en', { numeric: true });
        if (nameComparison === 0) {
            return parseFloat(a.balance) - parseFloat(b.balance);
        }
        return nameComparison;
    });

    return data;
}