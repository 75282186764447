import moment from 'moment';

export function getContracts(contracts, suppliers) {
    const data = [];

    contracts.forEach((contract) => {
        const supplierName = suppliers.find((item) => item.id === contract.supplierid)?.name || '';
        const expiryDate = moment.unix(contract.to).format('D/MMM/YYYY');

        const contractData = {
            id: contract.id,
            to: expiryDate,
            suppliername: supplierName,
            name: contract.name,
        };

        data.push(contractData);
    });

    return data;
}