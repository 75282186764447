import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import ReactToPrint from 'react-to-print';

import { inspectionsremovalprotection, emsreadingsremovalprotection, emstanksremovalprotection, emsmetersremovalprotection } from './Utils/removeprotection.js';
import { getPermission } from './Utils/permissions.js';
import Title from './Title';

import './Assets.css';

function Assets({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredassets, setFilteredassets] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    const assetTypes = [
        { value: "accesscontrol", label: "Access Control" },
        { value: "boilersystem", label: "Boiler System" },
        { value: "commonareas", label: "Common Areas" },
        { value: "firextinguisher", label: "Fire Extinguisher" },
        { value: "generator", label: "Generator" },
        { value: "lift", label: "Lift" },
        { value: "meter", label: "Meter" },
        { value: "tank", label: "Tank" },
        { value: "filtrationsystem", label: "Filtration System" },
        { value: "videophone", label: "Video Phone" }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.assets.filter((asset) =>
            asset.name.toLowerCase().includes(search.toLowerCase()) ||
            asset.location.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredassets(filtered);
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddAsset />);
    }

    const editButton = (asset) => {
        setOption(<EditAsset asset={asset} />);
    }

    const removeButton = (asset) => {
        setOption(<RemoveAsset asset={asset} />);
    }

    const viewButton = (asset) => {
        setOption(<ViewAsset asset={asset} />);
    }

    function AddAsset() {
        const [type, setType] = useState('');
        const [name, setName] = useState('');
        const [location, setLocation] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addAsset = (type, name, location) => {
            console.log('Trying to add asset');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Assets', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!type || !name) {
                setResult('Missing data');
                return;
            }

            const trimmedName = name.trim();
            const trimmedLocation = location.trim();

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                type: type,
                name: trimmedName,
                location: trimmedLocation
            }

            axios.post(configData.CONTROLLERURL + configData.ADDASSET, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add asset data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_ASSET',
                            asset: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }


        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Asset</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    {assetTypes.map((asset, index) => (
                                        <option key={index} value={asset.value}>
                                            {asset.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Location</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Location"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addAsset(type, name, location)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditAsset({ asset }) {
        const [id, setId] = useState(asset.id);
        const [type, setType] = useState(asset.type);
        const [name, setName] = useState(asset.name);
        const [location, setLocation] = useState(asset.location);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const updateAsset = (id, type, name, location) => {
            console.log('Trying to update asset');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Assets', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!type, !name) {
                setResult('Missing data');
                return;
            }

            const trimmedName = name.trim();
            const trimmedLocation = location.trim();

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                type: type,
                name: trimmedName,
                location: trimmedLocation
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEASSET, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update asset data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_ASSET',
                            asset: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Asset</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select
                                    className='modal_select'
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    {assetTypes.map((asset, index) => (
                                        <option key={index} value={asset.value}>
                                            {asset.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Location</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Location"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateAsset(id, type, name, location)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(asset)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAsset({ asset }) {
        const [id, setId] = useState(asset.id);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const removeAsset = (id) => {
            console.log('Trying to remove asset');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Assets', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const inspectionData = inspectionsremovalprotection(project.inspections, id, 'assets');
            if (inspectionData.code) {
                setResult(inspectionData.message);
                return
            }

            const emsreadingsData = emsreadingsremovalprotection(project.readings, id, 'assets');
            if (emsreadingsData.code) {
                setResult(emsreadingsData.message);
                return
            }
            
            const emstanksData = emstanksremovalprotection(project.tanks, id, 'assets');
            if (emstanksData.code) {
                setResult(emstanksData.message);
                return
            }

            const emsmetersData = emsmetersremovalprotection(project.meters, id, 'assets');
            if (emsmetersData.code) {
                setResult(emsmetersData.message);
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEASSET, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove asset data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_ASSET',
                            assetid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeAsset(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewAsset({ asset }) {
        const [id, setId] = useState(asset.id);

        const printRef = useRef();

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Asset</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>ID</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_description'>{id}</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Name</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_description'>{asset.name}</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Location</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_description'>{asset.location}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='assets'>
            <Title text='Assets' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Asset</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredassets.map((asset, index) => (
                            <tr key={'asset' + index}>
                                <td><div className='table_button' onClick={() => editButton(asset)}>Edit</div></td>
                                <td><div className='table_button' onClick={() => viewButton(asset)}>View</div></td>
                                <td><div>{asset.id}</div></td>
                                <td><div>{asset.type}</div></td>
                                <td><div>{asset.name}</div></td>
                                <td><div>{asset.location}</div></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Assets;