import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import html3pdf from 'html3pdf';

import ClientSelect from './Components/clientselect.js';
import SupplierSelect from './Components/supplierselect.js';
import SubaccountSelect from './Components/subaccountselect.js';
import CurrencySelect from './Components/currencyselect.js';
import CashSelect from './Components/cashselect.js';
import AccountSelect from './Components/accountselect.js';
import Title from './Title';
import { numberToWords } from './Utils/textparse.js';
import { getPermission } from './Utils/permissions.js';

import './Transactions.css';

function Transactions({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredtransactions, setFilteredTransactions] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.transactions.filter((transaction) =>
            transaction.name.toLowerCase().includes(search.toLowerCase()) ||
            (project.clients.find(item => item.id === transaction.from)?.name.toLowerCase().includes(search.toLowerCase())) ||
            transaction.id.toLowerCase().includes(search.toLowerCase()) ||
            transaction.amount.includes(search)
        );
        setFilteredTransactions(filtered);
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addReceiptButton = () => {
        setOption(<AddReceipt />);
    }

    const addPaymentButton = () => {
        setOption(<AddPayment />);
    }

    const editPaymentButton = (transaction) => {
        setOption(<EditPayment transaction={transaction} />);
    }

    const editReceiptButton = (transaction) => {
        setOption(<EditReceipt transaction={transaction} />);
    }

    const viewReceiptButton = (transaction) => {
        setOption(<ViewReceipt transaction={transaction} />);
    }

    const viewPaymentButton = (transaction) => {
        setOption(<ViewPayment transaction={transaction} />);
    }

    const removeTransactionButton = (transaction) => {
        setOption(<RemoveTransaction transaction={transaction} />);
    }

    const viewTransactions = (transactions) => {
        setOption(<ViewTransactions transactions={transactions} />);
    }

    function AddReceipt() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);

        const [name, setName] = useState('');

        const [selectedclient, setSelectedclient] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [selectedaccount, setSelectedaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            dateAtMidday.setHours(12, 0, 0, 0);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const addReceipt = (ts, name, from, subaccount, to, amount, currencyid) => {
            console.log('Trying to add transaction');
            setResult(null);

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Transactions', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !selectedclient || !subaccount || !selectedaccount || !currencyid || amount === 0) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                name: trimmedName,
                from: from,
                subaccount: subaccount,
                to: to,
                beneficiaryid: '',
                amount: amount,
                currencyid: currencyid,
                type: 'receipt'
            }

            axios.post(configData.CONTROLLERURL + configData.ADDTRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_TRANSACTION',
                            transaction: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
                setSearch('')
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Receipt</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Client</div>
                            </div>
                            <div className='modal_rowsection'>
                                <ClientSelect clients={project.clients} clientid={selectedclient} setClientid={(clientId) => setSelectedclient(clientId)} width={300} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Subaccount</div>
                            </div>
                            <div className='modal_rowsection'>
                                <SubaccountSelect subaccounts={project.subaccounts} subaccountid={selectedsubaccount} setSubaccountid={setSelectedSubaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Cash Account</div>
                            </div>
                            <div className='modal_rowsection'>
                                <CashSelect cashaccounts={project.cashaccounts} accountid={selectedaccount} setAccountid={setSelectedaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Amount</div>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addReceipt(timestamp, name, selectedclient, selectedsubaccount, selectedaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    function AddPayment() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);

        const [name, setName] = useState('');

        const [selectedaccount, setSelectedaccount] = useState('');
        const [selectedexpense, setSelectedexpense] = useState('');
        const [selectedsupplier, setSelectedsupplier] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            dateAtMidday.setHours(12, 0, 0, 0);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const addPayment = (ts, name, from, to, supplier, subaccount, amount, currencyid) => {
            console.log('Trying to add transaction');
            setResult(null);

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Transactions', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !selectedaccount || !selectedexpense || !selectedsupplier || !subaccount || amount === 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                name: trimmedName,
                from: from,
                to: to,
                beneficiaryid: supplier,
                subaccount: subaccount,
                amount: amount,
                currencyid: currencyid,
                type: 'payment'
            }

            axios.post(configData.CONTROLLERURL + configData.ADDTRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_TRANSACTION',
                            transaction: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
                setSearch('')
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Payment</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Cash Account</div>
                            </div>
                            <div className='modal_rowsection'>
                                <CashSelect cashaccounts={project.cashaccounts} accountid={selectedaccount} setAccountid={setSelectedaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Expense Account</div>
                            </div>
                            <div className='modal_rowsection'>
                                <AccountSelect accounts={project.expenseaccounts} accountid={selectedexpense} setAccountid={setSelectedexpense} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                            <div className='modal_rowsection'>
                                <SupplierSelect suppliers={project.suppliers} supplierid={selectedsupplier} setSupplierid={setSelectedsupplier} width={300} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Subaccount</div>
                            </div>
                            <div className='modal_rowsection'>
                                <SubaccountSelect subaccounts={project.subaccounts} subaccountid={selectedsubaccount} setSubaccountid={setSelectedSubaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Amount</div>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addPayment(timestamp, name, selectedaccount, selectedexpense, selectedsupplier, selectedsubaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditReceipt({ transaction }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [ts, setTs] = useState(0);

        const [name, setName] = useState('');

        const [selectedclient, setSelectedclient] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [selectedaccount, setSelectedaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            dateAtMidday.setHours(12, 0, 0, 0);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTs(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(transaction.id);
            setSelecteddate(new Date(transaction.ts * 1000));
            setName(transaction.name);
            setSelectedclient(transaction.from);
            setSelectedSubaccount(transaction.subaccount);
            setSelectedaccount(transaction.to);
            setAmount(transaction.amount);
            setCurrencyid(transaction.currencyid);
        }, [transaction]);

        const updateReceipt = (id, ts, name, from, subaccount, to, amount, currencyid) => {
            console.log('Trying to update transaction');
            setResult(null);

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Transactions', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !selectedclient || !subaccount || !selectedaccount || !currencyid || amount === 0) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                name: trimmedName,
                from: from,
                subaccount: subaccount,
                to: to,
                beneficiaryid: '',
                amount: amount,
                currencyid: currencyid,
                type: 'receipt'
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATETRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_TRANSACTION',
                            transaction: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
                setSearch('')
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Transaction</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Client</div>
                            </div>
                            <div className='modal_rowsection'>
                                <ClientSelect clients={project.clients} clientid={selectedclient} setClientid={(clientId) => setSelectedclient(clientId)} width={300} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Subaccount</div>
                            </div>
                            <div className='modal_rowsection'>
                                <SubaccountSelect subaccounts={project.subaccounts} subaccountid={selectedsubaccount} setSubaccountid={setSelectedSubaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Cash Account</div>
                            </div>
                            <div className='modal_rowsection'>
                                <CashSelect cashaccounts={project.cashaccounts} accountid={selectedaccount} setAccountid={setSelectedaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Amount</div>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateReceipt(id, ts, name, selectedclient, selectedsubaccount, selectedaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeTransactionButton(transaction)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditPayment({ transaction }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [ts, setTs] = useState(0);

        const [name, setName] = useState('');

        const [selectedaccount, setSelectedaccount] = useState('');
        const [selectedexpense, setSelectedexpense] = useState('');
        const [selectedsupplier, setSelectedsupplier] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');

        const [amount, setAmount] = useState(0);
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            dateAtMidday.setHours(12, 0, 0, 0);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTs(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(transaction.id);
            setSelecteddate(new Date(transaction.ts * 1000));
            setName(transaction.name);
            setSelectedaccount(transaction.from)
            setSelectedSubaccount(transaction.subaccount);
            setSelectedexpense(transaction.to);
            setSelectedsupplier(transaction.beneficiaryid)
            setAmount(transaction.amount);
            setCurrencyid(transaction.currencyid);
        }, [transaction]);

        const updatePayment = (id, ts, name, from, to, supplier, subaccount, amount, currencyid) => {
            console.log('Trying to update transaction');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Transactions', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !from || !to || !supplier || !subaccount || amount === 0 || !currencyid) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                name: trimmedName,
                from: from,
                to: to,
                beneficiaryid: supplier,
                subaccount: subaccount,
                amount: amount,
                currencyid: currencyid,
                type: 'payment'
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATETRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_TRANSACTION',
                            transaction: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
                setSearch('')
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Transaction</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Cash Account</div>
                            </div>
                            <div className='modal_rowsection'>
                                <CashSelect cashaccounts={project.cashaccounts} accountid={selectedaccount} setAccountid={setSelectedaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Expense Account</div>
                            </div>
                            <div className='modal_rowsection'>
                                <AccountSelect accounts={project.expenseaccounts} accountid={selectedexpense} setAccountid={setSelectedexpense} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                            <div className='modal_rowsection'>
                                <SupplierSelect suppliers={project.suppliers} supplierid={selectedsupplier} setSupplierid={setSelectedsupplier} width={300} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Subaccount</div>
                            </div>
                            <div className='modal_rowsection'>
                                <SubaccountSelect subaccounts={project.subaccounts} subaccountid={selectedsubaccount} setSubaccountid={setSelectedSubaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Amount</div>
                            </div>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updatePayment(id, ts, name, selectedaccount, selectedexpense, selectedsupplier, selectedsubaccount, amount, currencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeTransactionButton(transaction)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    const ViewReceipt = ({ transaction }) => {
        const [copy, setCopy] = useState(0);
        const printRef = useRef();

        useEffect(() => {
            setCopy(2)
        }, [transaction]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        const saveAsPDF = () => {
            let clientName = project.clients.find((client) => client.id === transaction.from)?.name || '';
            const content = printRef.current;

            clientName = clientName.replace(/\s+/g, '_').toLowerCase();
            const currentDate = moment().format('DD_MM_YYYY');

            const options = {
                filename: 'receipt_' + clientName + '_' + currentDate + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html3pdf().from(content).set(options).save();
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='transactions_filters'>
                            <select value={copy} onChange={(e) => setCopy(e.target.value)}>
                                <option value={2}>2 copy</option>
                                <option value={3}>3 copy</option>
                            </select>
                        </div>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_scrollable'>
                                <div className='transactions_printable' ref={printRef}>
                                    <div className='transactions_receipt'>
                                        <div className='modal_head'>
                                            <div className='modal_row'>
                                                <div className='modal_rowsection'>
                                                    COPY FOR CLIENT
                                                </div>
                                                <div className='modal_rowsection'>
                                                    <div className='modal_title'>RECEIPT</div>
                                                    <div className='modal_subtitle'>ID. <b>{transaction.id}</b></div>
                                                    {/*transaction.serialid && <div className='modal_subtitle'>SERIAL. <b>{transaction.serialid}</b></div>*/}
                                                </div>
                                                <div className='modal_rowsection'>
                                                    <div className='modal_subtitle'>{project.projectdetails}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'>
                                                <div className='modal_center'>
                                                    {project.currencies.find(item => item.id === transaction.currencyid)?.symbol || ''}{parseFloat(transaction.amount).toLocaleString('en-US')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'>
                                                <b>Received from</b> {project.clients.find(item => item.id === transaction.from)?.name || 'Client not found'}
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'>
                                                <b>The sum of</b> {numberToWords(transaction.amount)} {project.currencies.find(item => item.id === transaction.currencyid)?.name || ''}
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'>
                                                <b>For</b> {transaction.name}
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'></div>
                                            <div className='modal_rowsection'>
                                                <div className='transactions_signature'></div>
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'></div>
                                            <div className='modal_rowsection'>
                                                <div className='modal_date'>{moment.unix(transaction.ts).format('dddd, Do MMMM YYYY')}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='transactions_separator'></div>

                                    <div className='transactions_receipt'>
                                        <div className='modal_head'>
                                            <div className='modal_row'>
                                                <div className='modal_rowsection'>
                                                </div>
                                                <div className='modal_rowsection'>
                                                    <div className='modal_title'>RECEIPT</div>
                                                    <div className='modal_subtitle'>ID. <b>{transaction.id}</b></div>
                                                    {/*transaction.serialid && <div className='modal_subtitle'>SERIAL. <b>{transaction.serialid}</b></div>*/}
                                                </div>
                                                <div className='modal_rowsection'>
                                                    <div className='modal_subtitle'>{project.projectdetails}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'>
                                                <div className='modal_center'>
                                                    {project.currencies.find(item => item.id === transaction.currencyid)?.symbol || ''}{parseFloat(transaction.amount).toLocaleString('en-US')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'>
                                                <b>Received from</b> {project.clients.find(item => item.id === transaction.from)?.name || 'Client not found'}
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'>
                                                <b>The sum of</b> {numberToWords(transaction.amount)} {project.currencies.find(item => item.id === transaction.currencyid)?.name || ''}
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'>
                                                <b>For</b> {transaction.name}
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'></div>
                                            <div className='modal_rowsection'>
                                                <div className='transactions_signature'></div>
                                            </div>
                                        </div>
                                        <div className='modal_row'>
                                            <div className='modal_rowsection'></div>
                                            <div className='modal_rowsection'>
                                                <div className='modal_date'>{moment.unix(transaction.ts).format('dddd, Do MMMM YYYY')}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='transactions_separator'></div>

                                    {
                                        copy == 3 &&
                                        <div className='transactions_receipt'>
                                            <div className='modal_head'>
                                                <div className='modal_row'>
                                                    <div className='modal_rowsection'>
                                                        COPY FOR COMMITTEE
                                                    </div>
                                                    <div className='modal_rowsection'>
                                                        <div className='modal_title'>RECEIPT</div>
                                                        <div className='modal_subtitle'>ID. <b>{transaction.id}</b></div>
                                                        {/*transaction.serialid && <div className='modal_subtitle'>SERIAL. <b>{transaction.serialid}</b></div>*/}
                                                    </div>
                                                    <div className='modal_rowsection'>
                                                        <div className='modal_subtitle'>{project.projectdetails}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='modal_row'>
                                                <div className='modal_rowsection'>
                                                    <div className='modal_center'>
                                                        {project.currencies.find(item => item.id === transaction.currencyid)?.symbol || ''}{parseFloat(transaction.amount).toLocaleString('en-US')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='modal_row'>
                                                <div className='modal_rowsection'>
                                                    <b>Received from</b> {project.clients.find(item => item.id === transaction.from)?.name || 'Client not found'}
                                                </div>
                                            </div>
                                            <div className='modal_row'>
                                                <div className='modal_rowsection'>
                                                    <b>The sum of</b> {numberToWords(transaction.amount)} {project.currencies.find(item => item.id === transaction.currencyid)?.name || ''}
                                                </div>
                                            </div>
                                            <div className='modal_row'>
                                                <div className='modal_rowsection'>
                                                    <b>For</b> {transaction.name}
                                                </div>
                                            </div>
                                            <div className='modal_row'>
                                                <div className='modal_rowsection'></div>
                                                <div className='modal_rowsection'>
                                                    <div className='transactions_signature'></div>
                                                </div>
                                            </div>
                                            <div className='modal_row'>
                                                <div className='modal_rowsection'></div>
                                                <div className='modal_rowsection'>
                                                    <div className='modal_date'>{moment.unix(transaction.ts).format('dddd, Do MMMM YYYY')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                            pageStyle={`
                                @page {
                                    size: A4;
                                }
                                @media print {
                                    tfoot {
                                        display: table-row-group;
                                    }
                                    @top-center, @bottom-center {
                                        content: none;
                                    }
                                }
                            `}
                        />
                        <button className="modal_button" onClick={saveAsPDF}>Save as PDF</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div >
        );
    };

    function ViewPayment({ transaction }) {
        const printRef = useRef();

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        const saveAsPDF = () => {
            let supplierName = project.suppliers.find((supplier) => supplier.id === transaction.to)?.name || '';
            const content = printRef.current;

            supplierName = supplierName.replace(/\s+/g, '_').toLowerCase();
            const currentDate = moment().format('DD_MM_YYYY');

            const options = {
                filename: 'receipt_' + supplierName + '_' + currentDate + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html3pdf().from(content).set(options).save();
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_head'>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                    </div>
                                    <div className='modal_rowsection'>
                                        <div className='modal_title'>PAYMENT</div>
                                        <div className='modal_subtitle'>ID. <b>{transaction.id}</b></div>
                                    </div>
                                    <div className='modal_rowsection'>
                                        <div className='modal_subtitle'>{project.projectdetails}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_center'>
                                        {project.currencies.find(item => item.id === transaction.currencyid)?.symbol || ''}{parseFloat(transaction.amount).toLocaleString('en-US')}
                                    </div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>Payment to</b> {project.expenseaccounts.find(item => item.id === transaction.to)?.name || ''}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>The sum of</b> {numberToWords(transaction.amount)} {project.currencies.find(item => item.id === transaction.currencyid)?.name || ''}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>Beneficiary</b> {project.suppliers.find(item => item.id === transaction.beneficiaryid)?.name || ''}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>For</b> {transaction.name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'></div>
                                <div className='modal_rowsection'>
                                    <div className='modal_date'>{moment.unix(transaction.ts).format('dddd, Do MMMM YYYY')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={saveAsPDF}>Save as PDF</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }

    function RemoveTransaction({ transaction }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(transaction.id);
        }, [transaction]);

        const removeTransaction = (id) => {
            console.log('Trying to remove transaction');
            setResult(null);

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Transactions', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            setLoading(true);

            axios.post(configData.CONTROLLERURL + configData.REMOVETRANSACTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove transaction data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_TRANSACTION',
                            id: id
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
                setSearch('')
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Remove Transaction</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeTransaction(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewTransactions({ transactions }) {
        const printRef = useRef();

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };
        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Transactions</div>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>ID</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Name</th>
                                        <th>Amount</th>
                                        <th>Check</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactions.sort((a, b) => b.ts - a.ts).map((transaction, index) => {
                                            if (transaction.type === 'receipt') {
                                                return (
                                                    <tr key={'transaction' + index} className='transactions_receipt'>
                                                        <td><div>{moment.unix(transaction.ts).format('D/MMM/YYYY')}</div></td>
                                                        <td><div>{transaction.type}</div></td>
                                                        <td><div>{transaction.id}</div></td>
                                                        <td>
                                                            <div>{project.clients.find(item => item.id === transaction.from)?.name || ''}</div>
                                                        </td>
                                                        <td>
                                                            <div>{project.cashaccounts.find(item => item.id === transaction.to)?.name || ''}</div>
                                                        </td>
                                                        <td><div>{transaction.name}</div></td>
                                                        <td><div>{project.currencies.find(item => item.id === transaction.currencyid, 10)?.symbol || 'Currency not found'} {parseFloat(transaction.amount).toLocaleString('en-US')}</div></td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            }
                                            if (transaction.type === 'payment') {
                                                return (
                                                    <tr key={'transaction' + index} className='transactions_payment'>
                                                        <td><div>{moment.unix(transaction.ts).format('D/MMM/YYYY')}</div></td>
                                                        <td><div>{transaction.type}</div></td>
                                                        <td><div>{transaction.id}</div></td>
                                                        <td>
                                                            <div>{project.cashaccounts.find(item => item.id === transaction.from)?.name || ''}</div>
                                                        </td>
                                                        <td>
                                                            <div>{project.expenseaccounts.find(account => account.id === transaction.to)?.name || ''}</div>
                                                        </td>
                                                        <td><div>{transaction.name}</div></td>
                                                        <td><div>{project.currencies.find(item => item.id === transaction.currencyid, 10)?.symbol || 'Currency not found'} {parseFloat(transaction.amount).toLocaleString('en-US')}</div></td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                        <div className="modal_buttonscontainer">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                            />
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                </div>
            </div>
        )
    }

    const serializereceipts = () => {
        console.log('Trying to serialize receipts');

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid
        }

        axios.post(configData.CONTROLLERURL + configData.SERIALIZERECEIPTS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Serialize receipts data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                }
                else {
                }
            }
            else {
            }
        }).catch(() => {
        }).finally(() => {
        });
    }

    return (
        <div className='transactions'>
            <Title text='Transactions' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addReceiptButton}>Receipt</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={addPaymentButton}>Payment</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={() => viewTransactions(project.transactions)}>View Transactions</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <table className='table'>
                    <thead><tr><th></th><th></th><th>Date</th><th>Type</th><th>ID</th><th>From</th><th>To</th><th>Name</th><th>Amount</th></tr></thead>
                    <tbody>
                        {
                            filteredtransactions.sort((a, b) => b.ts - a.ts).map((transaction, index) => {
                                if (transaction.type === 'receipt') {
                                    return (
                                        <tr key={'transaction' + index} className='transactions_receipt'>
                                            <td><div className='table_button' onClick={() => editReceiptButton(transaction)}>Edit</div></td>
                                            <td><div className='table_button' onClick={() => viewReceiptButton(transaction)}>View</div></td>
                                            <td><div>{moment.unix(transaction.ts).format('D/MMM/YYYY')}</div></td>
                                            <td><div>{transaction.type}</div></td>
                                            <td><div>{transaction.id}</div></td>
                                            <td>
                                                <div>{project.clients.find(item => item.id === transaction.from)?.name || ''}</div>
                                            </td>
                                            <td>
                                                <div>{project.cashaccounts.find(item => item.id === transaction.to)?.name || ''}</div>
                                            </td>
                                            <td><div>{transaction.name}</div></td>
                                            <td><div>{project.currencies.find(item => item.id === transaction.currencyid, 10)?.symbol || 'Currency not found'} {parseFloat(transaction.amount).toLocaleString('en-US')}</div></td>
                                        </tr>
                                    )
                                }
                                if (transaction.type === 'payment') {
                                    return (
                                        <tr key={'transaction' + index} className='transactions_payment'>
                                            <td><div className='table_button' onClick={() => editPaymentButton(transaction)}>Edit</div></td>
                                            <td><div className='table_button' onClick={() => viewPaymentButton(transaction)}>View</div></td>
                                            <td><div>{moment.unix(transaction.ts).format('D/MMM/YYYY')}</div></td>
                                            <td><div>{transaction.type}</div></td>
                                            <td><div>{transaction.id}</div></td>
                                            <td>
                                                <div>{project.cashaccounts.find(item => item.id === transaction.from)?.name || ''}</div>
                                            </td>
                                            <td>
                                                <div>{project.expenseaccounts.find(account => account.id === transaction.to)?.name || ''}</div>
                                            </td>
                                            <td><div>{transaction.name}</div></td>
                                            <td><div>{project.currencies.find(item => item.id === transaction.currencyid, 10)?.symbol || 'Currency not found'} {parseFloat(transaction.amount).toLocaleString('en-US')}</div></td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    </tbody>
                </table>
            </div>
            {option}

        </div>
    );
}

export default Transactions;