import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import configData from './Config';
import { useStateValue } from './StateProvider';

import './AddProject.css';

function AddProject({ state, setState }) {
    const [{ }, dispatch] = useStateValue();

    const [name, setName] = useState('');

    const navigation = useNavigate();

    const addProject = (name) => {
        console.log('Trying to add project');

        const data = {
            projectuserid: state.user.userid,
            name: name
        }

        axios.post(configData.CONTROLLERURL + configData.ADDPROJECT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Add project data received')
            console.log(res.data)
            if (res.data.code === 1) {
                data.id = res.data.id;
                dispatch({
                    type: 'ADD_PROJECT',
                    project: data
                });
                navigation('/');
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const cancel = () => {
        navigation('/');
    }

    return (
        <div className='addproject'>
            <div className='addproject_title'>Create New Project</div>
            <div>
                <input
                    className='input'
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div>
                <button className='button' onClick={() => addProject(name)}>Add Project</button>
                <button className='button' onClick={cancel}>Cancel</button>
            </div>
        </div>
    );
}

export default AddProject;