import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import { useNavigate } from 'react-router-dom';

import header_logo from './assets/header_logo.png';
import header_notifications from './assets/header_notifications.png';
import header_logout from './assets/header_logout.png';
import header_login from './assets/header_login.png';

import './Header.css';

function Header({ state, setState }) {
    const [{ }, dispatch] = useStateValue();

    const navigation = useNavigate();

    const [notifications, setNotifications] = useState(false);

    const notificationRef = useRef(null);

    const handleLogo = () => {
        navigation('/');
    };

    const handleNotifications = () => {
        if(notifications) setNotifications(false)
        else setNotifications(true)
    };

    async function handleLogout() {
        setNotifications(null);
        setState(state => ({
            ...state, user: {
                userid: null,
                usertoken: 0,
                useremail: null,
                usertype: 0,
                username: null,
            }
        }))
        dispatch({
            type: "UNSET_PROJECT"
        });
        await localStorage.removeItem('user');
        navigation('/');
    }

    const handleLogin = () => {
        navigation('authscreen');
    };

    useEffect(() => {
        if (notifications) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [notifications]);

    const handleClickOutside = (event) => {
        if (notificationRef.current && !notificationRef.current.contains(event.target)) {
            setNotifications(false);
        }
    };
    
    return (
        <div className='header'>
            <div className='header_content'>
                <div className='header_section'>
                    <div className='header_logo' onClick={handleLogo}>
                        <img className='header_logoimage' src={header_logo} alt="Logo" />
                    </div>
                </div>
                <div className='header_section'></div>
                <div className='header_section'></div>
                {
                    state.user.userid &&
                    <div className='header_section'>
                        <div className='header_notifications' onClick={handleNotifications}>
                            <img className='header_notificationsimage' src={header_notifications} alt="Notifications" />
                        </div>
                        {
                            notifications &&
                            <div className='header_dropdown' ref={notificationRef}>
                                <div className='header_dropdownitem'>No notifications</div>
                            </div>
                        }
                    </div>
                }
                {
                    state.user.userid &&
                    <div className='header_section'>
                        <div className='header_logout' onClick={handleLogout}>
                            <img className='header_logoutimage' src={header_logout} alt="Logout" />
                        </div>
                    </div>
                }
                {
                    !state.user.userid &&
                    <div className='header_section'>
                        <div className='header_login' onClick={handleLogin}>
                            <img className='header_loginimage' src={header_login} alt="Login" />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Header;