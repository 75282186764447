import React, { useState, useEffect, useRef } from 'react';

import './select.css';

const SupplierSelect = ({ suppliers, supplierid, setSupplierid, width, position }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const handleSelect = (supplierId) => {
        setSupplierid(supplierId);
        setShowDropdown(false);
    };
    
    const filteredSuppliers = [...suppliers]
    .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
    .filter(
        (supplier) =>
            supplier.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            supplier.id !== supplierid
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        if (showDropdown && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showDropdown]);

    const clearSelection = () => {
        handleSelect(null);
        setShowDropdown(false);
        setSupplierid('');
    };

    const dropdown = () => {
        setShowDropdown(true)
        setSearchTerm('')
    }

    return (
        <div className="inputgroup" ref={dropdownRef} style={{ width: width }}>
            <input
                type="text"
                className='inputgroup_input'
                placeholder="Select a supplier"
                value={supplierid ? suppliers.find(supplier => supplier.id === supplierid)?.name : ''}
                onClick={dropdown}
                readOnly
            />
            {supplierid && (
                <button className="inputgroup_clearbutton" onClick={clearSelection}>
                    X
                </button>
            )}
            {showDropdown && (
                <div className="inputgroup_dropdown" style={{ position: position }}>
                    <input
                        type="text"
                        className="inputgroup_dropdowninput"
                        placeholder="Search suppliers"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        ref={inputRef}
                    />
                    <ul className='inputgroup_dropdownul'>
                        {filteredSuppliers.map((supplier, index) => (
                            <li
                                key={'supplier' + index}
                                className='inputgroup_dropdownli'
                                onClick={() => handleSelect(supplier.id)}
                            >
                                {supplier.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SupplierSelect;