import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import axios from 'axios';
import configData from './Config';

import { getPermission } from './Utils/permissions.js';
import SupplierSelect from './Components/supplierselect.js';
import CurrencySelect from './Components/currencyselect.js';
import Title from './Title';
import ReactToPrint from 'react-to-print';

import './Contracts.css';

function Contracts({ state, setState }) {
    const [{ user, project }, dispatch] = useStateValue();

    const [filteredcontracts, setFilteredcontracts] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.contracts.filter((contract) =>
            contract.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredcontracts(filtered)
    }, [project, search]);

    const cancelButton = () => {
        setOption(null)
    }

    const addButton = () => {
        setOption(<AddContract />)
    }

    const editButton = (contract) => {
        setOption(<EditContract contract={contract} />)
    }

    const removeButton = (contract) => {
        setOption(<RemoveContract contract={contract} />)
    }

    const viewButton = (contract) => {
        setOption(<ViewContract contract={contract} />)
    }

    const exportPage = () => {
        setOption(<ExportPage />)
    }

    function ExportPage() {
        useEffect(() => {
            if (!project || !project.contracts || project.contracts.length === 0) {
                console.log('No contracts data to export.');
                return;
            }

            const contractsContent = JSON.stringify(project.contracts, null, 2);

            navigator.clipboard.writeText(contractsContent)
                .then(() => console.log('Contracts data copied to clipboard'))
                .catch(err => console.error('Error copying contracts data to clipboard:', err));
        }, []);

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Contracts Exported</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={cancelButton}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    const importPage = () => {
        setOption(<ImportPage />)
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [result, setResult] = useState('');

        const handleImport = () => {
            const importedContracts = JSON.parse(importText);
            if (!(importedContracts instanceof Array)) {
                setResult('Imported data is not in the expected format.');
                return;
            }

            const duplicate = importedContracts.find(importContract =>
                project.contracts.some(projectContract =>
                    projectContract.id === importContract.id
                )
            );

            if (duplicate) {
                setResult(`Contract already exists with ID: ${duplicate.id} or Name: ${duplicate.name}`);
                return;
            }

            console.log('Trying to import contracts');
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                contracts: importedContracts
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTCONTRACTS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import contracts data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_CONTRACTS',
                            contracts: importedContracts
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            })

            setResult('Contracts imported successfully.');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Import Contracts</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <textarea
                                    className='modal_textarea'
                                    value={importText}
                                    onChange={e => setImportText(e.target.value)}
                                    placeholder="Paste your import data here..."
                                    rows={10}
                                    cols={50}
                                    style={{ resize: 'vertical' }}
                                />
                            </div>
                        </div>
                        {result && <div className="modal_result">{result}</div>}
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={handleImport}>Import</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function NewRow({ row, index, handleRowChange, handleRemoveRow }) {
        return (
            <tr>
                <td>
                    <DatePicker
                        className='modal_datepicker'
                        selected={row.date ? new Date(row.date * 1000) : null}
                        onChange={(date) => handleRowChange(date, 'date', index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder="Name"
                        value={row.name}
                        onChange={(e) => handleRowChange(e.target.value, 'name', index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder="0"
                        value={row.payment}
                        onChange={(e) => handleRowChange(e.target.value, 'payment', index)}
                    />
                </td>
                <td>
                    <div className='modal_cancelbuttoncontainer'>
                        <div className='modal_cancelbutton' onClick={() => handleRemoveRow(index)}>X</div>
                    </div>
                </td>
            </tr>
        );
    }

    function AddContract() {
        const [from, setFrom] = useState(new Date());
        const [fromts, setFromts] = useState(0);
        const [to, setTo] = useState(new Date());
        const [tots, setTots] = useState(0);

        const [supplierid, setSupplierid] = useState('');
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [rows, setRows] = useState([{ date: Math.floor(Date.now() / 1000), name: '', amount: 0 }]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setFromts(Math.floor(new Date(from).getTime() / 1000));
        }, [from]);

        useEffect(() => {
            setTots(Math.floor(new Date(to).getTime() / 1000));
        }, [to]);

        const handleRowChange = (value, field, index) => {
            const updatedRows = [...rows];
            if (field == 'date') {
                updatedRows[index][field] = Math.floor(new Date(value).getTime() / 1000);
            }
            else {
                updatedRows[index][field] = value;
            }
            setRows(updatedRows);
        };

        const handleRemoveRow = (indexToRemove) => {
            setRows(rows.filter((_, index) => index !== indexToRemove));
        };

        let amount = rows.reduce((sum, item) => sum + (Number(item.payment) || 0), 0);
        let currency = project.currencies.find((currency) => currency.id === currencyid)?.symbol || '';

        const handleAddRow = () => {
            const lastRow = rows[rows.length - 1];
            setRows([...rows, { ...lastRow }]);
        };

        const addContract = (fromts, tots, supplierid, name, currencyid, rows, amount) => {
            console.log('Trying to add contract');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Contracts', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!supplierid || !name || !currencyid || amount == 0) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                from: fromts,
                to: tots,
                supplierid: supplierid,
                name: trimmedName,
                currencyid: currencyid,
                rows: rows,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.ADDCONTRACT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add contract data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_CONTRACT',
                            contract: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Contract</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>From</div>
                            </div>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={from}
                                    onChange={(date) => setFrom(date)}
                                />
                            </div>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>To</div>
                            </div>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={to}
                                    onChange={(date) => setTo(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <SupplierSelect suppliers={project.suppliers} supplierid={supplierid} setSupplierid={setSupplierid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Payment Terms</div>
                            </div>
                        </div>
                        <table className='modal_table'>
                            <thead><tr><th>Date</th><th>Name</th><th>Payment</th><th></th></tr></thead>
                            <tbody>
                                {
                                    rows.map((row, index) => (
                                        <NewRow
                                            key={'row' + index}
                                            row={row}
                                            index={index}
                                            handleRowChange={handleRowChange}
                                            handleRemoveRow={handleRemoveRow}
                                        />
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><div>Total</div></td>
                                    <td><div></div></td>
                                    <td><div>{amount} {currency}</div></td>
                                    <td><div></div></td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className='modal_result'>{result && result}</div>
                        <button className='modal_button' onClick={handleAddRow}>Add row</button>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addContract(fromts, tots, supplierid, name, currencyid, rows, amount)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    function EditContract({ contract }) {
        const [id, setId] = useState('');

        const [from, setFrom] = useState(new Date());
        const [fromts, setFromts] = useState(0);
        const [to, setTo] = useState(new Date());
        const [tots, setTots] = useState(0);

        const [supplierid, setSupplierid] = useState('');
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [rows, setRows] = useState([]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(contract.id);
            setFrom(new Date(contract.from * 1000));
            setTo(new Date(contract.to * 1000));
            setSupplierid(contract.supplierid);
            setName(contract.name);
            setCurrencyid(contract.currencyid);
            setRows(contract.rows);
        }, [contract]);

        useEffect(() => {
            setFromts(Math.floor(new Date(from).getTime() / 1000));
        }, [from]);

        useEffect(() => {
            setTots(Math.floor(new Date(to).getTime() / 1000));
        }, [to]);

        const handleRowChange = (value, field, index) => {
            const updatedRows = [...rows];
            if (field == 'date') {
                updatedRows[index][field] = Math.floor(new Date(value).getTime() / 1000);
            }
            else {
                updatedRows[index][field] = value;
            }
            setRows(updatedRows);
        };

        const handleRemoveRow = (indexToRemove) => {
            setRows(rows.filter((_, index) => index !== indexToRemove));
        };

        let amount = rows.reduce((sum, item) => sum + (Number(item.payment) || 0), 0);
        let currency = project.currencies.find((currency) => currency.id === currencyid)?.symbol || '';

        const handleAddRow = () => {
            const lastRow = rows[rows.length - 1];
            setRows([...rows, { ...lastRow }]);
        };

        const updateContract = (id, fromts, tots, supplierid, name, currencyid, rows, amount) => {
            console.log('Trying to update contract');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Contracts', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!supplierid || !name || amount == 0) {
                setResult('Missing info');
                return;
            }

            setLoading(true);

            const trimmedName = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                from: fromts,
                to: tots,
                supplierid: supplierid,
                name: trimmedName,
                currencyid: currencyid,
                rows: rows,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATECONTRACT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update contract data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_CONTRACT',
                            contract: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Contract</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>From</div>
                            </div>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={from}
                                    onChange={(date) => setFrom(date)}
                                />
                            </div>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>To</div>
                            </div>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={to}
                                    onChange={(date) => setTo(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <SupplierSelect suppliers={project.suppliers} supplierid={supplierid} setSupplierid={setSupplierid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Payment Terms</div>
                            </div>
                        </div>
                        <table className='modal_table'>
                            <thead><tr><th>Date</th><th>Name</th><th>Payment</th><th></th></tr></thead>
                            <tbody>
                                {
                                    rows.map((row, index) => (
                                        <NewRow
                                            key={'row' + index}
                                            row={row}
                                            index={index}
                                            handleRowChange={handleRowChange}
                                            handleRemoveRow={handleRemoveRow}
                                        />
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><div>Total</div></td>
                                    <td><div></div></td>
                                    <td><div>{amount} {currency}</div></td>
                                    <td><div></div></td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className='modal_result'>{result && result}</div>
                        <button className='modal_button' onClick={handleAddRow}>Add row</button>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateContract(id, fromts, tots, supplierid, name, currencyid, rows, amount)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(contract)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewContract({ contract }) {
        const printRef = useRef();

        const [from, setFrom] = useState(new Date());
        const [fromts, setFromts] = useState(0);
        const [to, setTo] = useState(new Date());
        const [tots, setTots] = useState(0);

        const [supplierid, setSupplierid] = useState('');
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [rows, setRows] = useState([]);

        useEffect(() => {
            setFrom(new Date(contract.from * 1000));
            setTo(new Date(contract.to * 1000));
            setSupplierid(contract.supplierid);
            setName(contract.name);
            setCurrencyid(contract.currencyid);
            setRows(contract.rows);
        }, [contract]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        let amount = rows.reduce((sum, item) => sum + (Number(item.payment) || 0), 0);
        const currency = project.currencies.find(item => item.id === currencyid, 10)?.symbol || '';
        let supplierName = project.suppliers.find(supplier => supplier.id === supplierid)?.name || 'Supplier not found';

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Contract</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>From</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {moment.unix(contract.from).format('D/MMM/YYYY')}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>To</div>
                                </div>
                            </div>
                            <div className='modal_rowsection'>
                                {moment.unix(contract.to).format('D/MMM/YYYY')}
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Supplier</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {supplierName}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Name</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {name}
                                </div>
                            </div>
                            <table className='modal_table'>
                                <thead><tr><th>Date</th><th>Name</th><th>Payment</th></tr></thead>
                                <tbody>
                                    {
                                        rows.map((row, index) => (
                                            <tr key={'row' + index}>
                                                <td>{moment.unix(row.date).format('D/MMM/YYYY')}</td>
                                                <td>{row.name}</td>
                                                <td>{row.payment}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><div>Total</div></td>
                                        <td><div></div></td>
                                        <td><div>{amount} {currency}</div></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveContract({ contract }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(contract.id);
        }, [contract]);

        const removeContract = (id) => {
            console.log('Trying to remove contract');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Contracts', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECONTRACT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove contract data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_CONTRACT',
                            contractid: id
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_overlay'>
                    <div className='modal_content'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeContract(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='contracts'>
            <Title text='Contracts' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Contract</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Supplier</th>
                        <th>Name</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredcontracts.map((contract, index) => {
                            let from = moment.unix(contract.from).format('D/MMM/YYYY');
                            let to = moment.unix(contract.to).format('D/MMM/YYYY');
                            let supplierName = project.suppliers.find(supplier => supplier.id === contract.supplierid)?.name || 'Supplier not found';
                            let amount = contract.amount;
                            let currency = project.currencies.find((currency) => currency.id === contract.currencyid)?.symbol || '';

                            return (
                                <tr key={'contract' + index}>
                                    <td><div className='table_button' onClick={() => editButton(contract)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(contract)}>View</div></td>
                                    <td><div>{supplierName}</div></td>
                                    <td><div>{contract.name}</div></td>
                                    <td><div>{from}</div></td>
                                    <td><div>{to}</div></td>
                                    <td><div>{currency} {amount}</div></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Contracts;