import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import ReactToPrint from 'react-to-print';

import { getPermission } from './Utils/permissions.js';
import Title from './Title.js';
import ClientSelect from './Components/clientselect.js';

import './EMSMeters.css';

function EMSMeters({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [emsmeters, setEmsmeters] = useState([]);
    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setEmsmeters(project.meters)
    }, [project.meters]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddMeter />);
    }

    const editButton = (meter) => {
        setOption(<EditMeter meter={meter} />);
    }

    const viewButton = (meter) => {
        setOption(<ViewMeter meter={meter} />);
    }

    const removeButton = (meter) => {
        setOption(<RemoveMeter meter={meter} />);
    }

    function AddMeter() {
        const [name, setName] = useState('');
        const [clientid, setClientid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addMeter = (name, clientid) => {
            console.log('Trying to add meter');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'EMS Meters', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: name,
                clientid: clientid,
            }

            axios.post(configData.CONTROLLERURL + configData.ADDMETER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add meter data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_METER',
                            meter: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Meter</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Client</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <ClientSelect clients={project.clients} clientid={clientid} setClientid={setClientid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addMeter(name, clientid)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditMeter({ meter }) {
        const [id, setId] = useState('');

        const [name, setName] = useState('');
        const [clientid, setClientid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(meter.id)
            setName(meter.name)
            setClientid(meter.clientid);
        }, [meter]);

        const updateMeter = (id, name, clientid) => {
            console.log('Trying to update meters');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'EMS Meters', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: name,
                clientid: clientid
            }
            
            axios.post(configData.CONTROLLERURL + configData.UPDATEMETER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update meter data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_METER',
                            meter: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Meter</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Client</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <ClientSelect clients={project.clients} clientid={clientid} setClientid={setClientid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateMeter(id, name, clientid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(meter)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewMeter({ meter }) {
        const [name, setName] = useState('');
        const [clientid, setClientid] = useState('');

        const printRef = useRef();

        useEffect(() => {
            setName(meter.name);
            setClientid(meter.clientid);
        }, [meter]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_row'>
                                <div className='modal_rowsection'></div>
                                <div className='modal_rowsection'>
                                    <div className='modal_title'>EMS Reading</div>
                                </div>
                                <div className='modal_rowsection'></div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Name</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Client</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {project.clients.find((client) => client.id === clientid)?.name}
                                </div>
                            </div>
                        </div>
                        <div className="modal_buttonscontainer">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                            />
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveMeter({ meter }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(meter.id);
        }, [meter]);

        const removeMeter = (id) => {
            console.log('Trying to remove meter');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'EMS Meters', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEMETER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove meter data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_METER',
                            meterid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Remove Reading ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeMeter(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='emstanks'>
            <Title text='Energy Management System Meters' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Meter</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Client</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        emsmeters.map((meter, index) => {
                            return (
                                <tr key={'meter' + index}>
                                    <td><div className='table_button' onClick={() => editButton(meter)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(meter)}>View</div></td>
                                    <td><div>{meter.id}</div></td>
                                    <td><div>{meter.name}</div></td>
                                    <td>{project.clients.find((client) => client.id == meter.clientid)?.name}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default EMSMeters;