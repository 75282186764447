import React, { useState, useEffect, useRef } from 'react';

import './select.css';

const SubaccountSelect = ({ subaccounts, subaccountid, setSubaccountid, width, position }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const handleSelect = (subaccountId) => {
        setSubaccountid(subaccountId);
        setShowDropdown(false);
    };
    
    const filteredSubaccounts = [...subaccounts]
    .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
    .filter(
        (subaccount) =>
            subaccount.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            subaccount.id !== subaccountid
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        if (showDropdown && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showDropdown]);

    const clearSelection = () => {
        handleSelect(null);
        setShowDropdown(false);
        setSubaccountid('');
    };

    const dropdown = () => {
        setShowDropdown(true)
        setSearchTerm('')
    }

    return (
        <div className="inputgroup" ref={dropdownRef} style={{ width: width }}>
            <input
                type="text"
                className='inputgroup_input'
                placeholder="Select a subaccount"
                value={subaccountid ? subaccounts.find(subaccount => subaccount.id === subaccountid)?.name : ''}
                onClick={dropdown}
                readOnly
            />
            {subaccountid && (
                <button className="inputgroup_clearbutton" onClick={clearSelection}>
                    X
                </button>
            )}
            {showDropdown && (
                <div className="inputgroup_dropdown" style={{ position: position }}>
                    <input
                        type="text"
                        className="inputgroup_dropdowninput"
                        placeholder="Search subaccounts"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        ref={inputRef}
                    />
                    <ul className='inputgroup_dropdownul'>
                        {filteredSubaccounts.map((subaccount, index) => (
                            <li
                                key={'subaccount' + index}
                                className='inputgroup_dropdownli'
                                onClick={() => handleSelect(subaccount.id)}
                            >
                                {subaccount.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SubaccountSelect;