import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import ReactToPrint from 'react-to-print';

import Title from './Title';
import StatementAccount from './StatementAccount';
import { getCashAccounts } from './Utils/cash.js';
import { interaccounttransfersremovalprotection, transactionsremovalprotection, reconciliationsremovalprotection } from './Utils/removeprotection.js';
import { getPermission } from './Utils/permissions.js';

import CurrencySelect from './Components/currencyselect.js';

import './CashAccounts.css';

//20240524

function CashAccounts({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();
    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddAccount />);
    }

    const editButton = (account) => {
        setOption(<EditAccount account={account} />);
    }

    const viewButton = (account) => {
        setOption(<ViewAccount account={account} />);
    }

    const removeButton = (account) => {
        setOption(<RemoveAccount account={account} />);
    }

    const handleAccountStatement = (type, account, setOption) => {
        setOption(<StatementAccount type={type} account={account} setOption={setOption} />);
    }

    function AddAccount() {
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [openingbalance, setOpeningbalance] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addAccount = (name, currencyid, openingbalance) => {
            console.log('Trying to add account');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Cash Accounts', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !currencyid || isNaN(parseFloat(openingbalance))) {
                setResult('Missing info')
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.cashaccounts.some(account => account.name === trimmedName);
            if (isNameDuplicate) {
                setResult('Account with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: trimmedName,
                currencyid: currencyid,
                openingbalance: openingbalance
            }

            axios.post(configData.CONTROLLERURL + configData.ADDCASHACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_CASHACCOUNT',
                            cashaccount: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Account</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Opening balance</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="0"
                                    value={openingbalance}
                                    onChange={(e) => setOpeningbalance(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addAccount(name, currencyid, openingbalance)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditAccount({ account }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [openingbalance, setOpeningbalance] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(account.id)
            setName(account.name)
            setCurrencyid(account.currencyid)
            setOpeningbalance(account.openingbalance)
        }, [account]);

        const updateAccount = (id, name, currencyid, openingbalance) => {
            console.log('Trying to update account');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Cash Accounts', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!name || !currencyid || isNaN(parseFloat(openingbalance))) {
                setResult('Missing info')
                return
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.cashaccounts.some(account => account.name === name && account.id !== id);
            if (isNameDuplicate) {
                setResult('Account with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: trimmedName,
                currencyid: currencyid,
                openingbalance: openingbalance
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATECASHACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_CASHACCOUNT',
                            cashaccount: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Account</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Amount"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Opening balance</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="number"
                                    placeholder="0"
                                    value={openingbalance}
                                    onChange={(e) => setOpeningbalance(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateAccount(id, name, currencyid, openingbalance)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                            <button className="modal_button" onClick={() => removeButton(account)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewAccount({ account }) {
        const printRef = useRef();

        const [id, setId] = useState('');
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [openingbalance, setOpeningbalance] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(account.id)
            setName(account.name)
            setCurrencyid(account.currencyid)
            setOpeningbalance(account.openingbalance)
        }, [account]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Account</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Name</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                {name}
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Currency</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {project.currencies.find((currency) => currency.id === currencyid)?.name}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Opening balance</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {openingbalance}
                                </div>
                            </div>
                        </div>
                        <div className="modal_buttonscontainer">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                            />
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveAccount({ account }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(account.id)
            setName(account.name)
        }, [account]);

        const removeAccount = (id) => {
            console.log('Trying to remove account');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Cash Accounts', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const interaccountData = interaccounttransfersremovalprotection(project.interaccounttransfers, id, 'cashaccounts');
            if (interaccountData.code) {
                setResult(interaccountData.message);
                return
            }

            const transactionData = transactionsremovalprotection(project.transactions, id, 'cashaccounts');
            if (transactionData.code) {
                setResult(transactionData.message);
                return
            }

            const reconciliationData = reconciliationsremovalprotection(project.reconciliations, id, 'cashaccounts');
            if (reconciliationData.code) {
                setResult(reconciliationData.message);
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVECASHACCOUNT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove account data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_CASHACCOUNT',
                            cashaccountid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeAccount(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const cashdata = getCashAccounts(project.cashaccounts, project.transactions, project.interaccounttransfers, project.currencies, project.exchangerates);

    return (
        <div className='cashaccounts'>
            <Title text='Cash Accounts' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Account</button>
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Name</th><th>Balance</th></tr></thead>
                <tbody>
                    {
                        cashdata.map((account, index) => {
                            return (
                                <tr className='table' key={'account' + index}>
                                    <td><div className='table_button' onClick={() => editButton(account)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(account)}>View</div></td>
                                    <td><div>{account.name}</div></td>
                                    <td><div className='cash_balance' onClick={() => handleAccountStatement('cashaccounts', { id: account.id, currencyid: account.currencyid, name: account.name, openingbalance: account.openingbalance }, setOption)}>{account.currency} {account.balance.toLocaleString('en-US')}</div></td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default CashAccounts;