import React, { useState } from 'react';

import Header from './Header.js';

import './HomePage.css';

function Homepage({ state, setState }) {
    const [paymentoption, setPaymentoption] = useState(1);

    const [option, setOption] = useState(null);

    const handleBasicPlan = () => {
        setOption(<BasicPlan />);
    }

    const handleDemo = () => {
        setOption(<Demo />);
    }

    const startDemo = () => {
        setState(state => ({
            ...state,
            user: {
                userid: '669b5d6facee2',
                usertoken: '1fdd91bb65550a8b03306c8f1e43b59e',
                useremail: 'demouser@demo.com',
                username: 'demo_user',
                usertype: '0',
            }
        }))
    }

    function BasicPlan() {
        return (
            <div className='homepage_modal'>
                <div className='homepage_modaloverlay'>
                    <div className='homepage_modalcontent'>
                        <div className='homepage_modalclosebuttoncontainer'>
                            <div className='homepage_modalclosebutton' onClick={() => setOption(null)}>X</div>
                        </div>
                        <div className='homepage_modaltitle'>Basic Plan</div>
                        <p className='homepage_modaldescription'>We are only accepting Cash Payments at the moment.</p>
                    </div>
                </div>
            </div>
        )
    }

    function Demo() {
        return (
            <div className='homepage_modal'>
                <div className='homepage_modaloverlay'>
                    <div className='homepage_modalcontent'>
                        <div className='homepage_modalclosebuttoncontainer'>
                            <div className='homepage_modalclosebutton' onClick={() => setOption(null)}>X</div>
                        </div>
                        <div className='homepage_modaltitle'>Demo Account</div>
                        <p className='homepage_modaldescription'>Building XYZ 1344</p>
                        <button className='homepage_modalbutton' onClick={startDemo}>Start Demo</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='homepage'>
            <Header state={state} setState={setState} />
            <h1 className='homepage_title'>Property and Facilities Management Software</h1>
            <p className='homepage_description'>
                Our software offers comprehensive solutions for managing properties and facilities in the cloud.
                With our platform, you can streamline property management tasks, optimize facility operations,
                and enhance resident experiences.
            </p>
            <div className='homepage_demobuttoncontainer'>
                <button className='homepage_demobutton' onClick={handleDemo}>Demo Building XYZ</button>
            </div>
            <div className='homepage_paymentplans'>
                <div className="homepage_paymentplan">
                    <h3>Basic Plan</h3>
                    <select className='homepage_paymentplanselect' value={paymentoption} onChange={(e) => setPaymentoption(e.target.value)}>
                        <option value={1}>Monthly</option>
                        <option value={12}>Yearly</option>
                    </select>
                    {paymentoption == 1 && <p className="homepage_paymentplanprice">$10/Month</p>}
                    {paymentoption == 12 && <p className="homepage_paymentplanprice">$110/Year</p>}
                    <ul className='homepage_paymentplanfeatures'>
                        <li>Multi-user Access</li>
                        <li>User Permissions</li>
                        <li>Property Management Accounting</li>
                        <li>Facilities Management</li>
                    </ul>
                    <button className='homepage_planbutton' onClick={handleBasicPlan}>Basic Plan</button>
                </div>
            </div>

            {option}

        </div>
    );
}

export default Homepage;