import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import html3pdf from 'html3pdf';

import { getPermission } from './Utils/permissions.js';
import { isRTL } from './Utils/textparse';
import AssetSelect from './Components/assetselect.js';
import SupplierSelect from './Components/supplierselect.js';
import Title from './Title';

import './Inspections.css';

function Inspections({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredinspections, setFilteredinspections] = useState([]);
    const [search, setSearch] = useState('');

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let filtered = project.inspections.filter((inspection) => inspection.description.toLowerCase().includes(search.toLowerCase())).sort((a, b) => b.ts - a.ts);
        setFilteredinspections(filtered);
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddInspection />);
    }

    const editButton = (inspection) => {
        setOption(<EditInspection inspection={inspection} />);
    }

    const removeButton = (inspection) => {
        setOption(<RemoveInspection inspection={inspection} />);
    }

    const viewButton = (inspection) => {
        setOption(<ViewInspection inspection={inspection} />);
    }

    const viewAllButton = (inspections) => {
        setOption(<ViewInspections inspections={inspections} />);
    }

    function AddInspection() {
        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <button className="modal_button" onClick={() => setOption(<AddSuppliersInspection />)}>Suppliers Inspection</button>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <button className="modal_button" onClick={() => setOption(<AddLocalInspection />)}>Local Inspection</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function AddSuppliersInspection() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');

        const [assets, setAssets] = useState([]);
        const [assetid, setAssetid] = useState('');

        const [refno, setRefno] = useState('');
        const [description, setDescription] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setSuppliers(project.suppliers);
            setAssets(project.assets);
        }, []);

        const addInspection = (timestamp, supplierid, assetid, refno, description) => {
            console.log('Trying to add inspection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Inspections', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!supplierid || !refno || !description) {
                setResult('Missing info')
                return
            }

            setLoading(true);

            const trimmedrefno = refno.trim();
            const trimmeddescription = description.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                supplierid: supplierid,
                assetid: assetid,
                refno: trimmedrefno,
                description: trimmeddescription
            }

            axios.post(configData.CONTROLLERURL + configData.ADDINSPECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add inspection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_INSPECTION',
                            inspection: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Suppliers Inspection</div>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <SupplierSelect suppliers={suppliers} supplierid={supplierid} setSupplierid={setSupplierid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Asset</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <AssetSelect assets={assets} assetid={assetid} setAssetid={setAssetid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Ref No</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Ref No"
                                    value={refno}
                                    onChange={(e) => setRefno(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <textarea
                                    className={isRTL(description) ? 'modal_textarea modal_textrtl' : 'modal_textarea modal_textltr'}
                                    type="text"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal_result'>{result && result}</div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={() => addInspection(timestamp, supplierid, assetid, refno, description)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function AddLocalInspection() {
        const [selectedtab, setselectedtab] = useState(0);
        const [modalVisible, setModalVisible] = useState(false);
        const [currentSection, setCurrentSection] = useState(null);
        const [newItem, setNewItem] = useState({ picture: null, location: '', comment: '' });
        const [items, setItems] = useState([
            { id: 1, name: 'Assets check', description: 'Check assets if any missing', data: [] },
            { id: 2, name: 'Clients feedback', description: 'Check for complaints from clients (if any)', data: [] },
            { id: 3, name: 'Cleaning', description: 'Items found on stairs, garbage, landscape...', data: [] },
            { id: 4, name: 'Compliance', description: 'Regulations and compliance to rules.', data: [] },
            { id: 5, name: 'Documentation', description: 'Get papers from doormen, make sure no left papers...', data: [] },
            { id: 6, name: 'Emergency ready', description: 'Missing inspections from suppliers, contact numbers in case of emergencies...', data: [] },
            { id: 7, name: 'Environmental', description: 'Solar energy, CO2 emissions, energy wasted, leakages...', data: [] },
            { id: 8, name: 'Maintenance', description: 'MEP, Civil, technical issues', data: [] },
            { id: 9, name: 'Safety & Security', description: 'Check CCTV if working, check access control', data: [] },
            { id: 10, name: 'Staff', description: 'Doormen, cleaners, security officers...', data: [] }
        ]);
        const [progress, setProgress] = useState(0);

        const handleNewItemChange = (field, value) => {
            setNewItem(prevItem => ({ ...prevItem, [field]: value }));
        };

        const pickImage = () => {
            document.getElementById('fileInput').click();
        };

        const handleFileChange = (event) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    handleNewItemChange('picture', reader.result);
                };
                reader.readAsDataURL(file);
            }
        };

        const addNewItem = () => {
            if (!newItem.location || !newItem.comment) {
                alert('Please fill out all fields.');
                return;
            }

            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === currentSection
                        ? {
                            ...item,
                            data: [
                                ...item.data,
                                { ...newItem, id: Date.now() }
                            ]
                        }
                        : item
                )
            );

            setNewItem({
                picture: null,
                location: '',
                comment: ''
            });
            setModalVisible(false);
        };

        const removeItem = (sectionId, itemId) => {
            setItems(prevItems =>
                prevItems.map(item =>
                    item.id === sectionId
                        ? {
                            ...item,
                            data: item.data.filter(dataItem => dataItem.id !== itemId)
                        }
                        : item
                )
            );
        };

        const handleSubmit = async () => {
            const totalItems = items.length;
            let completedItems = 0;

            for (const item of items) {
                const formData = new FormData();

                item.data.forEach((dataItem, index) => {
                    formData.append(`item${item.id}[${index}]`, JSON.stringify(dataItem));
                });

                await axios.post(
                    configData.CONTROLLERURL + configData.ADDINSPECTION,
                    formData,
                    {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "multipart/form-data",
                            "userid": state.user.userid,
                            "usertoken": state.user.usertoken
                        }
                    }
                )
                    .then(res => {
                        console.log('Add inspection data received');
                        console.log(res.data);
                    })
                    .catch(err => {
                        console.error('Error while adding inspection data:', err);
                    });

                completedItems += 1;
                setProgress((completedItems / totalItems) * 100);
            }

            console.log('All inspections have been processed.');
        };

        const openModal = (sectionId) => {
            setCurrentSection(sectionId);
            setModalVisible(true);
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Local Inspection</div>
                        <div className="inspection">
                            {items.map((item, index) => (
                                <div key={item.id} className="accordion_item">
                                    <button
                                        className={index === selectedtab ? 'inspectionheader inspectionheader_active' : 'inspectionheader'}
                                        onClick={() => setselectedtab(index === selectedtab ? null : index)} // Toggle the selected tab
                                    >
                                        {item.name} {item.data.length > 0 && `(${item.data.length})`}
                                    </button>
                                    {index === selectedtab && (
                                        <div className="accordion_content">
                                            {items[selectedtab].description && (
                                                <div className="tableHeader">
                                                    <span className="tableHeaderText">{items[selectedtab].description}</span>
                                                </div>
                                            )}
                                            <table className="modal_table">
                                                <thead className="tableHeader">
                                                    <tr>
                                                        <th className="tableHeaderText">Picture</th>
                                                        <th className="tableHeaderText">Location</th>
                                                        <th className="tableHeaderText">Comment</th>
                                                        <th className="tableHeaderText">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items[selectedtab].data.map((dataItem, index) => (
                                                        <tr key={`item${index}`}>
                                                            <td></td>
                                                            <td>{dataItem.location}</td>
                                                            <td>{dataItem.comment}</td>
                                                            <td>
                                                                <button
                                                                    className="removeButton"
                                                                    onClick={() => removeItem(items[selectedtab].id, dataItem.id)}
                                                                >
                                                                    X
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="modal_buttons">
                                                <button className="modal_button" onClick={() => openModal(items[selectedtab].id)}>
                                                    Add Remark
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={handleSubmit}>Submit ({progress}%)</button>
                        <button className="modal_button" onClick={() => setOption(null)}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }

    function EditInspection({ inspection }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [type, setType] = useState('');

        const [suppliers, setSuppliers] = useState([]);
        const [supplierid, setSupplierid] = useState('');

        const [assets, setAssets] = useState([]);
        const [assetid, setAssetid] = useState('');

        const [refno, setRefno] = useState('');
        const [description, setDescription] = useState(inspection.description);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(inspection.id)
            setSelecteddate(new Date(inspection.ts * 1000));
            setSuppliers(project.suppliers);
            setSupplierid(inspection.supplierid);
            setAssets(project.assets);
            setAssetid(inspection.assetid)
            setRefno(inspection.refno);
            setDescription(inspection.description);
        }, []);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const updateInspection = (id, timestamp, supplierid, assetid, refno, description) => {
            console.log('Trying to update inspection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Inspections', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!supplierid || !refno || !description) {
                setResult('Missing info')
                return
            }

            setLoading(true);

            const trimmedrefno = refno.trim();
            const trimmeddescription = description.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: timestamp,
                supplierid: supplierid,
                assetid: assetid,
                refno: trimmedrefno,
                description: trimmeddescription
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEINSPECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update inspection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_INSPECTION',
                            inspection: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Inspection</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Supplier</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <SupplierSelect suppliers={suppliers} supplierid={supplierid} setSupplierid={setSupplierid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Asset</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <AssetSelect assets={assets} assetid={assetid} setAssetid={setAssetid} width={200} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Ref No</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Ref No"
                                    value={refno}
                                    onChange={(e) => setRefno(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Description</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <textarea
                                    className={isRTL(description) ? 'modal_textarea modal_textrtl' : 'modal_textarea modal_textltr'}
                                    type="text"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal_result'>{result && result}</div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={() => updateInspection(id, timestamp, supplierid, assetid, refno, description)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        <button className="modal_button" onClick={() => removeButton(inspection)}>Remove</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveInspection({ inspection }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(inspection.id)
        }, [inspection]);

        const removeInspection = (id) => {
            console.log('Trying to remove inspection');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Inspections', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEINSPECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove inspection data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_INSPECTION',
                            inspectionid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Are you sure ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeInspection(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewInspection({ inspection }) {
        const [ts, setTs] = useState('');
        const [refno, setRefno] = useState('');
        const [description, setDescription] = useState(inspection.description);

        const printRef = useRef();

        const printButton = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        useEffect(() => {
            setTs(inspection.ts)
            setRefno(inspection.refno);
            setDescription(inspection.description);
        }, [inspection]);

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Inspection</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Date</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {moment.unix(inspection.ts).format('D/MMM/YYYY')}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Ref No.</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    {refno}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Description</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='inspections_description'>{description}</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal_buttonscontainer">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={printButton}>Print</button>
                                )}
                                content={() => printRef.current}
                            />
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewInspections({ inspections }) {
        const [supplierid, setSupplierid] = useState('');
        const [assetid, setAssetid] = useState('');
        const [filteredInspections, setFilteredInspections] = useState([]);

        const printRef = useRef();

        const printButton = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        useEffect(() => {
            const updatedFilteredInspections = supplierid === 0 && assetid === 0
                ? inspections
                : inspections.filter((inspection) => {
                    return (supplierid === '' || inspection.supplierid === supplierid) &&
                        (assetid === '' || inspection.assetid === assetid);
                });

            updatedFilteredInspections.sort((a, b) => b.ts - a.ts);

            setFilteredInspections(updatedFilteredInspections);
        }, [supplierid, assetid]);

        const saveInspectionsPDF = async () => {
            const content = printRef.current;

            const currentDate = moment().format('DD_MM_YYYY');

            const options = {
                margin: 10,
                filename: 'inspections_' + project.name + '_' + currentDate + '.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html3pdf().from(content).set(options).save();
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Inspections</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Supplier</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <SupplierSelect suppliers={project.suppliers} supplierid={supplierid} setSupplierid={setSupplierid} position='fixed' width={200} />
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <div className='modal_label'>Asset</div>
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <AssetSelect assets={project.assets} assetid={assetid} setAssetid={setAssetid} position='fixed' width={200} />
                                </div>
                            </div>
                            <table className='modal_table'>
                                <thead><tr><th>Date</th><th>Ref</th><th>Supplier</th><th>Asset</th><th>Description</th></tr></thead>
                                <tbody>
                                    {
                                        filteredInspections.map((inspection, index) => {
                                            const date = moment.unix(inspection.ts).format('D/MMM/YYYY');
                                            const refno = inspection.refno;
                                            const supplierName = project.suppliers.find(supplier => supplier.id == inspection.supplierid)?.name || 'Supplier not found';
                                            const assetName = project.assets.find(asset => asset.id == inspection.assetid)?.name;
                                            const description = inspection.description;

                                            return (
                                                <tr key={'inspection' + index}>
                                                    <td><div>{date}</div></td>
                                                    <td><div>{refno}</div></td>
                                                    <td><div>{supplierName}</div></td>
                                                    <td><div>{assetName}</div></td>
                                                    <td><div className='inspections_description'>{description}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={printButton}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={saveInspectionsPDF}>Save as PDF</button>
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div >
        )
    }

    return (
        <div className='inspections'>
            <Title text='Inspections' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Inspection</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={() => viewAllButton(project.inspections)}>View Inspections</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>Ref</th><th>Supplier</th><th>Asset</th><th>Description</th></tr></thead>
                <tbody>
                    {
                        filteredinspections.map((inspection, index) => {
                            let supplierName = project.suppliers.find(supplier => supplier.id == inspection.supplierid)?.name;
                            let assetName = project.assets.find(asset => asset.id == inspection.assetid)?.name;

                            return (
                                <tr key={'inspection' + index}>
                                    <td><div className='table_button' onClick={() => editButton(inspection)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(inspection)}>View</div></td>
                                    <td><div>{moment.unix(inspection.ts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{inspection.refno}</div></td>
                                    <td><div>{supplierName}</div></td>
                                    <td><div>{assetName}</div></td>
                                    <td><div className='inspections_description'>{inspection.description}</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Inspections;