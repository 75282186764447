import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactToPrint from 'react-to-print';
import Title from './Title.js';

import Header from './Header.js';

import './AuditTrail.css';

//20240525

function AuditTrail({ state, setState }) {
    const [{ projects }, dispatch] = useStateValue();
    const printRef = useRef();

    const [selecteddate, setSelecteddate] = useState(new Date());
    const [timestamp, setTimstamp] = useState(0);
    const [entries, setEntries] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
        setTimstamp(unixTimestamp);
    }, [selecteddate]);

    useEffect(() => {
        const uniqueIds = projects.map(project => project.userid)
            .filter((userId, index, array) => array.indexOf(userId) === index);

        console.log('Trying to get audit entries');
        setLoading(true);

        if (uniqueIds.length > 0) {
            setEntries([])
            uniqueIds.forEach(userId => {
                const data = {
                    projectuserid: userId,
                    ts: timestamp
                };

                axios.post(configData.CONTROLLERURL + configData.GETAUDITTRAIL, data, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "userid": state.user.userid,
                        "usertoken": state.user.usertoken
                    }
                }).then((res) => {
                    console.log('Audit entries data received')
                    console.log(res.data)
                    if (res.data instanceof Array) {
                        setEntries(prevEntries => [...prevEntries, ...res.data]);
                    }
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setLoading(false);
                })
            });
        }
    }, [timestamp]);

    const handlePrint = async () => {
        const pdf = await ReactToPrint.render(printRef.current);
        window.open(pdf.toDataURL(), '_blank');
    };

    return (
        <div className='audittrail'>
            <Header state={state} setState={setState} />
            <div className='modal_row'>
                <div className='modal_rowsection'></div>
                <div className='modal_rowsection'>
                    <DatePicker
                        className='modal_datepicker'
                        selected={selecteddate}
                        onChange={(date) => setSelecteddate(date)}
                    />
                </div>
                <div className='modal_rowsection'>
                    <ReactToPrint
                        trigger={() => (
                            <button className="audittrail_button" onClick={handlePrint}>Print</button>
                        )}
                        content={() => printRef.current}
                    />
                </div>
            </div>
            <div className='audittrail_printable' ref={printRef}>
                <Title text='AUDIT TRAIL' />

                <div className='audittrail_datecontainer'>
                    <div className='audittrail_date'>
                        {moment().format('dddd, Do MMMM YYYY')}
                    </div>
                </div>

                <table className='audittrail_table'>
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Username</th>
                            <th>Project Name</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && <tr><td colSpan={4}><div>loading...</div></td></tr>}
                        {
                            entries.map((entry, index) => {
                                return (
                                    <tr key={'entry' + index}>
                                        <td><div>{moment.unix(entry.ts).format('D/MMM/YYYY hh:mm:ss A')}</div></td>
                                        <td><div>{entry.username}</div></td>
                                        <td><div>{projects.find(project => project.projectid === entry.projectid)?.name}</div></td>
                                        <td><div>{entry.description}</div></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        </div>
    );
}

export default AuditTrail;