import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Title from './Title.js';

import Header from './Header.js';

import './Backups.css';

//20240525

function Backups({ state, setState }) {
    const [{ user, projects }, dispatch] = useStateValue();

    const [backups, setBackups] = useState([]);
    const [option, setOption] = useState(null);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const uniqueIds = projects.map(project => project.userid)
            .filter((userId, index, array) => array.indexOf(userId) === index);

        console.log('Trying to get backups');
        setLoading(true);

        if (uniqueIds.length > 0) {
            setBackups([])
            uniqueIds.forEach(userId => {
                const data = {
                    projectuserid: userId
                };

                axios.post(configData.CONTROLLERURL + configData.GETBACKUPS, data, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "userid": state.user.userid,
                        "usertoken": state.user.usertoken
                    }
                }).then((res) => {
                    console.log('Backups data received')
                    console.log(res.data)
                    if (res.data instanceof Array) {
                        setBackups(prevEntries => [...prevEntries, ...res.data]);
                    }
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setLoading(false);
                })
            });
        }
    }, [refresh]);

    const addBackup = () => {
        const uniqueIds = projects.map(project => project.userid)
            .filter((userId, index, array) => array.indexOf(userId) === index);

        if (uniqueIds.length > 0) {
            uniqueIds.forEach(userId => {
                const data = {
                    projectuserid: userId
                };

                axios.post(configData.CONTROLLERURL + configData.ADDBACKUP, data, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "userid": state.user.userid,
                        "usertoken": state.user.usertoken
                    }
                }).then((res) => {
                    console.log(res.data)
                    if (res.data.code == 1) {
                        setRefresh(!refresh)
                    }
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setLoading(false);
                })
            })
        }
    }


    return (
        <div className='backups'>
            <Header state={state} setState={setState} />

            <Title text='Backups' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addBackup}>Add Backup</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Date/Time</th>
                        <th>ProjectUserId</th>
                        <th>Size</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        backups.map((backup, index) => {
                            return (
                                <tr key={'backup' + index}>
                                    <td><div>{backup.id}</div></td>
                                    <td><div>{moment.unix(backup.ts).format('D/MMM/YYYY hh:mm:ss A')}</div></td>
                                    <td><div>{backup.projectuserid}</div></td>
                                    <td><div>{(backup.size / 1024).toFixed(2)}KB</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default Backups;