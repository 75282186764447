import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { getPermission } from './Utils/permissions.js';
import AccountSelect from './Components/accountselect.js';
import ClientSelect from './Components/clientselect.js';
import SupplierSelect from './Components/supplierselect.js';
import SubaccountSelect from './Components/subaccountselect.js';
import CurrencySelect from './Components/currencyselect.js';
import { isValidNumber, hasMoreThanTwoDecimals } from './Utils/textparse'
import Title from './Title';

import './JournalEntries.css';

//20240525

function JournalEntries({ state, setState }) {
    const [{ user, project }, dispatch] = useStateValue();

    const [search, setSearch] = useState('');
    const [filteredjournals, setFilteredjournals] = useState([]);

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let filtered = project.journalentries.filter((journal) =>
            journal.name.toLowerCase().includes(search.toLowerCase()) ||
            journal.id.includes(search) ||
            journal.amount.includes(search)
        ).sort((a, b) => b.ts - a.ts);

        setFilteredjournals(filtered);
    }, [project, search]);

    const cancelButton = () => {
        setOption(null);
    }

    const addButton = () => {
        setOption(<AddJournalEntry />);
    }

    const editButton = (journal) => {
        setOption(<EditJournalEntry journal={journal} mode='edit' />);
    }

    const cloneButton = (journal) => {
        setOption(<EditJournalEntry journal={journal} mode='clone' />);
    }

    const viewButton = (journal) => {
        setOption(<ViewJournalEntry journal={journal} />);
    }

    const removeButton = (journal) => {
        setOption(<RemoveJournalEntry journal={journal} />);
    }

    function NewRow({ row, index, handleTypeChange, handleAccountChange, handleNameChange, handleDebitChange, handleCreditChange, handleRemoveRow }) {
        const renderAccountOptions = () => {
            switch (row.accounttype) {
                case 'incomeaccounts':
                    return <AccountSelect accounts={project.incomeaccounts} accountid={row.accountid} setAccountid={(accountId) => handleAccountChange({ target: { value: accountId } }, index)} width={250} position='relative' />;
                case 'expenseaccounts':
                    return <AccountSelect accounts={project.expenseaccounts} accountid={row.accountid} setAccountid={(accountId) => handleAccountChange({ target: { value: accountId } }, index)} width={250} position='relative' />;
                case 'clients':
                    return <ClientSelect clients={project.clients} clientid={row.accountid} setClientid={(clientId) => handleAccountChange({ target: { value: clientId } }, index)} width={250} position='relative' />;
                case 'suppliers':
                    return <SupplierSelect suppliers={project.suppliers} supplierid={row.accountid} setSupplierid={(supplierId) => handleAccountChange({ target: { value: supplierId } }, index)} width={250} position='relative' />;
                default:
                    return null;
            }
        };

        return (
            <tr>
                <td>
                    <select
                        className='modal_select'
                        value={row.accounttype}
                        onChange={(e) => handleTypeChange(e, index)}
                    >
                        <option value="incomeaccounts">Income Accounts</option>
                        <option value="expenseaccounts">Expense Accounts</option>
                        <option value="clients">Accounts Receivable</option>
                        <option value="suppliers">Accounts Payable</option>
                    </select>
                </td>
                <td>
                    {renderAccountOptions()}
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        value={row.name}
                        onChange={(e) => handleNameChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder={0}
                        value={row.debit}
                        onChange={(e) => handleDebitChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder={0}
                        value={row.credit}
                        onChange={(e) => handleCreditChange(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_cancelbuttoncontainer'>
                        <div className='modal_cancelbutton' onClick={() => handleRemoveRow(index)}>X</div>
                    </div>
                </td>
            </tr>
        );
    }

    function AddJournalEntry() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [rows, setRows] = useState([{ accounttype: 'incomeaccounts', accountid: '', name: '', debit: 0, credit: 0 }]);
        const [name, setName] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const handleTypeChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].accounttype = selectedRow;
            setRows(updatedRows);
        };

        const handleAccountChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].accountid = selectedRow;
            setRows(updatedRows);
        };

        const handleNameChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].name = selectedRow;
            setRows(updatedRows);
        };

        const handleDebitChange = (event, index) => {
            const selectedRow = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedRows = [...rows];
            updatedRows[index].debit = selectedRow;
            setRows(updatedRows);
        };

        const handleCreditChange = (event, index) => {
            const selectedRow = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedRows = [...rows];
            updatedRows[index].credit = selectedRow;
            setRows(updatedRows);
        };

        const handleAddRow = () => {
            const lastRowAccountType = rows.length > 0 ? rows[rows.length - 1].accounttype : 'incomeaccounts';
            setRows([...rows, { accounttype: lastRowAccountType, accountid: '', name: '', debit: 0, credit: 0 }])
        };

        const handleRemoveRow = (indexToRemove) => {
            if (rows.length > 1) {
                const updatedRows = rows.filter((_, index) => index !== indexToRemove);
                setRows(updatedRows);
            }
        };

        let sumDebit = rows.reduce((sum, item) => sum + (Number(item.debit) || 0), 0);
        let sumCredit = rows.reduce((sum, item) => sum + (Number(item.credit) || 0), 0);
        if (hasMoreThanTwoDecimals(sumDebit)) {
            sumDebit = sumDebit.toFixed(2);
        }

        if (hasMoreThanTwoDecimals(sumCredit)) {
            sumCredit = sumCredit.toFixed(2);
        }
        const totalDifference = (sumDebit - sumCredit).toFixed(2);
        const currencySymbol = project.currencies.find(item => item.id === currencyid)?.symbol;

        const addJournal = (timestamp, subaccount, currencyid, name, sumdebit, sumcredit, rows, setResult) => {

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Journal Entries', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!subaccount || !name || !currencyid) {
                setResult('Missing info')
                return
            }

            if (sumdebit !== sumcredit) {
                setResult('Entries not equal')
                return
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                subaccount: subaccount,
                currencyid: currencyid,
                amount: sumdebit,
                name: trimmedname,
                rows: rows
            }

            axios.post(configData.CONTROLLERURL + configData.ADDJOURNALENTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_JOURNALENTRY',
                            journalentry: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Journal Entry</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Subaccount</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <SubaccountSelect subaccounts={project.subaccounts} subaccountid={selectedsubaccount} setSubaccountid={setSelectedSubaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_scrollable'>
                            <table className='modal_table'>
                                <thead><tr><th>Type</th><th>Account</th><th>Name</th><th>Debit</th><th>Credit</th><th></th></tr></thead>
                                <tbody>
                                    {
                                        rows.map((row, index) => (
                                            <NewRow
                                                key={'row' + index}
                                                row={row}
                                                index={index}
                                                handleTypeChange={handleTypeChange}
                                                handleAccountChange={handleAccountChange}
                                                handleNameChange={handleNameChange}
                                                handleDebitChange={handleDebitChange}
                                                handleCreditChange={handleCreditChange}
                                                handleRemoveRow={handleRemoveRow}
                                            />
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><div>Total</div></td>
                                        <td><div></div></td>
                                        <td><div>{totalDifference != 0 && totalDifference}</div></td>
                                        <td><div>{currencySymbol} {sumDebit.toLocaleString('en-US')}</div></td>
                                        <td><div>{currencySymbol} {sumCredit.toLocaleString('en-US')}</div></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <button className='modal_button' onClick={handleAddRow}>Add row</button>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => addJournal(timestamp, selectedsubaccount, currencyid, name, sumDebit, sumCredit, rows, setResult)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditJournalEntry({ journal, mode }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [name, setName] = useState('');
        const [rows, setRows] = useState([{ accounttype: 'incomeaccounts', accountid: '', name: '', debit: 0, credit: 0 }]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            setId(journal.id);
            setSelecteddate(new Date(journal.ts * 1000));
            setTimstamp(journal.ts)
            setCurrencyid(journal.currencyid);
            setSelectedSubaccount(journal.subaccount);
            setName(journal.name);
            setRows(journal.rows);
        }, [journal]);

        const handleTypeChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].accounttype = selectedRow;
            setRows(updatedRows);
        };

        const handleAccountChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].accountid = selectedRow;
            setRows(updatedRows);
        };

        const handleNameChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].name = selectedRow;
            setRows(updatedRows);
        };

        const handleDebitChange = (event, index) => {
            const selectedRow = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedRows = [...rows];
            updatedRows[index].debit = selectedRow;
            setRows(updatedRows);
        };

        const handleCreditChange = (event, index) => {
            const selectedRow = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedRows = [...rows];
            updatedRows[index].credit = selectedRow;
            setRows(updatedRows);
        };

        const handleAddRow = () => {
            const lastRowAccountType = rows.length > 0 ? rows[rows.length - 1].accounttype : 'incomeaccounts';
            setRows([...rows, { accounttype: lastRowAccountType, accountid: '', name: '', debit: 0, credit: 0 }])
        };

        const handleRemoveRow = (indexToRemove) => {
            if (rows.length > 1) {
                const updatedRows = rows.filter((_, index) => index !== indexToRemove);
                setRows(updatedRows);
            }
        };

        let sumDebit = rows.reduce((sum, item) => sum + (Number(item.debit) || 0), 0);
        let sumCredit = rows.reduce((sum, item) => sum + (Number(item.credit) || 0), 0);
        if (hasMoreThanTwoDecimals(sumDebit)) {
            sumDebit = parseFloat(sumDebit.toFixed(2));
        }

        if (hasMoreThanTwoDecimals(sumCredit)) {
            sumCredit = parseFloat(sumCredit.toFixed(2));
        }
        const totalDifference = (sumDebit - sumCredit).toFixed(2);
        const currencySymbol = project.currencies.find(item => item.id === currencyid)?.symbol;

        const addJournal = (timestamp, subaccount, currencyid, name, sumdebit, sumcredit, rows, setResult) => {

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Journal Entries', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!subaccount || !name || !currencyid) {
                setResult('Missing info')
                return
            }

            if (sumdebit !== sumcredit) {
                setResult('Entries not equal')
                return
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                subaccount: subaccount,
                currencyid: currencyid,
                amount: sumdebit,
                name: trimmedname,
                rows: rows
            }

            axios.post(configData.CONTROLLERURL + configData.ADDJOURNALENTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_JOURNALENTRY',
                            journalentry: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const updateJournal = (id, timestamp, subaccount, currencyid, name, sumdebit, sumcredit, rows, setResult) => {

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Journal Entries', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            if (!subaccount || !name || !currencyid) {
                setResult('Missing info')
                return
            }

            if (sumdebit !== sumcredit) {
                setResult('Entries not equal')
                return
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: timestamp,
                subaccount: subaccount,
                currencyid: currencyid,
                name: trimmedname,
                amount: sumdebit,
                rows: rows
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEJOURNALENTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_JOURNALENTRY',
                            journalentry: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Journal Entry</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Subaccount</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <SubaccountSelect subaccounts={project.subaccounts} subaccountid={selectedsubaccount} setSubaccountid={setSelectedSubaccount} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Currency</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <CurrencySelect currencies={project.currencies} currencyid={currencyid} setCurrencyid={setCurrencyid} width={250} position='fixed' />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Name</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_scrollable'>
                            <table className='modal_table'>
                                <thead><tr><th>Type</th><th>Account</th><th>Name</th><th>Debit</th><th>Credit</th><th></th></tr></thead>
                                <tbody>
                                    {rows.map((row, index) => (
                                        <NewRow
                                            key={'row' + index}
                                            row={row}
                                            index={index}
                                            handleTypeChange={handleTypeChange}
                                            handleAccountChange={handleAccountChange}
                                            handleNameChange={handleNameChange}
                                            handleDebitChange={handleDebitChange}
                                            handleCreditChange={handleCreditChange}
                                            handleRemoveRow={handleRemoveRow}
                                        />
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><div>Total</div></td>
                                        <td><div></div></td>
                                        <td><div>{totalDifference != 0 && totalDifference}</div></td>
                                        <td><div>{currencySymbol} {sumDebit.toLocaleString('en-US')}</div></td>
                                        <td><div>{currencySymbol} {sumCredit.toLocaleString('en-US')}</div></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <button className='modal_button' onClick={handleAddRow}>Add row</button>
                        <div className="modal_buttonscontainer">
                            {mode === 'edit' && <button className="modal_button" onClick={() => updateJournal(id, timestamp, selectedsubaccount, currencyid, name, sumDebit, sumCredit, rows, setResult)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>}
                            {mode === 'clone' && <button className="modal_button" onClick={() => addJournal(timestamp, selectedsubaccount, currencyid, name, sumDebit, sumCredit, rows, setResult)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>}
                            <button className="modal_button" onClick={() => removeButton(journal)}>Remove</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewJournalEntry({ journal }) {
        const printRef = useRef();

        const [timestamp, setTimstamp] = useState(0);

        const [rows, setRows] = useState([]);
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [subaccount, setSubaccount] = useState('');

        useEffect(() => {
            setName(journal.name)
            setRows(journal.rows)
            setTimstamp(journal.ts)
            setCurrencyid(journal.currencyid)
            const sub = project.subaccounts.find(sub => sub.id === journal.subaccount)?.name;
            setSubaccount(sub)
        }, [journal]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        const currency = project.currencies.find(item => item.id === currencyid, 10)?.symbol || '';

        const renderAccountName = (accounttype, accountid) => {
            switch (accounttype) {
                case 'incomeaccounts':
                    return project.incomeaccounts.find(account => account.id == accountid)?.name || 'Account not found'
                case 'expenseaccounts':
                    return project.expenseaccounts.find(account => account.id == accountid)?.name || 'Account not found'
                case 'clients':
                    return project.clients.find(client => client.id == accountid)?.name || 'Client not found'
                case 'suppliers':
                    return project.suppliers.find(supplier => supplier.id == accountid)?.name || 'Supplier not found'
                default:
                    return null;
            }
        };

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_title'>Journal Entry</div>
                            <div className='modal_date'>{moment.unix(timestamp).format('D MMMM YYYY')}</div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>Subaccount:</b> {subaccount}
                                </div>
                            </div>
                            <div className='modal_row'>
                                <div className='modal_rowsection'>
                                    <b>Name:</b> {name}
                                </div>
                            </div>
                            <div className='modal_scrollable'>
                                <table className='modal_table'>
                                    <thead><tr><th>Account</th><th>Name</th><th>Debit</th><th>Credit</th></tr></thead>
                                    <tbody>
                                        {
                                            rows.map((row, index) => {
                                                const debit = parseFloat(row.debit).toLocaleString('en-US');
                                                const credit = parseFloat(row.credit).toLocaleString('en-US');

                                                return (
                                                    <tr key={'row' + index}>
                                                        <td>
                                                            <div>{renderAccountName(row.accounttype, row.accountid)}</div>
                                                        </td>
                                                        <td><div>{row.name}</div></td>
                                                        <td><div>{currency} {debit}</div></td>
                                                        <td><div>{currency} {credit}</div></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Total</td>
                                            <td></td>
                                            <td>{currency} {rows.reduce((sum, item) => sum + Number(item.debit), 0).toLocaleString('en-US')}</td>
                                            <td>{currency} {rows.reduce((sum, item) => sum + Number(item.credit), 0).toLocaleString('en-US')}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal_buttonscontainer">
                        <button className="modal_button" onClick={() => cloneButton(journal)}>Clone</button>
                        <ReactToPrint
                            trigger={() => (
                                <button className="modal_button" onClick={handlePrint}>Print</button>
                            )}
                            content={() => printRef.current}
                        />
                        <button className="modal_button" onClick={cancelButton}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveJournalEntry({ journal }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(journal.id)
        }, [journal]);

        const removeJournal = (id) => {

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'Journal Entries', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEJOURNALENTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_JOURNALENTRY',
                            id: id
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Remove Journal Entry</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeJournal(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='journalentries'>
            <Title text='Journal Entries' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Journal Entry</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>ID</th><th>Name</th><th>Amount</th></tr></thead>
                <tbody>
                    {
                        filteredjournals.map((journal, index) => {
                            const currency = project.currencies.find(item => item.id === journal.currencyid, 10)?.symbol || '';
                            const amount = parseFloat(journal.amount).toLocaleString('en-US');

                            return (
                                <tr key={'journal' + index}>
                                    <td><div className='table_button' onClick={() => editButton(journal)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(journal)}>View</div></td>
                                    <td><div>{moment.unix(journal.ts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{journal.id}</div></td>
                                    <td><div>{journal.name}</div></td>
                                    <td><div>{currency + ' ' + amount}</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default JournalEntries;