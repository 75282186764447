import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import configData from './Config';
import Title from './Title';
import { useStateValue } from './StateProvider';

import './EditProject.css';

function EditProject({ state, setState, setOption }) {
    const [{ user, project }, dispatch] = useStateValue();

    const [projectuserid, setProjectuserid] = useState('');
    const [projectid, setProjectid] = useState('');
    const [projectname, setProjectname] = useState('');
    const [projectsize, setProjectsize] = useState(0);

    const [result, setResult] = useState(null);

    const navigation = useNavigate();

    useEffect(() => {
        setProjectuserid(project.projectuserid);
        setProjectid(project.projectid);
        setProjectname(project.name);
        setProjectsize(project.size);
    }, [project]);

    const cancelButton = () => {
        setOption(null);
    }

    const removeButton = () => {
        setOption(<RemoveProject />);
    }

    const updateProject = (projectuserid, projectid, projectname, projectsize) => {
        console.log('Trying to update project');

        const data = {
            projectuserid: projectuserid,
            projectid: projectid,
            name: projectname,
            size: projectsize
        }

        axios.post(configData.CONTROLLERURL + configData.UPDATEPROJECT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Add project data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    dispatch({
                        type: 'UPDATE_PROJECT',
                        project: data
                    });
                    navigation('/');
                }
                else {
                    setResult(res.data.data);
                }
            }
        }).catch(() => {
            console.log('Not connected')
        })
    }

    function RemoveProject() {
        const [removeresult, setRemoveresult] = useState(null);

        const removeProject = () => {
            if (project.users.length > 0) {
                console.log('res')
                setRemoveresult('Please remove users first')
                return
            }

            console.log('Trying to remove project');

            if (project.users.length > 0) {
                setRemoveresult('Remove users from this project first')
                return
            }

            const data = {
                projectuserid: projectuserid,
                projectid: projectid
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEPROJECT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove project data received')
                console.log(res.data)
                if (res.data.code === 1) {
                    dispatch({
                        type: 'REMOVE_PROJECT',
                        projectid: project.id
                    });
                    navigation('/');
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        return (
            <div className='removeproject'>
                <Title text='Remove Project' />
                <div className='buttonscontainer'>Are you sure ?</div>
                {removeresult && <div className='result'>{removeresult}</div>}
                <button className='button' onClick={removeProject}>Remove</button>
                <button className='button' onClick={cancelButton}>Cancel</button>
            </div>
        )
    }

    return (
        <div className='editproject'>
            <Title text='Project Name' />
            <div>
                <input
                    className='input'
                    type="text"
                    placeholder="Name"
                    value={projectname}
                    onChange={(e) => setProjectname(e.target.value)}
                />
            </div>
            {result && <div className='result'>{result}</div>}
            <div>
                <button className='button' onClick={() => updateProject(projectuserid, projectid, projectname, projectsize)}>Update</button>
                <button className='button' onClick={removeButton}>Remove Project</button>
            </div>
        </div>
    );
}

export default EditProject;