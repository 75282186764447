import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from './StateProvider';

import axios from 'axios';
import configData from './Config';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import Header from './Header.js';

import './Projects.css';

function Projects({ state, setState }) {
    const [{ version, projects }, dispatch] = useStateValue();

    const [listprojects, setListprojects] = useState([]);

    const [search, setSearch] = useState('');
    const [progress, setProgress] = useState(null);

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(null);

    const navigation = useNavigate();

    useEffect(() => {
        const filtered = projects.filter((project) =>
            project.name.toLowerCase().includes(search.toLowerCase())
        );
        const sortedProjects = filtered.sort((a, b) => a.name.localeCompare(b.name));
        setListprojects(sortedProjects);
    }, [projects, search]);

    useEffect(() => {

        const data = {
            userid: state.user.userid
        }

        axios.post(configData.CONTROLLERURL + configData.GETPROJECTS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log(res.data)
            if (res.data instanceof Array) {
                dispatch({
                    type: 'SET_PROJECTS',
                    projects: res.data
                });
            }
        }).catch(() => {
            setResult('Not connected')
        })
    }, [])

    const addProject = () => {
        navigation('addproject');
    }

    const backupProjects = async () => {
        const zip = new JSZip();
        const today = new Date().toLocaleDateString('en-GB');
        const formattedDate = today.replace(/\//g, '_');
        const backupFileName = `backup_${formattedDate}.zip`;

        for (let i = 0; i < projects.length; i++) {
            const project = projects[i];

            const data = {
                projectuserid: project.userid,
                projectid: project.projectid
            };

            try {
                const res = await axios.post(configData.CONTROLLERURL + configData.GETPROJECT, data, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "userid": state.user.userid,
                        "usertoken": state.user.usertoken
                    }
                });


                if (res.data instanceof Object) {
                    res.data.version = version;
                    const pdContent = JSON.stringify(res.data);
                    zip.file(`${project.projectid}.pd`, pdContent);
                }

                const currentProgress = `${i + 1}/${projects.length}`;
                setProgress(currentProgress);
            } catch (err) {
            }
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, backupFileName);

        setProgress(null);
    };

    const selectProject = (project, index) => {

        setLoading(index);

        const data = {
            projectuserid: project.userid,
            projectid: project.projectid
        }

        axios.post(configData.CONTROLLERURL + configData.GETPROJECT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log(res.data)
            if (res.data instanceof Object) {
                dispatch({
                    type: 'SET_PROJECT',
                    project: res.data
                });
                navigation('home');
            }
        }).catch(() => {
            setResult('Not connected')
        }).finally(() => {
            setLoading(null);
        });
    }

    function handleCollectionsOverview() {
        navigation('collectionsoverview');
    }

    function handlePaymentsOverview() {
        navigation('paymentsoverview');
    }

    function handleAuditTrail() {
        navigation('audittrail');
    }

    function handleBackups() {
        navigation('backups');
    }

    return (
        <div className='projects'>
            <Header state={state} setState={setState} />

            <div className='projects_options'>
                {state.user.usertype <= 2 && <div className='projects_optionssection'><button className='projects_addbutton' onClick={addProject}>Add Project</button></div>}
                <div className='projects_optionssection'><button className='projects_collectionsoverviewbutton' onClick={handleCollectionsOverview}>Collections Overview</button></div>
                <div className='projects_optionssection'><button className='projects_collectionsoverviewbutton' onClick={handlePaymentsOverview}>Payments Overview</button></div>
                <div className='projects_optionssection'><button className='projects_audittrailbutton' onClick={handleAuditTrail}>Audit Trail</button></div>
                <div className='projects_optionssection'><button className='projects_audittrailbutton' onClick={handleBackups}>Backups</button></div>
                <div className='projects_optionssection'><button className='projects_backupbutton' onClick={() => backupProjects()}>Backup all data {progress}</button></div>
            </div>
            <div className='projects_searchcontainer'>
                <input
                    className="projects_searchinput"
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <div className='projects_optionstitle'>Projects ({listprojects.length})</div>
            <div className='result'>{result && result}</div>
            <div className='projects_projectscontainer'>
                <div className='projects_seperator' />
                {
                    listprojects.map((project, index) => (
                        <div key={'project' + index} className='projects_projectbutton'>
                            <div className='projects_projecttext' onClick={() => selectProject(project, index)}>{project.name} {loading === index && '(loading...)'}</div>
                            <div className='projects_projectsize'>{project.size} KB</div>
                        </div>
                    ))
                }
            </div>
        </div>

    );
}

export default Projects;