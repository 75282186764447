import React, { useState, useEffect, useRef } from 'react';

import './select.css';

const CashSelect = ({ cashaccounts, accountid, setAccountid, width, position }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const handleSelect = (accountId) => {
        setAccountid(accountId);
        setShowDropdown(false);
    };

    const filteredAccounts = [...cashaccounts]
        .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
        .filter(
            (account) =>
                account.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                account.id !== accountid
        );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        if (showDropdown && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showDropdown]);

    const clearSelection = () => {
        handleSelect(null);
        setShowDropdown(false);
        setAccountid('');
    };

    const dropdown = () => {
        setShowDropdown(true)
        setSearchTerm('')
    }

    return (
        <div className="inputgroup" ref={dropdownRef} style={{ width: width }}>
            <input
                type="text"
                className='inputgroup_input'
                placeholder="Select a cash account"
                value={accountid ? cashaccounts.find(account => account.id === accountid)?.name : ''}
                onClick={dropdown}
                readOnly
            />
            {accountid && (
                <button className="inputgroup_clearbutton" onClick={clearSelection}>
                    X
                </button>
            )}
            {showDropdown && (
                <div className="inputgroup_dropdown" style={{ position: position }}>
                    <input
                        type="text"
                        className="inputgroup_dropdowninput"
                        placeholder="Search cash accounts"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        ref={inputRef}
                    />
                    <ul className='inputgroup_dropdownul'>
                        {filteredAccounts.map((account, index) => (
                            <li
                                key={'account' + index}
                                className='inputgroup_dropdownli'
                                onClick={() => handleSelect(account.id)}
                            >
                                {account.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CashSelect;