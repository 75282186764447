import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import ReactToPrint from 'react-to-print';
import Title from './Title.js';
import { numberToWords } from './Utils/textparse.js';
import { getSupplierAgingReport } from './Utils/suppliers.js';

import Header from './Header.js';

import './PaymentsOverview.css';

//20240525

function PaymentsOverview({ state, setState }) {
    const [{ projects }, dispatch] = useStateValue();

    const printRef = useRef();
    const [agedpayables, setAgedpayables] = useState([]);
    const [option, setOption] = useState(null);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProjectData = async (project) => {
            const data = {
                projectuserid: project.userid,
                projectid: project.projectid
            };

            return axios.post(configData.CONTROLLERURL + configData.GETPROJECT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log(res.data)
                if (res.data instanceof Object) {
                    const projectData = res.data;

                    const report = getSupplierAgingReport(
                        projectData.suppliers,
                        projectData.transactions,
                        projectData.purchaseinvoices,
                        projectData.journalentries,
                        projectData.exchangerates,
                        projectData.currencies,
                        projectData.basecurrency
                    );

                    setAgedpayables(prevState => [
                        ...prevState,
                        {
                            projectId: project.projectid,
                            projectName: project.name,
                            agingReport: report
                        }
                    ]);
                }
            }).finally(() => {
            });
        };

        const fetchAllProjectData = () => {
            setLoading(true);

            projects.forEach(project => {
                fetchProjectData(project);
            });
        };

        if (projects.length > 0) {
            fetchAllProjectData();
        }
    }, [refresh]);


    const handlePrint = async () => {
        const pdf = await ReactToPrint.render(printRef.current);
        window.open(pdf.toDataURL(), '_blank');
    };

    const handleClick = (collection) => {
        setOption(<Modal collection={collection} setOption={() => setOption(null)} />);
    };

    function Modal({ collection, setOption }) {
        const [projectuserid, setProjectuserid] = useState('');
        const [projectid, setProjectid] = useState('');
        const [id, setId] = useState('');

        const [modalloading, setModalloading] = useState(false);
        const [modalresult, setModalresult] = useState(null);

        const modalRef = useRef();
        const printReceiptRef = useRef();
        const printReceiptButtonRef = useRef();

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (modalRef.current && !modalRef.current.contains(event.target)) {
                    setOption(null);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [setOption]);

        useEffect(() => {
            setProjectuserid(collection.projectuserid)
            setProjectid(collection.projectid)
            setId(collection.id)
        }, [collection])

        const markCollection = (status) => {
            console.log('Trying to mark collection');
            setModalloading(true)

            const data = {
                projectuserid: projectuserid,
                projectid: projectid,
                id: id,
                status: status
            };

            axios.post(configData.CONTROLLERURL + configData.MARKCOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Mark collection data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        setOption(null)
                        setRefresh(!refresh)
                        if (status == 'received') printReceiptButtonRef.current.handlePrint();
                    }
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                setModalloading(false);
            });
        };

        const handleShare = () => {
            const projectName = projects.find(project => project.projectid === collection.projectid)?.name || 'Project';
            const collectorName = collection.collectorname ? collection.collectorname : '';
            const receiptText =
                "<100>COPY FOR CLIENT\n" +
                "<000>Receipt ID     : " + collection.id + "\n" +
                "<000>Project Name   : " + projectName + "\n" +
                "\n" +
                "<110>Client Information\n" +
                "<000>Client Name    : " + collection.clientname + "\n" +
                "\n" +
                "<110>Payment Details\n" +
                "<000>Description    : " + collection.description + "\n" +
                "<000>Amount Paid    : " + collection.currencysymbol + " " + collection.amount + "\n" +
                "<000>Amount in Words: " + numberToWords(collection.amount) + " " + collection.currencysymbol + "\n" +
                "\n" +
                "<110>Collector Information\n" +
                "<000>Collector      : " + collectorName + "\n" +
                "\n" +
                "<000>Date           : " + moment().format('D/MMM/YYYY hh:mm:ss A') + "\n" +
                "\n" +
                "<100>Thank you for your payment!\n" +
                "\n";

            if (navigator.share) {
                navigator.share({
                    title: 'Receipt',
                    text: receiptText,
                }).then(() => {
                    markCollection('received')
                }).catch((error) => {
                    console.error('Error sharing:', error)
                });

            } else {
                alert('Web Share API is not supported in your browser.');
            }
        };

        return (
            <div className='paymentsoverview_modal_overlay'>
                <div className='paymentsoverview_modal' ref={modalRef}>
                    <div className='paymentsoverview_modalheader'>Receipt</div>
                    <div className='paymentsoverview_printable' ref={printReceiptRef}>
                        <pre className='paymentsoverview_printablepre'>
                            {"COPY FOR CLIENT\n" +
                                "Receipt ID     : " + collection.id + "\n" +
                                "Project Name   : " + projects.find(project => project.projectid === collection.projectid)?.name + "\n" +
                                "\n" +
                                "Client Information\n" +
                                "Client Name    : " + collection.clientname + "\n" +
                                "\n" +
                                "Payment Details\n" +
                                "Description    : " + collection.description + "\n" +
                                "Amount Paid    : " + collection.currencysymbol + " " + collection.amount + "\n" +
                                "Amount in Words: " + numberToWords(collection.amount) + " " + collection.currencysymbol + "\n" +
                                "\n" +
                                "Collector Information\n" +
                                "Collector      : " + (collection.collectorname ? collection.collectorname : '') + "\n" +
                                "\n" +
                                "Date           : " + moment().format('D/MMM/YYYY hh:mm:ss A') + "\n" +
                                "\n" +
                                "Thank you for your payment!\n"}
                        </pre>
                    </div>
                    <div className='paymentsoverview_modalstatuspending' onClick={() => markCollection('pending')}>Pending - لم يستلم</div>
                    <div className='paymentsoverview_modalstatusreceived' onClick={() => markCollection('received')}>Received - استلام</div>
                    {modalresult && <div className='paymentsoverview_modalresult'>{modalresult}</div>}
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className="paymentsoverview_button" onClick={handleShare}>Share</div>
                        </div>
                        <div className='modal_rowsection'>
                            <div className='paymentsoverview_button' onClick={() => setOption(null)}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='paymentsoverview'>
            <Header state={state} setState={setState} />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <ReactToPrint
                        trigger={() => (
                            <button className="button" onClick={handlePrint}>Print</button>
                        )}
                        content={() => printRef.current}
                    />
                </div>
            </div>

            <div className='paymentsoverview_printable' ref={printRef}>
                <Title text='PAYMENTS OVERVIEW' />
                {
                    agedpayables
                        .filter(agedpayable =>
                            agedpayable.agingReport.some(supplier =>
                                supplier.aging.thirty > 0 ||
                                supplier.aging.sixty > 0 ||
                                supplier.aging.ninety > 0 ||
                                supplier.aging.overninety > 0
                            )
                        )
                        .map((agedpayable, index) => (
                            <div key={'agedpayable' + index}>
                                <h3>{agedpayable.projectName}</h3>

                                <table className='paymentsoverview_table'>
                                    <thead>
                                        <tr>
                                            <th>Supplier</th>
                                            <th>30 Days</th>
                                            <th>60 Days</th>
                                            <th>90 Days</th>
                                            <th>Over 90 Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            agedpayable.agingReport
                                                .filter(supplier =>
                                                    supplier.aging.thirty > 0 ||
                                                    supplier.aging.sixty > 0 ||
                                                    supplier.aging.ninety > 0 ||
                                                    supplier.aging.overninety > 0
                                                )
                                                .map((supplier, supplierIndex) => (
                                                    <tr key={'supplier' + supplierIndex}>
                                                        <td>{supplier.name}</td>
                                                        <td>{supplier.aging.thirty}</td>
                                                        <td>{supplier.aging.sixty}</td>
                                                        <td>{supplier.aging.ninety}</td>
                                                        <td>{supplier.aging.overninety}</td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        ))
                }
                {option}
            </div>
        </div>
    );
}

export default PaymentsOverview;