import React, { useState, useEffect, useRef } from "react";
import { useStateValue } from "./StateProvider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactToPrint from "react-to-print";
import StatementAccount from "./StatementAccount";
import html3pdf from "html3pdf";
import html2canvas from 'html2canvas';
import Plot from 'react-plotly.js';
import jsPDF from 'jspdf';

import { getCashAccounts } from "./Utils/cash.js";
import { getActualBudget, getCashFlowData, getReceipts, getExpenses, getClientBalancesRow, getClientBalancesColumn, } from "./Utils/reports.js";
import { getSupplierAgingReport } from "./Utils/suppliers.js";

import Title from "./Title";

import "./Reports.css";

function Reports() {
  const [{ project }, dispatch] = useStateValue();

  const [from, setFrom] = useState(new Date());
  const [fromts, setFromTs] = useState(0);
  const [to, setTo] = useState(new Date());
  const [tots, setToTs] = useState(0);

  const [option, setOption] = useState(null);
  const [soaoption, setSoaoption] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFrom(new Date(project.startdate * 1000));
  }, [project]);

  useEffect(() => {
    const dateAtStartOfDay = new Date(from);
    dateAtStartOfDay.setHours(0, 0, 0, 0);
    const unixfromts = Math.floor(dateAtStartOfDay.getTime() / 1000);
    setFromTs(unixfromts);
  }, [from]);

  useEffect(() => {
    const dateAtEndOfDay = new Date(to);
    dateAtEndOfDay.setHours(23, 59, 59, 999);
    const unixtots = Math.floor(dateAtEndOfDay.getTime() / 1000);
    setToTs(unixtots);
  }, [to]);

  const handleActualvsbudget = () => {
    setOption(<ActualvsBudget />);
  };

  const handleCashFlow = () => {
    setOption(<CashFlow />);
  };

  const handleReceiptsReport = () => {
    setOption(<ReceiptsReport />);
  };

  const handleExpensesReport = () => {
    setOption(<ExpensesReport />);
  };

  const handleClientBalances = () => {
    setOption(<ClientBalances />);
  };

  const handleAgedPayablesReport = () => {
    setOption(<AgedPayablesReport />);
  };

  const handleOverallReport = () => {
    setOption(<OverallReport />);
  };

  function ActualvsBudget() {
    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    const actualbudgetdata = getActualBudget(
      project.incomegroups,
      project.incomeaccounts,
      project.expensegroups,
      project.expenseaccounts,
      project.journalentries,
      project.transactions,
      fromts,
      tots,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    const printRef = useRef();

    const handlePrint = async () => {
      const pdf = await ReactToPrint.render(printRef.current);
      window.open(pdf.toDataURL(), "_blank");
    };

    const handleAccountStatement = (type, account, setOption) => {
      setSoaoption(
        <StatementAccount type={type} account={account} setOption={setOption} />
      );
    };

    const saveAsPDF = async () => {
      setLoadingpdf(true);
      setResultpdf('');

      try {
        const content = printRef.current;

        const projectName = project.name.replace(/\s+/g, "_").toLowerCase();
        const currentDate = moment().format("DD_MM_YYYY");

        const options = {
          filename: "actualvsbudget" + '_' + projectName + '_' + currentDate + ".pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        await html3pdf().from(content).set(options).save();
      } catch (error) {
        setResultpdf('Error generating PDF');
      } finally {
        setLoadingpdf(false);
      }
    };

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    return (
      <div className="report">
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (
              <button className="reports_button" onClick={handlePrint}>
                Print
              </button>
            )}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Actual vs Budget</div>
              <div className="reports_projectname">{project.name}</div>
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Actual</th>
                <th>Budget</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {
                actualbudgetdata.map((data, index) => {
                  if (data.type === "section") {
                    return (
                      <tr key={"data" + index}>
                        <td colSpan={4}>
                          <div className="reports_tablesection">{data.name}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (data.type === "group") {
                    return (
                      <tr key={"data" + index}>
                        <td colSpan={4}>
                          <div className="reports_tablegroup">{data.name}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (data.type === "grouptotal") {
                    return (
                      <tr key={"data" + index} className="reports_tablegrouptotal">
                        <td>
                          <div>{data.name}</div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.actual}
                          </div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.budget}
                          </div>
                        </td>
                        <td>
                          <div>{data.percentage}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (data.type === "account") {
                    if (data.section === "income") {
                      return (
                        <tr key={"data" + index} className="reports_tableaccount">
                          <td>
                            <div>{data.name}</div>
                          </td>
                          <td>
                            <div
                              className="reports_actualvsbudgetaccountvalue"
                              onClick={() =>
                                handleAccountStatement(
                                  "incomeaccounts",
                                  {
                                    id: data.accountid,
                                    currencyid: project.basecurrency,
                                    name: data.name,
                                    from: fromts,
                                    to: tots,
                                  },
                                  setSoaoption
                                )
                              }
                            >
                              {data.currency} {data.actual}
                            </div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.budget}
                            </div>
                          </td>
                          <td>
                            <div>{data.percentage}</div>
                          </td>
                        </tr>
                      );
                    }
                    if (data.section === "expense") {
                      return (
                        <tr key={"data" + index} className="reports_tableaccount">
                          <td>
                            <div>{data.name}</div>
                          </td>
                          <td>
                            <div
                              className="reports_actualvsbudgetaccountvalue"
                              onClick={() =>
                                handleAccountStatement(
                                  "expenseaccounts",
                                  {
                                    id: data.accountid,
                                    currencyid: project.basecurrency,
                                    name: data.name,
                                    from: fromts,
                                    to: tots,
                                  },
                                  setSoaoption
                                )
                              }
                            >
                              {data.currency} {data.actual}
                            </div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.budget}
                            </div>
                          </td>
                          <td>
                            <div>{data.percentage}</div>
                          </td>
                        </tr>
                      );
                    }
                  }
                  if (data.type === "sectiontotal") {
                    return (
                      <tr key={"data" + index} className="reports_tablesectiontotal">
                        <td>
                          <div>{data.name}</div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.actual}
                          </div>
                        </td>
                        <td>
                          <div>
                            {data.currency} {data.budget}
                          </div>
                        </td>
                        <td>
                          <div>{data.percentage}</div>
                        </td>
                      </tr>
                    );
                  }
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function CashFlow() {
    const printRef = useRef();

    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    const cashflowdata = getCashFlowData(
      project.cashaccounts,
      project.expenseaccounts,
      project.transactions,
      fromts,
      tots,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    const handlePrint = async () => {
      const pdf = await ReactToPrint.render(printRef.current);
      window.open(pdf.toDataURL(), "_blank");
    };

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    const saveAsPDF = async () => {
      setLoadingpdf(true);
      setResultpdf('');

      try {
        const content = printRef.current;

        const projectName = project.name.replace(/\s+/g, "_").toLowerCase();
        const currentDate = moment().format("DD_MM_YYYY");

        const options = {
          filename: "cashflowstatement" + '_' + projectName + '_' + currentDate + ".pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        await html3pdf().from(content).set(options).save();
      } catch (error) {
        setResultpdf('Error generating PDF');
      } finally {
        setLoadingpdf(false);
      }
    };

    return (
      <div className="report">
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (
              <button className="reports_button" onClick={handlePrint}>
                Print
              </button>
            )}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Cash Flow Statement</div>
              <div className="reports_projectname">{project.name}</div>
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                cashflowdata.map((account, index) => {
                  if (account.type === "section") {
                    return (
                      <tr key={"account" + index}>
                        <td colSpan={2}>
                          <div className="reports_tablesection">{account.name}</div>
                        </td>
                      </tr>
                    );
                  }
                  if (account.type === "account") {
                    return (
                      <tr key={"account" + index} className="reports_tableaccount">
                        <td>
                          <div>{account.name}</div>
                        </td>
                        <td>
                          <div>
                            {account.currency}{" "}
                            {account.amount.toLocaleString("en-US")}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (account.type === "grouptotal") {
                    return (
                      <tr key={"account" + index} className="reports_tablegrouptotal">
                        <td>
                          <div>{account.name}</div>
                        </td>
                        <td>
                          <div>
                            {account.currency}{" "}
                            {account.amount.toLocaleString("en-US")}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (account.type === "sectiontotal") {
                    return (
                      <tr key={"account" + index} className="reports_tablesectiontotal">
                        <td>
                          <div>{account.name}</div>
                        </td>
                        <td>
                          <div>
                            {account.currency}{" "}
                            {account.amount.toLocaleString("en-US")}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function ReceiptsReport() {
    const [cashaccount, setCashaccount] = useState(0);
    const [subaccount, setSubaccount] = useState(0);

    const printRef = useRef();

    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    const receiptsdata = getReceipts(
      project.clients,
      project.transactions,
      fromts,
      tots,
      cashaccount,
      subaccount,
      project.cashaccounts,
      project.subaccounts,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    const handlePrint = async () => {
      const pdf = await ReactToPrint.render(printRef.current);
      window.open(pdf.toDataURL(), "_blank");
    };

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    const saveAsPDF = async () => {
      setLoadingpdf(true);
      setResultpdf('');

      try {
        const content = printRef.current;

        const projectName = project.name.replace(/\s+/g, "_").toLowerCase();
        const currentDate = moment().format("DD_MM_YYYY");

        const options = {
          filename: "receivablesreport" + '_' + projectName + '_' + currentDate + ".pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        await html3pdf().from(content).set(options).save();
      } catch (error) {
        setResultpdf('Error generating PDF');
      } finally {
        setLoadingpdf(false);
      }
    };

    return (
      <div className="report">
        <div className="reports_buttonscontainer">
          <select
            className="reports_select"
            value={cashaccount}
            onChange={(e) => setCashaccount(e.target.value)}
          >
            <option value={0}>All</option>
            {project.cashaccounts.map((account, index) => (
              <option key={'account' + index} value={account.id}>
                {account.name}
              </option>
            ))}
          </select>
          <select
            className="reports_select"
            value={subaccount}
            onChange={(e) => setSubaccount(e.target.value)}
          >
            <option value={0}>All</option>
            {project.subaccounts.map((subaccount) => (
              <option key={subaccount.id} value={subaccount.id}>
                {subaccount.name}
              </option>
            ))}
          </select>
        </div>
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (
              <button className="reports_button" onClick={handlePrint}>
                Print
              </button>
            )}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Receivables Report</div>
              <div className="reports_projectname">{project.name}</div>
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Client</th>
                <th>Name</th>
                <th>Cashaccount</th>
                <th>Subaccount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {
                receiptsdata.map((receipt, index) => {
                  if (receipt.type === "section") {
                    return (
                      <tr key={"receipt" + index} className="reports_tablesection">
                        <td colSpan={4}>
                          <div>{receipt.name}</div>
                        </td>
                        <td>
                          <div>
                            {receipt.currency} {receipt.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (receipt.type === "account") {
                    return (
                      <tr key={"receipt" + index}>
                        <td>
                          <div>{receipt.date}</div>
                        </td>
                        <td>
                          <div>{receipt.clientname}</div>
                        </td>
                        <td>
                          <div>{receipt.name}</div>
                        </td>
                        <td>
                          <div>{receipt.cashaccount}</div>
                        </td>
                        <td>
                          <div>{receipt.subaccount}</div>
                        </td>
                        <td>
                          <div>
                            {receipt.currency} {receipt.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  if (receipt.type === "total") {
                    return (
                      <tr key={"receipt" + index} className='reports_tablesectiontotal'>
                        <td colSpan={5}>
                          <div>{receipt.name}</div>
                        </td>
                        <td>
                          <div>
                            {receipt.currency} {receipt.amount}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function ExpensesReport() {
    const [cashaccount, setCashaccount] = useState(0);
    const [subaccount, setSubaccount] = useState(0);

    const printRef = useRef();

    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    const expensesdata = getExpenses(
      project.suppliers,
      project.expenseaccounts,
      project.transactions,
      fromts,
      tots,
      cashaccount,
      subaccount,
      project.cashaccounts,
      project.subaccounts,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    const handlePrint = async () => {
      const pdf = await ReactToPrint.render(printRef.current);
      window.open(pdf.toDataURL(), "_blank");
    };

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    const saveAsPDF = async () => {
      setLoadingpdf(true);
      setResultpdf('');

      try {
        const content = printRef.current;

        const projectName = project.name.replace(/\s+/g, "_").toLowerCase();
        const currentDate = moment().format("DD_MM_YYYY");

        const options = {
          filename: "expensesreport" + '_' + projectName + '_' + currentDate + ".pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        await html3pdf().from(content).set(options).save();
      } catch (error) {
        setResultpdf('Error generating PDF');
      } finally {
        setLoadingpdf(false);
      }
    };

    return (
      <div className="report">
        <div className="reports_buttonscontainer">
          <select
            className="reports_select"
            value={cashaccount}
            onChange={(e) => setCashaccount(e.target.value)}
          >
            <option value={0}>All</option>
            {project.cashaccounts.map((account, index) => (
              <option key={'account' + index} value={account.id}>
                {account.name}
              </option>
            ))}
          </select>
          <select
            className="reports_select"
            value={subaccount}
            onChange={(e) => setSubaccount(e.target.value)}
          >
            <option value={0}>All</option>
            {project.subaccounts.map((subaccount) => (
              <option key={subaccount.id} value={subaccount.id}>
                {subaccount.name}
              </option>
            ))}
          </select>
        </div>
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (
              <button className="reports_button" onClick={handlePrint}>
                Print
              </button>
            )}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Expenses Report</div>
              <div className="reports_projectname">{project.name}</div>
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          <table className="reports_table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Supplier</th>
                <th>Name</th>
                <th>Cashaccount</th>
                <th>Subaccount</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {expensesdata.map((expense, index) => {
                if (expense.type === "section") {
                  return (
                    <tr key={"expense" + index} className="reports_tablesection">
                      <td colSpan={3}>
                        <div className="reports_tablesectiondata">
                          {expense.name}
                        </div>
                      </td>
                      <td>
                        <div className="reports_tablesectiondata">
                          {expense.currency} {expense.amount}
                        </div>
                      </td>
                    </tr>
                  );
                }
                if (expense.type === "account") {
                  return (
                    <tr key={"expense" + index}>
                      <td>
                        <div>{expense.date}</div>
                      </td>
                      <td>
                        <div>{expense.suppliername}</div>
                      </td>
                      <td>
                        <div>{expense.name}</div>
                      </td>
                      <td>
                        <div>{expense.cashaccount}</div>
                      </td>
                      <td>
                        <div>{expense.subaccount}</div>
                      </td>
                      <td>
                        <div>
                          {expense.currency} {expense.amount}
                        </div>
                      </td>
                    </tr>
                  );
                }
                if (expense.type === "total") {
                  return (
                    <tr key={"expense" + index} className='reports_tablesectiontotal'>
                      <td colSpan={5}>
                        <div>{expense.name}</div>
                      </td>
                      <td>
                        <div>
                          {expense.currency} {expense.amount}
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function ClientBalances() {
    const [filteredclientsrow, setFilteredclientsrow] = useState([]);

    const [filteredclientscolumnheader, setFilteredclientscolumnheader] = useState([]);
    const [filteredclientscolumnrows, setFilteredclientscolumnrows] = useState([]);

    const [subaccount, setSubaccount] = useState(0);
    const [search, setSearch] = useState("");
    const [order, setOrder] = useState("nameasc");

    const [mode, setMode] = useState("column");

    const printRef = useRef();

    const [loadingpdf, setLoadingpdf] = useState(false);
    const [resultpdf, setResultpdf] = useState('');

    useEffect(() => {
      if (mode == "row") {
        let clientsdatarow = getClientBalancesRow(
          project.clients,
          project.transactions,
          project.journalentries,
          fromts,
          tots,
          project.subaccounts,
          subaccount,
          project.currencies,
          project.exchangerates,
          order
        );

        let filteredrow = clientsdatarow.filter((client) =>
          client.clientname.toLowerCase().includes(search.toLowerCase())
        );

        setFilteredclientsrow(filteredrow);
      }
      if (mode == "column") {
        let clientsdatacolumn = getClientBalancesColumn(
          project.clients,
          project.transactions,
          project.journalentries,
          fromts,
          tots,
          project.subaccounts,
          project.currencies,
          project.exchangerates,
          project.basecurrency,
          order
        );

        setFilteredclientscolumnheader(clientsdatacolumn.header);

        let filteredrow = clientsdatacolumn.rows.filter((client) =>
          client.clientname.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredclientscolumnrows(filteredrow);
      }
    }, [search, subaccount, order, mode]);

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    const copyButtonExcelColumn = () => {
      const header = filteredclientscolumnheader;
      const rows = filteredclientscolumnrows.map(row => row.columns);

      const tsvData = [header, ...rows].map(row => row.join("\t")).join("\n");

      navigator.clipboard.writeText(tsvData)
        .then(() => {
          alert('Data copied to clipboard successfully!');
        })
        .catch(err => {
          console.error('Failed to copy data to clipboard: ', err);
        });
    };

    const copyButtonExcelRow = () => {
      const header = ["Name", "Subaccount", "Balance"];
      const rows = filteredclientsrow.map(client => [
        client.clientname,
        client.subaccountname,
        `${client.currencysymbol} ${client.balance.toLocaleString('en-US', {
          minimumFractionDigits: Number.isInteger(client.balance) ? 0 : 2,
          maximumFractionDigits: 2,
        })}`
      ]);

      const tsvData = [header, ...rows].map(row => row.join("\t")).join("\n");

      navigator.clipboard.writeText(tsvData)
        .then(() => {
          alert('Data copied to clipboard successfully!');
        })
        .catch(err => {
          console.error('Failed to copy data to clipboard: ', err);
        });
    };

    const handlePrint = async () => {
      const pdf = await ReactToPrint.render(printRef.current);
      window.open(pdf.toDataURL(), "_blank");
    };

    const saveAsPDF = async () => {
      setLoadingpdf(true);
      setResultpdf('');

      try {
        const content = printRef.current;

        const projectName = project.name.replace(/\s+/g, "_").toLowerCase();
        const currentDate = moment().format("DD_MM_YYYY");

        const options = {
          filename: "clientbalances" + '_' + projectName + '_' + currentDate + ".pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        await html3pdf().from(content).set(options).save();
      } catch (error) {
        setResultpdf('Error generating PDF');
      } finally {
        setLoadingpdf(false);
      }
    };

    const shareAsScreenshot = async () => {
      try {
        const content = printRef.current;

        const projectName = project.name.replace(/\s+/g, "_").toLowerCase();
        const currentDate = moment().format("DD_MM_YYYY");

        const canvas = await html2canvas(content, {
          scale: 2,
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
          windowWidth: content.scrollWidth,
          windowHeight: content.scrollHeight,
        });

        const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpeg', 1));

        if (navigator.share) {
          const file = new File([blob], `clientbalances_${projectName}_${currentDate}.jpeg`, { type: 'image/jpeg' });

          await navigator.share({
            title: "Client Balances",
            text: "Check out this screenshot!",
            files: [file],
          });
        } else {
          console.error("Web Share API is not supported in this browser.");
        }
      } catch (error) {
        console.error("Error sharing screenshot:", error);
      }
    };

    return (
      <div className="report">
        <div className="reports_buttonscontainer">
          <input
            className="reports_input"
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <select
            className="reports_select"
            value={mode}
            onChange={(e) => setMode(e.target.value)}
          >
            <option value="column">Column mode</option>
            <option value="row">Row mode</option>
          </select>
          {
            mode == 'row' &&
            <select
              className="reports_select"
              value={subaccount}
              onChange={(e) => setSubaccount(e.target.value)}
            >
              <option value={0}>All</option>
              {project.subaccounts.map((subaccount) => (
                <option key={subaccount.id} value={subaccount.id}>
                  {subaccount.name}
                </option>
              ))}
            </select>
          }
        </div>
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (
              <button className="reports_button" onClick={handlePrint}>
                Print
              </button>
            )}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          {
            mode == 'row' &&
            <button className="reports_button" onClick={copyButtonExcelRow}>
              Copy to Excel
            </button>
          }
          {
            mode == 'column' &&
            <button className="reports_button" onClick={copyButtonExcelColumn}>
              Copy to Excel
            </button>
          }
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'} {resultpdf && "(" + resultpdf + ")"}
          </button>
          <button className="reports_button" onClick={shareAsScreenshot}>
            Share as Screenshot
          </button>
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Client Balances</div>
              <div className="reports_projectname">{project.name}</div>
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          {mode == "row" && (
            <table className="reports_table">
              <thead>
                <tr>
                  <th>Name
                    <div className="table_sortbutton" onClick={() => setOrder(order === "nameasc" ? "namedesc" : "nameasc")}>
                      {order === "nameasc" ? " ↓" : " ↑"}
                    </div>
                  </th>
                  <th>Subaccount</th>
                  <th>
                    Balance
                    <div className="table_sortbutton" onClick={() => setOrder(order === "balanceasc" ? "balancedesc" : "balanceasc")}>
                      {order === "balanceasc" ? " ↓" : " ↑"}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredclientsrow.map((client, index) => {
                  let clientBalanceLoc = client.balance.toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: Number.isInteger(client.balance)
                        ? 0
                        : 2,
                      maximumFractionDigits: 2,
                    }
                  );

                  return (
                    <tr key={"client" + index}>
                      <td>
                        <div>{client.clientname}</div>
                      </td>
                      <td>
                        <div>{client.subaccountname}</div>
                      </td>
                      <td>
                        <div>
                          {client.currencysymbol} {clientBalanceLoc}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {mode == "column" && (
            <table className="reports_table">
              <thead>
                <tr>
                  {filteredclientscolumnheader.map((headeritem, index) => {
                    return <th key={"headeritem" + index}>
                      {headeritem}
                      {
                        index === 0 &&
                        <div className="table_sortbutton" onClick={() => setOrder(order === "nameasc" ? "namedesc" : "nameasc")}>
                          {order === "nameasc" ? " ↓" : " ↑"}
                        </div>
                      }
                      {
                        index === filteredclientscolumnheader.length - 1 &&
                        <div className="table_sortbutton" onClick={() => setOrder(order === "balanceasc" ? "balancedesc" : "balanceasc")}>
                          {order === "balanceasc" ? " ↓" : " ↑"}
                        </div>
                      }
                    </th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {filteredclientscolumnrows.map((row, index) => {
                  return (
                    <tr key={"row" + index}>
                      {row?.columns.map((column, index) => (
                        <td key={"column" + index}>
                          <div>{column}</div>
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }

  function AgedPayablesReport() {
    const printRef = useRef();

    let suppliersagingdata = getSupplierAgingReport(
      project.suppliers,
      project.transactions,
      project.purchaseinvoices,
      project.journalentries,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    const handlePrint = async () => {
      const pdf = await ReactToPrint.render(printRef.current);
      window.open(pdf.toDataURL(), "_blank");
    };

    return (
      <div className="report">
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (
              <button className="reports_button" onClick={handlePrint}>
                Print
              </button>
            )}
            content={() => printRef.current}
          />
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_headerrow">
            <div className="reports_headercolleft">
              {project.projectdetailsimage && (
                <img
                  className="projectdetailsimage"
                  src={project.projectdetailsimage}
                  alt="Project Details Image"
                />
              )}
            </div>
            <div className="reports_headercolmiddle">
              <div className="reports_title">Aged Payables Report</div>
              <div className="reports_projectname">{project.name}</div>
              <div className="reports_dateframe">
                {moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}
              </div>
            </div>
            <div className="reports_headercolright"></div>
          </div>
          <table className='reports_table'>
            <thead>
              <tr>
                <th>Supplier</th>
                <th>30 Days</th>
                <th>60 Days</th>
                <th>90 Days</th>
                <th>Over 90 Days</th>
              </tr>
            </thead>
            <tbody>
              {
                suppliersagingdata.map((supplier, index) => (
                  <tr key={'supplier' + index}>
                    <td>{supplier.name}</td>
                    <td>{supplier.aging.thirty}</td>
                    <td>{supplier.aging.sixty}</td>
                    <td>{supplier.aging.ninety}</td>
                    <td>{supplier.aging.overninety}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function OverallReport() {
    const printRef = useRef();
    const [filteredclientscolumnheader, setFilteredclientscolumnheader] = useState([]);
    const [filteredclientscolumnrows, setFilteredclientscolumnrows] = useState([]);

    const [loadingpdf, setLoadingpdf] = useState(false);

    const handlePrint = async () => {
      const pdf = await ReactToPrint.render(printRef.current);
      window.open(pdf.toDataURL(), "_blank");
    };

    const copyToWord = async () => {
      try {
        const htmlContent = printRef.current.innerHTML;

        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], { type: 'text/html' }),
          }),
        ]);

        alert('Content copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };

    const saveAsPDF = async () => {
      setLoadingpdf(true);

      try {
        const content = printRef.current;
        const projectName = project.name.replace(/\s+/g, "_").toLowerCase();
        const currentDate = moment().format("DD_MM_YYYY");

        const doc = new jsPDF("p", "mm", "a4");
        const canvas = await html2canvas(content, { scrollY: -window.scrollY });
        const imgData = canvas.toDataURL("image/jpeg");

        const imgWidth = 210;
        const pageHeight = 295;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save(`overallreport_${projectName}_${currentDate}.pdf`);
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        setLoadingpdf(false);
      }
    };

    useEffect(() => {
      const clientsdatacolumn = getClientBalancesColumn(
        project.clients,
        project.transactions,
        project.journalentries,
        fromts,
        tots,
        project.subaccounts,
        project.currencies,
        project.exchangerates,
        project.basecurrency,
        'nameasc'
      );

      setFilteredclientscolumnheader(clientsdatacolumn.header);
      setFilteredclientscolumnrows(clientsdatacolumn.rows);
    }, []);

    const cashdata = getCashAccounts(
      project.cashaccounts,
      project.transactions,
      project.interaccounttransfers,
      project.currencies,
      project.exchangerates
    );
    const actualbudgetdata = getActualBudget(
      project.incomegroups,
      project.incomeaccounts,
      project.expensegroups,
      project.expenseaccounts,
      project.journalentries,
      project.transactions,
      fromts,
      tots,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );
    const cashflowdata = getCashFlowData(
      project.cashaccounts,
      project.expenseaccounts,
      project.transactions,
      fromts,
      tots,
      project.exchangerates,
      project.currencies,
      project.basecurrency
    );

    return (
      <div className="report">
        <div className="reports_buttonscontainer">
          <ReactToPrint
            trigger={() => (
              <button className="reports_button" onClick={handlePrint}>
                Print
              </button>
            )}
            content={() => printRef.current}
          />
          <button className="reports_button" onClick={copyToWord}>
            Copy to Word
          </button>
          <button className="reports_button" onClick={saveAsPDF}>
            Save as PDF {loadingpdf && '(Loading...)'}
          </button>
        </div>
        <div className='reports_note'>
          Please make sure to complete the data entry before sending the report. By entering all contracts data, reconciling the cash accounts, making sure all inspections have been entered, and finalizing the budget chart with their corresponding estimated budget.
        </div>
        <div className="reports_printable" ref={printRef}>
          <div className="reports_title">Report</div>
          <div className="reports_projectname">{project.projectdetails}</div>
          <div className="reports_dateframe">{moment.unix(fromts).format("D/MMM/YYYY") + ' to ' + moment.unix(tots).format("D/MMM/YYYY")}</div>
          <div className="reports_section">
            <div className="reports_sectiontitle">Table of Contents</div>
            <ul>
              <li>Cash Accounts</li>
              <li>Cash Flow Statement</li>
              <li>Remaining Collections</li>
              <li>Actual vs Budget</li>
              <li>Inspections</li>
              <li>Tanks</li>
              <li>Meters</li>
              <li>Contracts</li>
            </ul>
          </div>
          <div className="reports_section">
            <div className="reports_sectiontitle">Cash Accounts</div>
            <table className="reports_table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {cashdata.map((account, index) => {
                  return (
                    <tr key={"account" + index} className="reports_tableaccount" >
                      <td>
                        <div>{account.name}</div>
                      </td>
                      <td>
                        <div>
                          {account.currency + account.balance.toLocaleString("en-US")}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="reports_section">
            <div className="reports_sectiontitle">Cash Flow Statement</div>
            <table className="reports_table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {
                  cashflowdata.map((account, index) => {
                    if (account.type === "section") {
                      return (
                        <tr key={"account" + index}>
                          <td colSpan={2}>
                            <div className="reports_tablesection">{account.name}</div>
                          </td>
                        </tr>
                      );
                    }
                    if (account.type === "account") {
                      return (
                        <tr key={"account" + index} className="reports_tableaccount">
                          <td>
                            <div>{account.name}</div>
                          </td>
                          <td>
                            <div>
                              {account.currency + account.amount.toLocaleString("en-US")}
                            </div>
                          </td>
                        </tr>
                      );
                    }
                    if (account.type === "sectiontotal") {
                      return (
                        <tr key={"account" + index} className="reports_tablesectiontotal">
                          <td>
                            <div>{account.name}</div>
                          </td>
                          <td>
                            <div>
                              {account.currency + account.amount.toLocaleString("en-US")}
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>
          <div className="reports_section">
            <div className="reports_sectiontitle">Remaining Collections</div>
            <table className="reports_table">
              <thead>
                <tr>
                  {
                    filteredclientscolumnheader.map((headeritem, index) => {
                      return <th key={"headeritem" + index}>
                        {headeritem}
                      </th>;
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  filteredclientscolumnrows.map((row, index) => {
                    return (
                      <tr key={"row" + index}>
                        {row?.columns.map((column, index) => (
                          <td key={"column" + index}>
                            <div>{column}</div>
                          </td>
                        ))}
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
          <div className="reports_section">
            <div className="reports_sectiontitle">Actual vs Budget</div>
            <table className="reports_table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Actual</th>
                  <th>Budget</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {
                  actualbudgetdata.map((data, index) => {
                    if (data.type === "section") {
                      return (
                        <tr key={"data" + index}>
                          <td colSpan={4}>
                            <div className="reports_tablesection">{data.name}</div>
                          </td>
                        </tr>
                      );
                    }
                    if (data.type === "group") {
                      return (
                        <tr key={"data" + index}>
                          <td colSpan={4}>
                            <div className="reports_tablegroup">{data.name}</div>
                          </td>
                        </tr>
                      );
                    }
                    if (data.type === "grouptotal") {
                      return (
                        <tr key={"data" + index} className="reports_tablegrouptotal">
                          <td>
                            <div>{data.name}</div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.actual}
                            </div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.budget}
                            </div>
                          </td>
                          <td>
                            <div>{data.percentage}</div>
                          </td>
                        </tr>
                      );
                    }
                    if (data.type === "account") {
                      if (data.section === "income") {
                        return (
                          <tr key={"data" + index} className="reports_tableaccount">
                            <td>
                              <div>{data.name}</div>
                            </td>
                            <td>
                              <div>
                                {data.currency} {data.actual}
                              </div>
                            </td>
                            <td>
                              <div>
                                {data.currency} {data.budget}
                              </div>
                            </td>
                            <td>
                              <div>{data.percentage}</div>
                            </td>
                          </tr>
                        );
                      }
                      if (data.section === "expense") {
                        return (
                          <tr key={"data" + index} className="reports_tableaccount">
                            <td>
                              <div>{data.name}</div>
                            </td>
                            <td>
                              <div>
                                {data.currency} {data.actual}
                              </div>
                            </td>
                            <td>
                              <div>
                                {data.currency} {data.budget}
                              </div>
                            </td>
                            <td>
                              <div>{data.percentage}</div>
                            </td>
                          </tr>
                        );
                      }
                    }
                    if (data.type === "sectiontotal") {
                      return (
                        <tr key={"data" + index} className="reports_tablesectiontotal">
                          <td>
                            <div>{data.name}</div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.actual}
                            </div>
                          </td>
                          <td>
                            <div>
                              {data.currency} {data.budget}
                            </div>
                          </td>
                          <td>
                            <div>{data.percentage}</div>
                          </td>
                        </tr>
                      );
                    }
                  })
                }
              </tbody>
            </table>
          </div>
          <div className="reports_section">
            <div className="reports_sectiontitle">Inspections</div>
            <table className="reports_table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Ref</th>
                  <th>Supplier</th>
                  <th>Asset</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {project.inspections
                  .sort((a, b) => b.ts - a.ts)
                  .map((inspection, index) => {
                    let supplierName = project.suppliers.find(
                      (supplier) => supplier.id == inspection.supplierid
                    )?.name;
                    let assetName = project.assets.find(
                      (asset) => asset.id == inspection.assetid
                    )?.name;

                    return (
                      <tr key={"inspection" + index}>
                        <td>
                          <div>
                            {moment.unix(inspection.ts).format("D/MMM/YYYY")}
                          </div>
                        </td>
                        <td>
                          <div>{inspection.refno}</div>
                        </td>
                        <td>
                          <div>{supplierName}</div>
                        </td>
                        <td>
                          <div>{assetName}</div>
                        </td>
                        <td>
                          <div className="inspections_description">
                            {inspection.description}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="reports_section">
            <div className="reports_sectiontitle">Tanks</div>
            {
              project.tanks.map((tank, index) => {
                const filteredReadings = project.readings.filter(reading => tank.assetid === reading.assetid).sort((a, b) => a.ts - b.ts);;
                const dates = filteredReadings.map(reading => new Date(reading.ts * 1000).toLocaleString());
                const levels = filteredReadings.map(reading => reading.amount);
                return (
                  <div key={'tank' + index}>
                    <Plot
                      data={[
                        {
                          x: dates,
                          y: levels,
                          type: 'scatter',
                          mode: 'lines+markers',
                          marker: { color: 'blue' },
                          name: 'Tank Level'
                        }
                      ]}
                      layout={{
                        title: tank.name,
                        xaxis: { title: 'Date' },
                        yaxis: { title: 'Level' }
                      }}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                )
              })
            }
          </div>
          <div className="reports_section">
            <div className="reports_sectiontitle">Meters</div>
            <table className="reports_table">
              <thead>
                <tr>
                  <th>Meter Name</th>
                  <th>Consumption</th>
                </tr>
              </thead>
              <tbody>
                {
                  project.meters.map((meter, index) => {
                    const meterReadings = project.readings
                      .filter(reading => reading.type == 'meters')

                    const matchingRows = [];
                    meterReadings.forEach(reading => {
                      reading.rows.forEach(row => {
                        if (row.meterid == meter.id) {
                          matchingRows.push({
                            ts: parseInt(reading.ts),
                            amount: parseFloat(row.reading),
                          });
                        }
                      });
                    });

                    matchingRows.sort((a, b) => a.ts - b.ts);
                    let difference = 0;
                    if (matchingRows.length > 1) {
                      difference = matchingRows[matchingRows.length - 1].amount - matchingRows[0].amount;
                    }

                    return (
                      <tr key={'meter' + index}>
                        <td>{meter.name}</td>
                        <td>{difference.toFixed(2)}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
          <div className="reports_section">
            <div className="reports_sectiontitle">Contracts</div>
            <table className='reports_table'>
              <thead>
                <tr>
                  <th>Supplier</th>
                  <th>Name</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {
                  project.contracts.map((contract, index) => {
                    let from = moment.unix(contract.from).format('D/MMM/YYYY');
                    let to = moment.unix(contract.to).format('D/MMM/YYYY');
                    let supplierName = project.suppliers.find(supplier => supplier.id === contract.supplierid)?.name || 'Supplier not found';
                    let amount = contract.amount;
                    let currency = project.currencies.find((currency) => currency.id === contract.currencyid)?.symbol || '';

                    return (
                      <tr key={'contract' + index}>
                        <td><div>{supplierName}</div></td>
                        <td><div>{contract.name}</div></td>
                        <td><div>{from}</div></td>
                        <td><div>{to}</div></td>
                        <td><div>{currency} {amount}</div></td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="reports">
      <Title text="reports" />
      <div className="reports_datescontainer">
        <div className="reports_datelabel">From</div>
        <DatePicker
          className="reports_datepicker"
          selected={from}
          onChange={(date) => setFrom(date)}
        />
        <div className="reports_datelabel">To</div>
        <DatePicker
          className="reports_datepicker"
          selected={to}
          onChange={(date) => setTo(date)}
        />
      </div>
      <div className="reports_buttonscontainer">
        <button className="reports_button" onClick={handleActualvsbudget}>
          Actual vs Budget
        </button>
        <button className="reports_button" onClick={handleCashFlow}>
          Cash Flow Statement
        </button>
        <button className="reports_button" onClick={handleReceiptsReport}>
          Receipts Report
        </button>
        <button className="reports_button" onClick={handleExpensesReport}>
          Expenses Report
        </button>
        <button className="reports_button" onClick={handleClientBalances}>
          Client Balances
        </button>
        <button className="reports_button" onClick={handleAgedPayablesReport}>
          Aged Payables Report
        </button>
        <button className="reports_button" onClick={handleOverallReport}>
          Overall Report
        </button>
      </div>

      {option}

      {soaoption}
    </div>
  );
}

export default Reports;
