import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { getEmsReadings } from './Utils/energymanagementsystem.js';
import { getPermission } from './Utils/permissions.js';
import Title from './Title.js';
import MeterSelect from './Components/meterselect.js';

import './EMSReadings.css';

function EMSReadings({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [option, setOption] = useState(null);

    const emsreadings = getEmsReadings(
        project.readings,
        project.assets,
        project.clients,
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cancelButton = () => {
        setOption(null);
    }

    const addReadingButton = () => {
        setOption(<AddReading />);
    }

    const editButton = (reading) => {
        setOption(<EditReading reading={reading} mode='edit' />);
    }

    const viewButton = (reading) => {
        setOption(<ViewReading reading={reading} />);
    }

    const removeButton = (reading) => {
        setOption(<RemoveReading reading={reading} />);
    }

    const cloneButton = (reading) => {
        setOption(<EditReading reading={reading} mode='clone' />);
    }

    function NewRowMeters({ row, index, handleMeterChange, handleReadingChange, handleRemoveRow }) {
        return (
            <tr>
                <td>
                    <MeterSelect meters={project.meters} meterid={row.meterid} setMeterid={(meterId) => handleMeterChange({ target: { value: meterId } }, index)} position='relative' />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="number"
                        value={row.reading}
                        onChange={(e) => handleReadingChange(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_cancelbutton' onClick={() => handleRemoveRow(index)}>X</div>
                </td>
            </tr>
        );
    }

    function AddReading() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);
        const [type, setType] = useState('meters');

        const [rows, setRows] = useState([{ meterid: '', reading: 0 }]);

        const [amount, setAmount] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const handleAddRow = () => {
            setRows([...rows, { meterid: '', reading: 0 }])
        };

        const handleMeterChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].meterid = selectedRow;
            setRows(updatedRows);
        };

        const handleReadingChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].reading = selectedRow;
            setRows(updatedRows);
        };

        const handleRemoveRow = () => {
            if (rows.length > 1) {
                const updatedRows = rows.slice(0, rows.length - 1);
                setRows(updatedRows);
            }
        };

        const totalAmount = rows.reduce((total, row) => total + (parseFloat(row.reading) || 0), 0);

        const addReading = (timestamp, type, rows, amount) => {
            console.log('Trying to add reading');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'EMS Readings', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const areAllReadingsValid = rows.every(row => !isNaN(parseFloat(row.reading)));
            if (!areAllReadingsValid) {
                setResult('Invalid readings. Please enter valid numbers.');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                type: type,
                rows: rows,
                amount: amount,
            }

            axios.post(configData.CONTROLLERURL + configData.ADDREADING, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add reading data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_READING',
                            reading: data
                        });
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Add Reading</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                    showTimeSelect
                                    dateFormat="Pp"
                                />
                            </div>
                            <div className='modal_rowsection'>
                                <select
                                    className="select"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <option value="meters">Meters</option>
                                    <option value="tanks">Tanks</option>
                                </select>
                            </div>
                        </div>
                        {
                            type == 'meters' &&
                            <>
                                <table className='modal_table'>
                                    <thead><tr><th>Meter</th><th>Reading</th><th></th></tr></thead>
                                    <tbody>
                                        {
                                            rows.map((row, index) => (
                                                <NewRowMeters
                                                    key={'row' + index}
                                                    row={row}
                                                    index={index}
                                                    handleMeterChange={handleMeterChange}
                                                    handleReadingChange={handleReadingChange}
                                                    handleRemoveRow={handleRemoveRow}
                                                />
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Total</td>
                                            <td>{totalAmount}</td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div className="modal_buttonscontainer">
                                    <div className='modal_button' onClick={handleAddRow}>Add row</div>
                                </div>
                                <div className='modal_result'>{result && result}</div>
                                <div className="modal_buttonscontainer">
                                    <button className="modal_button" onClick={() => addReading(timestamp, type, rows, totalAmount)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                                    <button className="modal_button" onClick={cancelButton}>Cancel</button>
                                </div>
                            </>
                        }
                        {
                            type == 'tanks' &&
                            <>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        <div className='modal_label'>Amount</div>
                                    </div>
                                </div>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        <input
                                            className='modal_input'
                                            type="number"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='modal_result'>{result && result}</div>
                                <div className="modal_buttonscontainer">
                                    <button className="modal_button" onClick={() => addReading(timestamp, type, [], amount)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                                    <button className="modal_button" onClick={cancelButton}>Cancel</button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div >
        )
    }

    function EditReading({ reading, mode }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [type, setType] = useState('');
        const [timestamp, setTimestamp] = useState(0);

        const [rows, setRows] = useState([{ meterid: '', reading: 0 }]);

        const [amount, setAmount] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(reading.id);
            setSelecteddate(new Date(reading.ts * 1000));
            setType(reading.type);
            setRows(reading.rows);
            setAmount(reading.amount);
        }, []);

        useEffect(() => {
            const dateAtMidday = new Date(selecteddate);
            const unixTimestamp = Math.floor(dateAtMidday.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const handleAddRow = () => {
            setRows([...rows, { meterid: '', reading: 0 }])
        };

        const handleMeterChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].meterid = selectedRow;
            setRows(updatedRows);
        };

        const handleReadingChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].reading = selectedRow;
            setRows(updatedRows);
        };

        const handleRemoveRow = () => {
            if (rows.length > 1) {
                const updatedRows = rows.slice(0, rows.length - 1);
                setRows(updatedRows);
            }
        };

        const totalAmount = rows.reduce((total, row) => total + (parseFloat(row.reading) || 0), 0);

        const addReading = (timestamp, type, rows, amount) => {
            console.log('Trying to add reading');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'EMS Readings', 'add');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const areAllReadingsValid = rows.every(row => !isNaN(parseFloat(row.reading)));
            if (!areAllReadingsValid) {
                setResult('Invalid readings. Please enter valid numbers.');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                type: type,
                rows: rows,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.ADDREADING, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add reading data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_READING',
                            reading: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const updateReading = (id, timestamp, type, rows, amount) => {
            console.log('Trying to update reading');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'EMS Readings', 'update');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            const areAllReadingsValid = rows.every(row => !isNaN(parseFloat(row.reading)));
            if (!areAllReadingsValid) {
                setResult('Invalid readings. Please enter valid numbers.');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: timestamp,
                type: type,
                rows: rows,
                amount: amount
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEREADING, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update reading data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_READING',
                            reading: data
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit Reading</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>Date</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <DatePicker
                                    className='modal_datepicker'
                                    selected={selecteddate}
                                    onChange={(date) => setSelecteddate(date)}
                                    showTimeSelect
                                    dateFormat="Pp"
                                />
                            </div>
                            <div className='modal_rowsection'>
                                <select
                                    className="select"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <option value="meters">Meters</option>
                                    <option value="tanks">Tank</option>
                                </select>
                            </div>
                        </div>
                        {
                            type == 'meters' &&
                            <>
                                <table className='modal_table'>
                                    <thead><tr><th>Client</th><th>Reading</th><th></th></tr></thead>
                                    <tbody>
                                        {
                                            rows.map((row, index) => (
                                                <NewRowMeters
                                                    key={'row' + index}
                                                    row={row}
                                                    index={index}
                                                    handleMeterChange={handleMeterChange}
                                                    handleReadingChange={handleReadingChange}
                                                    handleRemoveRow={handleRemoveRow}
                                                />
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Total</td>
                                            <td>{totalAmount}</td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div className="modal_buttonscontainer">
                                    <div className='modal_button' onClick={handleAddRow}>Add row</div>
                                </div>
                                <div className='modal_result'>{result && result}</div>
                                <div className="modal_buttonscontainer">
                                    {mode === 'edit' && <button className="modal_button" onClick={() => updateReading(id, timestamp, type, rows, totalAmount)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>}
                                    {mode === 'clone' && <button className="modal_button" onClick={() => addReading(timestamp, type, rows, totalAmount)} disabled={loading}>{loading ? 'Loading...' : 'Add'}</button>}
                                    <button className="modal_button" onClick={() => removeButton(reading)}>Remove</button>
                                    <button className="modal_button" onClick={cancelButton}>Cancel</button>
                                </div>
                            </>
                        }
                        {
                            type == 'tanks' &&
                            <>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        <div className='modal_label'>Amount</div>
                                    </div>
                                </div>
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        <input
                                            className='modal_input'
                                            type="number"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='modal_result'>{result && result}</div>
                                <div className="modal_buttonscontainer">
                                    {mode === 'edit' && <button className="modal_button" onClick={() => updateReading(id, timestamp, type, [], amount)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>}
                                    {mode === 'clone' && <button className="modal_button" onClick={() => addReading(timestamp, type, [], amount)} disabled={loading}>{loading ? 'Loading...' : 'Add'}</button>}
                                    <button className="modal_button" onClick={() => removeButton(reading)}>Remove</button>
                                    <button className="modal_button" onClick={cancelButton}>Cancel</button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }

    function ViewReading({ reading }) {
        const [timestamp, setTimestamp] = useState(0);
        const [type, setType] = useState('');

        const [rows, setRows] = useState([]);

        const [amount, setAmount] = useState(0);

        const printRef = useRef();

        useEffect(() => {
            setTimestamp(reading.ts);
            setType(reading.type);
            setRows(reading.rows);
            setAmount(reading.amount);
        }, [reading]);

        const handlePrint = async () => {
            const pdf = await ReactToPrint.render(printRef.current);
            window.open(pdf.toDataURL(), '_blank');
        };

        const totalAmount = rows.reduce((total, row) => total + (parseFloat(row.reading) || 0), 0);

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_printable' ref={printRef}>
                            <div className='modal_row'>
                                <div className='modal_rowsection'></div>
                                <div className='modal_rowsection'>
                                    <div className='modal_title'>EMS Reading</div>
                                    <div className='modal_date'>{moment.unix(timestamp).format('dddd Do MMMM YYYY')}</div>
                                </div>
                                <div className='modal_rowsection'></div>
                            </div>
                            {
                                type == 'meters' &&
                                <table className='modal_table'>
                                    <thead><tr><th>Meter</th><th>Reading</th></tr></thead>
                                    <tbody>
                                        {
                                            rows.map((row, index) => {
                                                const meterName = project.meters.find((meter) => meter.id === row.meterid)?.name;
                                                return (
                                                    <tr key={'row' + index}>
                                                        <td>{meterName}</td>
                                                        <td>{row.reading}</td>
                                                    </tr>
                                                )
                                            }
                                            )
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Total</td>
                                            <td>{totalAmount}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            }
                            {
                                type == 'tanks' &&
                                <div className='modal_row'>
                                    <div className='modal_rowsection'>
                                        {amount}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => cloneButton(reading)}>Clone</button>
                            <ReactToPrint
                                trigger={() => (
                                    <button className="modal_button" onClick={handlePrint}>Print</button>
                                )}
                                content={() => printRef.current}
                            />
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveReading({ reading }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(reading.id);
        }, [reading]);

        const removeReading = (id) => {
            console.log('Trying to remove reading');

            const hasPermission = getPermission(project.projectuserid, project.users, state.user.userid, 'EMS Readings', 'remove');
            if (!hasPermission) {
                setResult('No permission');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEREADING, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove reading data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_READING',
                            readingid: id
                        });
                        setOption(null);
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Remove Reading ?</div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => removeReading(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='emsreadings'>
            <Title text='Energy Management System Readings' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addReadingButton}>Add Reading</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Asset</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        emsreadings.map((read, index) => {
                            return (
                                <tr key={'read' + index}>
                                    <td><div className='table_button' onClick={() => editButton(read)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(read)}>View</div></td>
                                    <td><div>{moment.unix(read.ts).format('D/MMM/YYYY hh:mm:ss A')}</div></td>
                                    <td><div>{read.type}</div></td>
                                    <td><div>{project.assets.find((asset) => asset.id === read.assetid)?.name}</div></td>
                                    <td><div>{read.currency} {read.amount}</div></td>
                                </tr>
                            )
                        }
                        )
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default EMSReadings;