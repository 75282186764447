import React, { useState, useEffect } from 'react';
import AdminUsers from './AdminUsers';

import Title from '../Title';

function Admin({ state, setState }) {

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='admin'>
            <Title text='Admin' />

            <div className='buttonscontainer'>
                <button className='button' onClick={() => setOption(<AdminUsers state={state} setState={setState} />)}>Users</button>
            </div>

            {option}

        </div>
    );
}

export default Admin;