import React, { useState, useEffect } from 'react';
import { useStateValue } from '../StateProvider';
import moment from 'moment';

import axios from 'axios';
import configData from '../Config';

import Title from '../Title';

function AdminUsers({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');

    const [refresh, setRefresh] = useState(false);
    const [result, setResult] = useState(null);
    const [option, setOption] = useState(null);

    useEffect(() => {
        console.log('Trying to get users');

        axios.post(configData.CONTROLLERURL + configData.ADMINGETUSERS, {}, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Get users data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setUsers(res.data)
            }
        }).catch(() => {
        })
    }, [project, refresh]);

    const cancelButton = () => {
        setOption(null)
    }

    const editButton = (user) => {
        setOption(<EditUser user={user} />)
    }

    const viewButton = (user) => {
        setOption(<ViewUser user={user} />)
    }

    function EditUser({ user }) {
        const [id, setId] = useState('');
        const [email, setEmail] = useState('');
        const [status, setStatus] = useState('');
        const [username, setUsername] = useState('');
        const [newpassword, setNewpassword] = useState('');

        useEffect(() => {
            setId(user.id);
            setEmail(user.email);
            setStatus(user.status);
            setUsername(user.name);
        }, [user]);

        const updateUser = (id, email, username, status) => {
            console.log('Trying to update user');

            setResult(null);

            const data = {
                id: id,
                email: email,
                username: username,
                status: status
            }

            axios.post(configData.CONTROLLERURL + configData.ADMINUPDATEUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update user data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setRefresh(!refresh)
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            })
        }

        const updatePassword = (id, newpassword) => {
            console.log('Trying to update user password');

            setResult(null);

            const data = {
                id: id,
                newpassword: newpassword,
            }

            axios.post(configData.CONTROLLERURL + configData.ADMINUPDATEUSERPASSWORD, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update user password data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setOption(null)
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            })
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_title'>Edit User</div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select className='modal_select' value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="">Select status</option>
                                    <option value='A'>Active</option>
                                    <option value='P'>Pending</option>
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    value={newpassword}
                                    onChange={(e) => setNewpassword(e.target.value)}
                                />
                            </div>
                            <div className='modal_rowsection'>
                                <button className="modal_button" onClick={() => updatePassword(id, newpassword)}>Change Password</button>
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateUser(id, email, username, status)}>Update</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewUser({ user }) {
        const [id, setId] = useState('');
        const [email, setEmail] = useState('');

        useEffect(() => {
            setId(user.id)
            setEmail(user.email)
        }, [user]);

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>{id}</div>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <div className='modal_label'>{email}</div>
                            </div>
                        </div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='adminusers'>
            <Title text='Admin Users' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Id</th><th>Email</th><th>Username</th><th>Last login</th><th>Status</th></tr></thead>
                <tbody>
                    {
                        users.map((user, index) => {
                            let parsed = JSON.parse(user.token);

                            return (
                                <tr key={'user' + index}>
                                    <td><div className='table_button' onClick={() => editButton(user)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(user)}>View</div></td>
                                    <td><div>{user.id}</div></td>
                                    <td><div>{user.email}</div></td>
                                    <td><div>{user.name}</div></td>
                                    <td><div>{moment.unix(parsed.ts).format('DD-MM-YYYY HH:mm:ss')}</div></td>
                                    <td><div>{user.status}</div></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {option}

        </div>
    );
}

export default AdminUsers;